import axios from 'axios';
import Oidc from 'oidc-client';
import { CookieStorage } from 'cookie-storage';

export default class SecurityService {
    public static rand(): string { return (Date.now() + '' + Math.random()).replace('.', ''); }
    private isInterne: boolean = false;
    private defaultExternalAuthenticationScheme: string;
    private canAutoSilentSignin: boolean = false;
    private autoAuthenticationScheme: string | null = null;
    private baseUrl: string;
    private settings: any = [];

    private cookieStorage = new CookieStorage({
        secure: true,
        sameSite: 'Strict'
    });

    private getOidcUserManager: (authenticationScheme: string) => Oidc.UserManager;
    private isOidcSettingsExist: (authenticationScheme: string) => boolean;

    private defaultOidcSettings: any = {
        userStore: new Oidc.WebStorageStateStore({ store: this.cookieStorage }),
    };
    private oidcSettingsLocal: any = {
        userStore: new Oidc.WebStorageStateStore({ store: this.cookieStorage }),
    };

    constructor({ isInterne, defaultExternalAuthenticationScheme, canAutoSilentSignin, autoAuthenticationScheme, defaultOidcSettings, settings }: {
        isInterne: boolean,
        defaultExternalAuthenticationScheme: string,
        canAutoSilentSignin: boolean,
        autoAuthenticationScheme: string,
        defaultOidcSettings: any,
        settings: [],
    }) {
        this.isInterne = isInterne;
        this.defaultExternalAuthenticationScheme = defaultExternalAuthenticationScheme;
        this.canAutoSilentSignin = canAutoSilentSignin;
        this.autoAuthenticationScheme = autoAuthenticationScheme;
        // Construction base URl
        this.baseUrl = defaultOidcSettings.baseUrl;
        delete defaultOidcSettings.baseUrl;
        //
        this.defaultOidcSettings = { ...this.defaultOidcSettings, ...defaultOidcSettings };
        this.oidcSettingsLocal = {
            ...this.oidcSettingsLocal,
            ...this.buildOidcSettingsLocal(this.defaultOidcSettings),
        };

        this.settings = settings;
        const memoizer = Memoizer(this.settings, this.oidcSettingsLocal);
        this.isOidcSettingsExist = memoizer.isOidcSettingsExist();
        this.getOidcUserManager = memoizer.memoize();

        // Auto Connect.
        const autoAuthenticationSchemeResult = this.getOidcUserManager(autoAuthenticationScheme);
        if (autoAuthenticationSchemeResult !== null) {
            this.autoAuthenticationScheme = (autoAuthenticationSchemeResult.settings.extraQueryParams as any).authenticationScheme;
            if (this.isBoolean(this.canAutoSilentSignin) && !!canAutoSilentSignin && (this.autoAuthenticationScheme !== null && typeof this.autoAuthenticationScheme !== 'undefined')) {
                this.signinRedirect(this.autoAuthenticationScheme, null);
            }
        }
    }

    public async getUserProfile(): Promise<any> { return (await this.isProfileLoaded()).profile; }

    public async isProfileLoaded(): Promise<{ isProfileLoaded: boolean, profile: any }> {
        const user = await this.getUser();
        if (user !== null) {
            const isProfileLoaded = !!(user.profile);
            const profile = isProfileLoaded ? user.profile : null;
            return { isProfileLoaded, profile };
        } else {
            return { isProfileLoaded: false, profile: null };
        }
    }

    public getClaims(): any { return null; }

    public getCookieStorage(): CookieStorage { return this.cookieStorage; }

    public async getUser(): Promise<Oidc.User | null> {
        const { loggedIn, user } = await this.isLoggedIn();
        if (!loggedIn) { return null; } else {
            return user;
        }
    }

    public isLoggedIn(): Promise<{ loggedIn: boolean, hasInterne: boolean, user: Oidc.User | null }> {
        return new Promise((resolve) => {
            this.getLocalOidcUserManager.getUser().then((user: Oidc.User) => {
                const loggedIn = !!user && !user.expired;
                resolve({ loggedIn, hasInterne: this.IsInterne, user: loggedIn ? user : null });
            }).catch((_: any) => resolve({ loggedIn: false, hasInterne: this.IsInterne, user: null }));
        });
    }

    public signinLocal(email: string, username: string, password: string): Promise<{ user: Oidc.User | null }> {
        return this.getToken(this.getUrlLocalLogin, email, username, password, null, (null as any));
    }

    public signinDefaultRedirect(): Promise<{ user: Oidc.User | null }> {
        return this.signinRedirect(this.defaultExternalAuthenticationScheme, null)
            .then(({ user, provider }) => {
                const email = user!.profile.email;
                const username = user!.profile.preferred_username;
                const urlTokenEndpoint = this.getUrlLocalLogin;
                const tmpUser = user!;
                const profile = tmpUser.profile;
                if (!!profile) {
                    delete tmpUser.profile;
                }
                return this.getToken(urlTokenEndpoint, email, username, SecurityService.rand(), provider, { ...tmpUser, ...profile });
            })
            .catch((err: any) => {
                throw new Error(err);
            });
    }

    public logout(): Promise<void> {
        return new Promise<void>((resolve) => {
            // On lance l'opération en asynchrone.
            this.getLocalOidcUserManager.revokeAccessToken();

            // Suppression du user du storage.
            this.getLocalOidcUserManager.removeUser();

            // ClearStaleState.
            setTimeout(() => {
                const webStorageStateStore = this.getLocalOidcUserManager.settings.stateStore;
                webStorageStateStore.getAllKeys().then((keys: string[]) => {
                    keys && keys.length >= 1 && keys.forEach((key: string) => webStorageStateStore.remove(key));
                });
                this.cookieStorage.clear();
                resolve();
            }, 50);
        });
    }

    private signinRedirect(authenticationScheme: string, redirectUrlTo: string | null): Promise<{ user: Oidc.User | null, provider: string }> {
        const manager = this.getOidcUserManager(authenticationScheme);
        return manager.clearStaleState().then(() => {
            return new Promise<{ user: Oidc.User | null, provider: string }>((resolve, reject) => {
                const provider: string = ((!!manager.settings && (manager.settings.extraQueryParams || {})) as any).providerType;
                manager.signinPopup({ state: SecurityService.rand(), nonce: SecurityService.rand() })
                    .then((user: any) => {
                        if (!!user && !user.expired) { resolve({ user, provider }); } else { reject({ user: null, provider }); }
                    })
                    .catch((err: any) => reject({ user: null, provider }));
            });
        });
    }


    private isBoolean(val: any) { return 'boolean' === typeof val && Boolean(val) === val; }

    private buildOidcSettingsLocal(defaultOidcSettings: any): any {
        return {
            ...{
                scope: 'openid profile offline_access api',
                response_type: 'id_token token',
                automaticSilentRenew: true,
                accessTokenExpiringNotificationTime: 10,
                filterProtocolClaims: false,
                loadUserInfo: true,
            },
            ...defaultOidcSettings,
        };
    }

    get IsInterne(): boolean {
        return this.isBoolean(this.isInterne) && !!this.isInterne && this.isOidcSettingsExist(this.defaultExternalAuthenticationScheme);
    }

    get getLocalOidcUserManager(): Oidc.UserManager { return this.getOidcUserManager('local'); }

    get getUrlLocalLogin(): string { return `${this.baseUrl}auth/login/`; }

    // Récupère les infos de connection.
    private getToken(
        urlTokenEndpoint: string,
        email: string,
        username: string,
        password: string,
        provider: string | null, additionnalParams: any): Promise<{ user: Oidc.User | null }> {
        return new Promise(async (resolve, promiseError) => {
            try {
                const urlTokenEndPoint = urlTokenEndpoint || (await this.getLocalOidcUserManager.metadataService.getTokenEndpoint())!;
                const clientId = this.getLocalOidcUserManager.settings.client_id;
                const clientSecret = this.getLocalOidcUserManager.settings.client_secret;

                const dataForConnect = {
                    email,
                    username,
                    password,
                    /*username: utoa(username),
                    password: utoa(password),*/
                    grant_type: 'password',
                    scope: 'openid profile offline_access api',
                    response_type: 'code id_token token',
                    loadUserInfo: true,
                    provider,
                    ...additionnalParams,
                };
                const defaultHeaders = { 'Content-Type': 'application/json' };

                // Récupère les infos de base lors d'une connexion.
                const metas = document.querySelectorAll('meta[name][data-initial-data]') || ([] as Element[]);
                (metas as any).forEach((meta: any) => {
                    axios.defaults.headers.common[meta.name] = meta.content;
                });

                const { data: responseData } = await axios.post(urlTokenEndPoint, dataForConnect, { headers: defaultHeaders });
                await this.getLocalOidcUserManager.clearStaleState();
                if (!!responseData && !responseData.isError) {
                    const authTime = parseJwt(responseData.data.id_token).auth_time;
                    const profile = { ...responseData.data.profile, auth_time: parseInt(authTime, 10) };
                    const user = new Oidc.User({ ...JSON.parse(JSON.stringify(responseData.data)), ...{ expires_at: responseData.data.expires_in }, ...{ profile } });
                    await this.getLocalOidcUserManager.storeUser(user);
                    await this.getLocalOidcUserManager.signinSilent({ client_id: clientId, client_secret: clientSecret });
                    resolve({ user });
                } else {
                    promiseError(responseData.messages);
                }
            } catch (err) {
                promiseError({ user: null });
            }
        });
    }
}

const Memoizer = (settingsArray: [], defaultSettings: any) => {
    const cacher = () => {
        const cache: any = {};
        return (key: string) => {
            const stringifiedArgs = JSON.stringify(key);
            if (cache[stringifiedArgs]) {
                return cache[stringifiedArgs];
            } else {
                const oidcSetting = { ...defaultSettings, ...(getSetting(settingsArray, key) || defaultSettings) };
                const val = new Oidc.UserManager(oidcSetting);
                cache[stringifiedArgs] = val;
                return val;
            }
        };
    };

    const getSetting = (settingsParameters: [], authenticationScheme: string): any => {
        return (settingsParameters || []).filter((p: any) => {
            return (!!p && (p.extraQueryParams || {})).authenticationScheme === authenticationScheme;
        })[0] || null;
    };

    return {
        isOidcSettingsExist() {
            return (externalAuthenticationScheme: string): boolean => {
                const setting = getSetting(settingsArray, externalAuthenticationScheme);
                return (setting !== null && typeof setting !== 'undefined');
            };
        },
        memoize() {
            return cacher();
        },
    };
};

const parseJwt = (token: string): any => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
    } catch (e) {
        return null;
    }
};
