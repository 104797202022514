export class GlobalConfiguration {
    public static apiUrl: string | undefined = '';

    /**
     * Récupération des variables dans le parameters.json
     */
    public static async setBaseUrl(baseUrl: string | undefined): Promise<void> {
        this.apiUrl = baseUrl;
    }
}
