import * as VeeValidate from 'vee-validate';
import { configure, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import { i18n } from '@/plugins';

// Install rules
for (let [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
        message: messages[rule] // assign message
    });
}

// Install messages
localize({
    messages,
});
i18n.locale = 'fr'; // Définir une locale par défaut(sans elle, cela ne fonctionnera pas).
VeeValidate.setInteractionMode('eager');
const config = {
    inject: false,
    mode: 'eager', // Mode de validation eager. Voir http://vee-validate.logaretm.com/v2/guide/interaction.html pour plus d'infos.
    delay: 1,
    i18nRootKey: 'validations', // Customize the root path for validation messages.
    i18n,
    dictionary: {
        fr: messages,
    },
};
// Sets the options.
configure(config);
