/**
 * Énumération des états de traitement d'une opération.
 */
export enum EtatTraitementOperation {
    /// <summary>
    /// En Analyse.
    /// </summary>
    EN_ANALYSE = 1,
    /// <summary>
    /// En attente de retour.
    /// </summary>
    EN_ATTENTE_RETOUR = 2,
    /// <summary>
    /// Bloqué.
    /// </summary>
    BLOQUE = 3,
    /// <summary>
    /// Bloqué.
    /// </summary>
    EN_ATTENTE_EXPERTISE = 4,
    /// <summary>
    /// Bloqué.
    /// </summary>
    EN_ATTENTE_RECEPTION_AH = 5,
}