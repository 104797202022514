import { HttpClient } from '@/shared/HttpClient';
import { Result, FileContentResult } from '@/shared/models';

/**
 * Classe d'helper pour les appels au back pour un utilisateur.
 * */
// tslint:disable-next-line:no-namespace
export module ApiHelper {

    /**
    * Récupération de tous les élements d'un type donné.
    * @param url La route du controller pour récupérer tous les éléments.
    */
    export function getAll<T>(url: string): Promise<T[]> {
        return new Promise<T[]>((resolve, reject) => {
            return HttpClient.axios.get<Result<T[]>>(url).then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    /**
     * Crée un blob à partir du contenu du fichier et lance le téléchargement sur la page.
     * @param fileContentResult Le résultat avec le contenu du fichier.
     */
    export function createAndDownloadBlobFile(fileContentResult: FileContentResult): void {
        // Crée un Blob à partir du contenu du fichier.
        const binaryString = window.atob(fileContentResult.fileContents);
        const bytes = new Uint8Array(binaryString.length);
        const blobContent = bytes.map((_byte, i) => binaryString.charCodeAt(i));
        const blob = new Blob([blobContent]);

        // Ajoute un lien invisible à la page et clique dessus pour lancer le téléchargement.
        if(navigator.msSaveBlob) {
        // IE 10+
            navigator.msSaveBlob(blob, fileContentResult.fileDownloadName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileContentResult.fileDownloadName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    /**
     * Récupère l'URL du contenu du fichier et lance le téléchargement sur la page.
     * @param fileContentResult Le résultat avec le contenu du fichier.
     */
    export function getUrlDownloadBlobFile(fileContentResult: FileContentResult,options?: BlobPropertyBag): string {

        // Crée un Blob à partir du contenu du fichier.
        const binaryString = window.atob(fileContentResult.fileContents);
        const bytes = new Uint8Array(binaryString.length);
        const blobContent = bytes.map((_byte, i) => binaryString.charCodeAt(i));
        const blob = new Blob([blobContent], options);

        const url = URL.createObjectURL(blob);
        return url;
    }
}

export default ApiHelper;
