import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { Utilisateur, Profil, Societe } from '@/models';
import { RootState } from '@/store/state';
import { EtatContratPartenariat, Profils, TypeProfilUtilisateur } from '@/shared/enums';
import { UtilisateurDroitsAcces } from '@/components/FormsUtilisateur/models';
import { MapUtilisateur } from '@/components/FormsUtilisateur/helpers/MapUtilisateur';

/**
 * Classe de gestion d'état pour le store d'un utilisateur.
 */
export class UtilisateurState {

    /**
     * L'utilisateur en cours.
     */
    public utilisateur: Utilisateur;

    /**
     * Type de profil de l'utilisateur.
     */
    public typeProfil: TypeProfilUtilisateur;

    /**
     * Liste des profils que peut avoir un utilisateur.
     */
    public profils: Profil[] = new Array<Profil>();

    public profil: Profils;
    /**
     * Constructeur.
     */
    constructor() {
        this.utilisateur = new Utilisateur();
        this.typeProfil = TypeProfilUtilisateur.None;
        this.profil = Profils.None;
    }
}

/**
 * Constantes pour les méthodes du store.
 */
export enum UtilisateurStoreMethods {
    UTILISATEUR = 'UTILISATEUR',
    SET_UTILISATEUR = 'SET_UTILISATEUR',
    ADD_NEW_SOCIETE_TO_UTILISATEUR = 'ADD_NEW_SOCIETE_TO_UTILISATEUR',
    SET_SOCIETE_UTILISATEUR = 'SET_SOCIETE_UTILISATEUR',
    SET_NEW_SOCIETE_TO_UTILISATEUR = 'SET_NEW_SOCIETE_TO_UTILISATEUR',
    UPDATE_SOCIETE_UTILISATEUR = 'UPDATE_SOCIETE_UTILISATEUR',
    SET_DROITS_UTILISATEUR = 'SET_DROITS_UTILISATEUR',
    UPDATE_DROITS_UTILISATEUR = 'UPDATE_DROITS_UTILISATEUR',
    TYPE_PROFIL = 'TYPE_PROFIL',
    SET_TYPE_PROFIL = 'SET_TYPE_PROFIL',
    PROFILS = 'PROFILS',
    SET_PROFILS = 'SET_PROFILS',
    PROFIL = 'PROFIL',
    SET_PROFIL = 'SET_PROFIL',
    SET_UTILISATEUR_BY_PROPERTIES = 'SET_UTILISATEUR_BY_PROPERTIES',
    CHANGE_ETAT_CONTRAT_PARTENARIAT = 'CHANGE_ETAT_CONTRAT_PARTENARIAT',
    SET_ETAT_CONTRAT_PARTENARIAT = 'SET_ETAT_CONTRAT_PARTENARIAT',
    CHANGE_TYPE_PROFIL = 'CHANGE_TYPE_PROFIL',
    CHANGE_PROFIL = 'CHANGE_PROFIL'
}

/**
 * Getters du store.
 */
const getters: GetterTree<UtilisateurState, RootState> = {
    [UtilisateurStoreMethods.UTILISATEUR]: (state) => state.utilisateur,
    [UtilisateurStoreMethods.TYPE_PROFIL]: (state) => state.typeProfil,
    [UtilisateurStoreMethods.PROFILS]: (state) => state.profils,
    [UtilisateurStoreMethods.PROFIL]: (state) => state.profil,
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<UtilisateurState> = {
    [UtilisateurStoreMethods.SET_UTILISATEUR]: (state: UtilisateurState, utilisateur: Utilisateur) => state.utilisateur = utilisateur,

    [UtilisateurStoreMethods.SET_TYPE_PROFIL]: (state: UtilisateurState, typeProfil: TypeProfilUtilisateur) => state.typeProfil = typeProfil,
    [UtilisateurStoreMethods.SET_PROFILS]: (state: UtilisateurState, profils: Profil[]) => state.profils = profils,
    [UtilisateurStoreMethods.SET_PROFIL]: (state: UtilisateurState, profil: Profils) => state.profil = profil,
    [UtilisateurStoreMethods.SET_UTILISATEUR_BY_PROPERTIES]: (state: UtilisateurState, utilisateurProperty: object) =>
        state.utilisateur = Object.assign({}, state.utilisateur, utilisateurProperty),
    [UtilisateurStoreMethods.SET_ETAT_CONTRAT_PARTENARIAT]: (state: UtilisateurState, nouvelEtat: EtatContratPartenariat) =>
        state.utilisateur.etatContratPartenariatId = nouvelEtat,

    [UtilisateurStoreMethods.SET_NEW_SOCIETE_TO_UTILISATEUR]: (state: UtilisateurState, societe: Societe): void => {
        if (societe !== null) {
            state.utilisateur.societes.push(societe);
        }
    },

    [UtilisateurStoreMethods.SET_SOCIETE_UTILISATEUR]: (state: UtilisateurState, payload: { index: number, societe: Societe }): void => {
        if (payload.index >= 0 && payload.societe) {
            Vue.set(state.utilisateur.societes, payload.index, payload.societe);
        }
    },

    // Copie les droits dans l'utilisateur courant.
    // Refacto à prévoir : faire un sous-objet pour les droits en base également.
    [UtilisateurStoreMethods.SET_DROITS_UTILISATEUR]: (state: UtilisateurState, droits: UtilisateurDroitsAcces): void => {
        if (droits !== null) {
            state.utilisateur = MapUtilisateur.fromDroitsAcces(state.utilisateur, droits);
        }
    }
};

/**
 * Actions du store.
 */
const actions: ActionTree<UtilisateurState, RootState> = {

    [UtilisateurStoreMethods.CHANGE_ETAT_CONTRAT_PARTENARIAT]: (store: ActionContext<UtilisateurState, RootState>, nouvelEtat: EtatContratPartenariat): void => {
        store.commit(UtilisateurStoreMethods.SET_ETAT_CONTRAT_PARTENARIAT, nouvelEtat);
    },

    [UtilisateurStoreMethods.CHANGE_TYPE_PROFIL]: (store: ActionContext<UtilisateurState, RootState>, nouveauTypeProfil: TypeProfilUtilisateur): void => {
        store.commit(UtilisateurStoreMethods.SET_TYPE_PROFIL, nouveauTypeProfil);
        if (nouveauTypeProfil === TypeProfilUtilisateur.Interne) {
            // Suppression des sociétés si le profil est interne.
            store.state.utilisateur.societes = [];
        } else if (nouveauTypeProfil === TypeProfilUtilisateur.Externe && store.state.utilisateur.societes.length === 0) {
            store.state.utilisateur.societes.push(new Societe());
        }
    },

    [UtilisateurStoreMethods.CHANGE_PROFIL]: (store: ActionContext<UtilisateurState, RootState>, nouveauProfil: Profils): void => {
        store.commit(UtilisateurStoreMethods.SET_PROFIL, nouveauProfil);

        // Un apporteur d'affaires ne peut avoir qu'une seule entreprise.
        if (nouveauProfil === Profils.ApporteurAffaires) {
            while (store.state.utilisateur.societes.length > 1) {
                store.state.utilisateur.societes.pop();
            }
        }
    },

    [UtilisateurStoreMethods.ADD_NEW_SOCIETE_TO_UTILISATEUR]: (store: ActionContext<UtilisateurState, RootState>, societe: Societe): void => {
        if (societe !== null) {
            store.commit(UtilisateurStoreMethods.SET_NEW_SOCIETE_TO_UTILISATEUR, societe);
        }
    },

    [UtilisateurStoreMethods.UPDATE_SOCIETE_UTILISATEUR]: (store: ActionContext<UtilisateurState, RootState>, payload: { index: number, societe: Societe }): void => {
        store.commit(UtilisateurStoreMethods.SET_SOCIETE_UTILISATEUR, payload);
    },

    [UtilisateurStoreMethods.UPDATE_DROITS_UTILISATEUR]: (store: ActionContext<UtilisateurState, RootState>, droits: UtilisateurDroitsAcces): void => {
        store.commit(UtilisateurStoreMethods.SET_DROITS_UTILISATEUR, droits);
    },
};

/**
 * Export du module
 */
export const UtilisateurStore: Module<UtilisateurState, RootState> = {
    state: new UtilisateurState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
