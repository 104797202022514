import Vue from 'vue';

/**
 * Méthode de conversion de la valorisation en €/kWhc en €/MWhc.
 */
Vue.filter('toEurosMWhc', (value: number) => {
    return validerValeur(value * 1000);
});

/**
 * Méthode de conversion de la valorisation en €/MWhc en €/kWhc.
 */
Vue.filter('toEurosKWhc', (value: number) => {
    return validerValeur(value / 1000);
});

/**
 * Méthode de conversion du volume en kWhc en MWhc.
 */
Vue.filter('toMWhc', (value: number) => {    
    return validerValeur(value / 1000, true);
});

/**
 * Méthode de conversion du volume en MWhc en kWhc.
 */
Vue.filter('toKWhc', (value: number) => {
    return validerValeur(value * 1000);
});

/**
 * Vérifie que la valeur est valide et positive avant de la retourner.
 */
const validerValeur = (value: number, autoriseNegatif: boolean = false) => {
    return (!Number.isNaN(value) && (autoriseNegatif || value >= 0))
        ? value
        : '-';
};
