import _Vue from 'vue';
import { Route } from 'vue-router';
import Oidc from 'oidc-client';
import {
    checkerPermission,
    check as checkCondition,
} from '@/shared/helpers/PermissionsHelper';

import SecurityService from './securityService';
import { CookieStorage } from 'cookie-storage';
import { uuidv4 } from '@/shared/helpers';

let pluginInstalled = false;

export class SecurityPluginOptions {
    public settings: any = [];
}

// Export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function SecurityPlugin(Vue: typeof _Vue, options: SecurityPluginOptions): void {
    if (pluginInstalled) { return; }
    pluginInstalled = true;

    if (options === null || typeof options === 'undefined') {
        throw new Error('Les informations de sécurité sont obligatoires, veuiller les renseigner.');
    }

    const defaultOptions: any = {};
    const initOptions: any = {
        ...defaultOptions,
        ...options,
    };

    const securityService: SecurityService = new SecurityService(initOptions);

    Object.defineProperties(Vue.prototype, {
        $security: {
            get() {
                return {
                    authRedirectGuard() {
                        return async (routeTo: Route, routeFrom: Route, next: any) => {
                            const routeToFullPath = routeTo.fullPath;
                            // Si rien n'est spécifié la l'authentification est obligatoire par défaut.
                            const predicateRequired = (meta: any) => meta.hasOwnProperty('authRequired') ? meta.authRequired : true;
                            const authRequired = routeTo.matched.some((route) => predicateRequired(route.meta));
                            // Fonction redirect to login.
                            const redirectToLogin = () => {
                                next({ name: 'login', query: { redirectFrom: routeToFullPath } });
                            };

                            // Si pas besoin d'authentification, on accès directement à la page.
                            if (!authRequired) {
                                return next();
                            }
                            // Sinon on vérifie si il est authentifié.si non on propose la page de login.
                            securityService.isLoggedIn().then(({ loggedIn, hasInterne }) => {
                                if (loggedIn) {
                                    const meta = routeTo.meta || {};
                                    const verb = meta.authProfilsVerb || 'every';
                                    // Si pas besoin de permissions, on continue la navigation.
                                    // La propriété authPermissions est obligatoire même si elle est vide.
                                    const authPermissions: string[] = meta.hasOwnProperty('authPermissions') ? meta.authPermissions : [uuidv4()];
                                    if (authPermissions.length <= 0) {
                                        next();
                                    } else {
                                        // Si il est authentifié, vérifie ,les permissions ,sinon affiche la page 403.
                                        checkCondition(verb, authPermissions,false, checkerPermission, () => next(), () => next({
                                            name: 'error',
                                            params: {
                                                '0': routeTo.path,
                                                routeToFullPath,
                                                status: 403,
                                            },
                                            replace: true
                                        }));
                                    }
                                    // 
                                } else {
                                    // TODO: Pour Okta : if (hasInterne) => securityService.signinDefaultRedirect(); else => redirectToLogin();
                                    
                                    // Authentification.
                                    redirectToLogin();
                                }
                            });
                        };
                    },
                    isInterne(): boolean { return securityService.IsInterne; },
                    async isLoggedIn(): Promise<boolean> { return (await securityService.isLoggedIn()).loggedIn; },
                    async isProfileLoaded(): Promise<boolean> { return (await securityService.isProfileLoaded()).isProfileLoaded; },
                    async getUser(): Promise<Oidc.User | null> { return (await securityService.getUser()); },
                    async userProfile(): Promise<any> { return await securityService.getUserProfile(); },
                    async signinLocal(email: string, username: string, password: string): Promise<{ user: Oidc.User | null }> {
                        return await securityService.signinLocal(email, username, password);
                    },
                    logout(): Promise<void> { return securityService.logout(); },
                    getCookieStorage(): CookieStorage { return securityService.getCookieStorage(); },
                };
            },
        },
    });
}
