/**
 * Modèle de donnée d'un Template d'opération.
 */
export class TemplateChamp {
    /**
     * Identifiant technique de l'entité.
     */
    public id!: number;
    /**
     * Code unique du template d'opération.
     */
    public code!: string;
    /**
     * Libelle du template d'opération.
     */
    public libelle!: string;
    /**
     * Identifiant du TemplateTypeChamp.
     */
    public templateTypeChampId!: number;
    /**
     * Code du TemplateTypeChamp.
     */
    public templateTypeChampCode!: string;
    /**
     * libellé du TemplateTypeChamp.
     */
    public templateTypeChampLibelle!: string;
}
