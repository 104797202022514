/**
 * Enum correspondant au UnitOfTime.Base de moment.
 */
export enum UniteDeTemps {
    day = 'day',
    days = 'days',
    month = 'month',
    months = 'months',
    year = 'year',
    years = 'years',
}
