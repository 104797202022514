import { CriteriaBase } from '.';

/**
 * Modèle de base pour les critérias.
 */
export class CriteriaBaseAvecFiltresRapides extends CriteriaBase {

    /**
     * Liste des filtres rapides (flags).
     */
    public filtresRapides: { [key: string]: boolean } = {};

}
