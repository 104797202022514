import { TypeContribution } from '@/shared/enums';

/**
 * Classe des paiements d'un dossier accessible depuis l'onglet Données Engie
 *
 * @export
 * @class Paiement
 */
export class Paiement {
    /**
     * Identifiant.
     *
     * @type {number}
     * @memberof Paiement
     */
    public id: number = 0;
    /**
     * Date de mise en paiement.
     *
     * @type {string}
     * @memberof Paiement
     */
    public dateMisePaiement: string = '';
    /**
     * Numéro de paiement.
     *
     * @type {string}
     * @memberof Paiement
     */
    public numeroPaiement: string = '';
    /**
     * Identifiant du type de contribution.
     *
     * @type {number}
     * @memberof Paiement
     */
    public typeContributionId: number = TypeContribution.PRIME_BENEFICIAIRE;
    /**
     * Valorisation CEE Classique.
     *
     * @type {number}
     * @memberof Paiement
     */
    public montantCeeClassique: number = 0;
    /**
     * Valorisation CEE Précarité.
     *
     * @type {number}
     * @memberof Paiement
     */
    public montantCeePrecarite: number = 0;
    /**
     * Id du dossier.
     *
     * @type {number}
     * @memberof Paiement
     */
    public dossierId: number = 0;
    /**
     * IBAN.
     *
     * @type {string}
     * @memberof Paiement
     */
    public iban: string = '';
    /**
     * Retour Trustpair.
     *
     * @type {number}
     * @memberof Paiement
     */
    public statutPaiementId: number | null = null;
    /**
     * siretBeneficiaire.
     *
     * @type {string}
     * @memberof Paiement
     */
    public siretBeneficiaire: string = '';
    /**
     * raisonSocialeBeneficiaire.
     *
     * @type {string}
     * @memberof Paiement
     */
    public raisonSocialeBeneficiaire: string = '';
    /**
     * Commentaire.
     *
     * @type {string}
     * @memberof Paiement
     */
    public commentaire: string = '';
    /**
     * Date de facture.
     *
     * @type {string}
     * @memberof Paiement
     */
    public dateFacture: string = '';   
    /**
     * Code Bic.
     *
     * @type {string}
     * @memberof Paiement
     */
    public bic: string = '';
    /**
     * Est validé.
     *
     * @type {boolean}
     * @memberof Paiement
     */
    public estValide: boolean = false;
    /**
     * Numéro de la facture.
     *
     * @type {string}
     * @memberof Paiement
     */
    public numeroFacture: string = ''
}
