/**
 * Énumération de statuts PNCEE des opérations.
 */
export enum StatutPncee {

    /**
     * Non déposé.
     */
    NON_DEPOSE = 'NON_DEPOSE',

    /**
     * Déposé en attente.
     */
    DEPOSE_EN_ATTENTE = 'DEPOSE_EN_ATTENTE',

    /**
     *  Accepté.
     */
    ACCEPTE = 'ACCEPTE',

    /**
     *  Refusé.
     */
    REFUSE = 'REFUSE'
}

/**
 * Énumération de statuts PNCEE des opérations.
 */
export enum StatutPnceeAsNumber {

    /**
     * Non déposé.
     */
    NON_DEPOSE = 1,

    /**
     * Déposé en attente.
     */
    DEPOSE_EN_ATTENTE = 2,

    /**
     *  Accepté.
     */
    ACCEPTE = 3,

    /**
     *  Refusé.
     */
    REFUSE = 4
}

/**
 * Dictionnaire avec les libellés des statuts Pncee.
 * */
export const libelleStatutPncee: { [statut: string]: string } = {
    [StatutPncee.NON_DEPOSE.toString()] : 'Non déposé',
    [StatutPncee.DEPOSE_EN_ATTENTE.toString()] : 'Déposé en attente',
    [StatutPncee.ACCEPTE.toString()] : 'Accepté',
    [StatutPncee.REFUSE.toString()] : 'Refusé',
}