
/**
 * Helper pour la gestion des conversions de données.
 */
export class ConvertHelper {

    /**
     * Convertion de €/kWhc vers €/MWhc.
     * @param kWhc
     */
    public static toEurosMWhc(kWhc: number): number {
        return kWhc * 1000;
    }

    /**
     * Convertion de €/MWhc vers €/kWhc.
     * @param MWhc
     */
    public static toEurosKWhc(MWhc: number): number {
        return MWhc / 1000;
    }

    /**
     * Convertit une chaîne de caractère en nombre.
     */
    public static parseFloat(chaine: string): number | null {
        if (chaine) {
            return parseFloat(chaine.replace(',', '.'));
        }
        return null;
    }

}
