export class UtilisateurIdentity {
    public userId: number;
    public civiliteId: number;
    public typeCompteSyndicId: number;
    public lastName: string;
    public firstName: string;
    public fonction: string;
    public phoneNumber: string;

    constructor();
    constructor(userId: number, civiliteId: number, typeCompteSyndicId: number, lastName: string, firstName: string, fonction: string, phoneNumber: string);
    constructor(userId?: number, civiliteId?: number, typeCompteSyndicId?: number, lastName?: string, firstName?: string, fonction?: string, phoneNumber?: string) {
        this.userId = userId || 0;
        this.civiliteId = civiliteId || 999999;
        this.typeCompteSyndicId = typeCompteSyndicId;
        this.lastName = lastName  || '';
        this.firstName = firstName  || '';
        this.fonction = fonction  || '';
        this.phoneNumber = phoneNumber  || '';
    }

  }
