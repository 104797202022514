

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'Confirm',
})
export default class Confirm extends Vue {

    public dialog = false;
    public resolve: ((value: boolean | PromiseLike<boolean>) => void) = (() => {/* Laissez vide express.*/ });
    public reject: ((reason?: string) => void) = (() => {/* Laissez vide express.*/ });
    public message: string | null = null;
    public title: string | null = null;
    public options: { color: string; width: number; zIndex: number } = {
        color: 'primary',
        width: 500,
        zIndex: 200,
    };
    @Prop({}) 
    public onlyClose: boolean;

    /**
     *
     */
    constructor() {
        super();
    }
    /**
     * Ouverture du composant en mode popup.
     *
     */
    public open(
        title: string | null,
        message: string | null,
        options: { color: string; width: number; zIndex: number }) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = { ...this.options, ...options };
        return new Promise<boolean>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
    /**
     * Confirmation de la boîte de dialogue.
     *
     */
    public agree() {
        this.resolve(true);
        this.dialog = false;
    }
    /**
     * Annulation de la boîte de dialogue.
     */
    public cancel() {
        this.resolve(false);
        this.dialog = false;
    }
}
