export class UtilisateurEmail {
    public userId: number;
    public ancienEmail: string;
    public nouveauEmail: string;
   constructor();
    constructor(userId: number, ancienEmail: string, nouveauEmail: string);
   constructor(userId?: number, ancienEmail?: string, nouveauEmail?: string) {
        this.userId = userId || -1;
        this.ancienEmail = ancienEmail || '';
        this.nouveauEmail = nouveauEmail || '';
   }
}
