import { Pagination } from './Pagination.model';

/**
 * Modèle pour la pagination et le tri.
 */
export class PagingAndSorting {
    /*
     * Index de la page courante.
     */
    public pageIndex: number = 0;

    /**
     * Nombre d'éléments par page.
     */
    public pageSize: number = 10;

    /**
     * Nombre d'éléments à passer.
     */
    public skipValue: number = 0;

    /**
     * Expression pour le tri (liste de colonnes).
     */
    public sortExpression: string;

    /**
     * Retourne un PagingAndSorting à partir d'un objet Pagination.
     * @param pagination l'objet pagination non typé.
     */
    public static buildFromPaginationObject(pagination: object): PagingAndSorting {
        return Object.assign(new Pagination(), pagination).toPagingAndSorting();
    }

    /*
     * Helper pour récupérer le query string.
     */
    get queryString(): string {
        const pairs = Object.entries(this).map(([key, val]) => [key, val].map(escape).join('='));
        return pairs.join('&');
    }
}
