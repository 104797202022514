import { User, UserProfile } from './types';
import { GetterTree, MutationTree, ActionTree, Module, Store, ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import Vue from 'vue';
import Oidc from 'oidc-client';

const env = process.env.NODE_ENV;
const isDev = env === 'dev' || env === 'development'; // Return false or true

Oidc.Log.logger = console;
Oidc.Log.level = isDev ? Oidc.Log.DEBUG : Oidc.Log.NONE;

/**
 * Classe de gestion d'état pour le store de l'authentification.
 * */
export class AuthState {

    /**
     * L'utilisateur actuellement connecté.
     */
    currentUser: User | null = null;
}

/**
 * Constantes pour les méthodes du store.
 */
export enum AuthStoreMethods {
    IS_CURRENT_USER_INTERNE = 'IS_CURRENT_USER_INTERNE',
    AUTH_CURRENT_USER = 'AUTH_CURRENT_USER',
    AUTH_SET_CURRENT_USER = 'AUTH_SET_CURRENT_USER',
    AUTH_INIT = 'AUTH_INIT',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    IS_CURRENT_USER_LOGGED_IN = 'IS_CURRENT_USER_LOGGED_IN',
    USER_PROFILE = 'USER_PROFILE',
    USER = 'USER',
}

/**
 * Getters du store.
 */
const getters: GetterTree<AuthState, RootState> = {
    [AuthStoreMethods.AUTH_CURRENT_USER]: (state): User => state.currentUser,
    [AuthStoreMethods.IS_CURRENT_USER_INTERNE]: (state): boolean => Vue.prototype.$security.isInterne(),

    // Récupération asynchrone de l'utilisateur.
    [AuthStoreMethods.USER]: (state): Promise<Oidc.User | null> => Vue.prototype.$security.getUser(),

    // Récupération asynchrone du profil de l'utilisateur connecté.
    [AuthStoreMethods.USER_PROFILE]: (state): Promise<UserProfile> => Vue.prototype.$security.userProfile(),

    // Indique si l'utilisateur est connecté en mode asynchrone.
    [AuthStoreMethods.IS_CURRENT_USER_LOGGED_IN]: (state): Promise<boolean> => Vue.prototype.$security.isLoggedIn(),
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<AuthState> = {
    [AuthStoreMethods.AUTH_SET_CURRENT_USER]: (state: AuthState, user: User) => state.currentUser = user,
};

/**
 * Actions du store.
 */
const actions: ActionTree<AuthState, RootState> = {

    // Initialise le store.
    [AuthStoreMethods.AUTH_INIT]: (store: ActionContext<AuthState, RootState>): void => {
        // On ne fait rien ici.
    },

    // Logs in the current user.
    [AuthStoreMethods.LOGIN]: (store: ActionContext<AuthState, RootState>, payload: { email: string, username: string, password: string }): Promise<User | null> => {

        return new Promise<User | null>((resolve, reject) => {
            Vue.prototype.$security.signinLocal(payload.email, payload.username, payload.password)
                .then((reponse: { user: Oidc.User | null }) => {
                    if (reponse.user === null) {
                        store.commit(AuthStoreMethods.AUTH_SET_CURRENT_USER, null);
                        reject();
                    } else {
                        const userResponse = mapOidcUserToUserCee(reponse.user);
                        store.commit(AuthStoreMethods.AUTH_SET_CURRENT_USER, userResponse);
                        resolve(userResponse);
                    }
                }).catch((_: User | null) => {
                    store.commit(AuthStoreMethods.AUTH_SET_CURRENT_USER, null);
                    reject();
                });
        });
    },

    // Logs out the current user.
    [AuthStoreMethods.LOGOUT]: (store: ActionContext<AuthState, RootState>): Promise<void> => {
        return new Promise<void>((resolve) => {
            Vue.prototype.$security.logout().then(() => {
                store.commit(AuthStoreMethods.AUTH_SET_CURRENT_USER, null);
                localStorage.clear();
                resolve();
            }).finally(() => {
                resolve();
            });
        });
    },
};

/**
 * Export du module
 */
export const AuthStore: Module<AuthState, RootState> = {
    state: new AuthState(),
    getters,
    mutations,
    actions,
    namespaced: false
};


// ===
// Store Plugins
// ===
export const authStoreTokenInterceptorPlugin = (store: Readonly<Store<RootState>>) => {
    let token: string | null = null;
    let tokenType: string | null = null;

    if (Vue.http.interceptors && Vue.http.interceptors.request) {
        const interceptors = Vue.http.interceptors;

        interceptors.request.use(async (config) => {
            if (!!token && !!tokenType && !config.headers.Authorization) {
                config.headers.Authorization = `${tokenType} ${token}`;
            } else if (!config.headers.Authorization) {
                const user: Oidc.User | null = await Vue.prototype.$security.getUser();
                if (!!user) {
                    config.headers.Authorization = `Bearer ${user.access_token}`;
                }
            }
            return config;
        },
            (error) => Promise.reject(error),
        );
    }

    store.subscribe((mutation: any, state: any) => {
        if (mutation.type === `${AuthStoreMethods.AUTH_SET_CURRENT_USER}` && (!!state && !!state.auth)) {
            token = state.auth.accessToken || null;
            tokenType = state.auth.tokenType || null;
        }
    });
};

// ===
// Private helpers
// ===

const mapOidcUserToUserCee = (response?: Oidc.User | null): User | null => {
    if (!!response) {
        const userString = JSON.stringify({
            idToken: response && response.id_token,
            sessionState: response && response.session_state,
            accessToken: response && response.access_token,
            refreshToken: '',
            tokenType: response && response.token_type,
            scope: response && response.scope,
            profile: response && response.profile,
            expiresAt: response && response.expires_at,
        });
        return User.fromStorageString(userString);
    } else {
        return null;
    }
};
