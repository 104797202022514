
import { CreateElement } from 'vue';
import { RouteConfig } from 'vue-router';

type getInfoMetaCallback = (itemRouteParam: RouteConfig) => string | null | undefined;
const getInfosMeta = (itemRoute: RouteConfig, key: string, infoMetaCallback?: getInfoMetaCallback | null): string | null | undefined => {
    if (itemRoute) {
        const callback = infoMetaCallback || ((itemRouteParam: RouteConfig) => itemRouteParam.name);
        const defaultValue: string | null | undefined = callback(itemRoute);
        const meta = itemRoute.meta || {};
        const item = meta[key] || (() => defaultValue);
        return item === 'function' ? item() : item;
    }
    return null;
};

const getRouteTitle = (itemRoute: RouteConfig): string | null | undefined => getInfosMeta(itemRoute, 'title');
const getRouteIcon = (itemRoute: RouteConfig): string | null | undefined => getInfosMeta(itemRoute, 'icon');
export const buildRoute = (item: RouteConfig): any => ({
    name: item.name,
    redirect: item.redirect,
    title: getRouteTitle(item),
    icon: getRouteIcon(item),
});

export default {
    functional: true,
    props: {
        routes: {
            type: Array,
            required: false,
        },
    },
    // Render functions are an alternative to templates
    render(h: CreateElement, context: any) {

        const { props: { routes } } = context as ({ props: { routes: RouteConfig[] }; children: any });
        const { scopedSlots } = context.data;

        return (routes || new Array<RouteConfig>()).map((item: RouteConfig) => {
            const route = buildRoute(item);
            return scopedSlots.default({ route });
        });
    },
};

