import { RootState } from '@/store/state';
import { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import SecureLS from "secure-ls";
import { TemplateOperationCriteria } from '../../../views/Administration/TemplateOperations/List/TemplateOperationCriteria.model';
const ls = new SecureLS({ isCompression: true });

/**
 * States Template Operation.
 *
 * @export
 * @class TemplateOperationState
 */
export class TemplateOperationState {

    /**
     * Critères.
     *
     * @type {TemplateOperationCriteria}
     * @memberof TemplateOperationState
     */
    public criteresTemplateOperation: TemplateOperationCriteria = new TemplateOperationCriteria();
}

/**
 * Constantes pour les méthodes du store.
 */
export enum TemplateOperationStoreMethods {    
    ADD_LAST_FILTERS_TEMPLATE_OPERATION = 'ADD_LAST_FILTERS_TEMPLATE_OPERATION',
    GET_LAST_FILTERS_TEMPLATE_OPERATION = 'GET_LAST_FILTERS_TEMPLATE_OPERATION',
}

/**
 * Getters du store.
 */
const getters: GetterTree<TemplateOperationState, RootState> = {   
    [TemplateOperationStoreMethods.GET_LAST_FILTERS_TEMPLATE_OPERATION]: (state) => {      
        return state.criteresTemplateOperation;
    },
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<TemplateOperationState> = {    
    [TemplateOperationStoreMethods.ADD_LAST_FILTERS_TEMPLATE_OPERATION]: (state: TemplateOperationState, lastFilters: TemplateOperationCriteria) => {
        state.criteresTemplateOperation = lastFilters;
    }
};

/**
 * Actions du store.
 */
const actions: ActionTree<TemplateOperationState, RootState> = {  
};

/**
 * Export du module
 */
export const TemplateOperationStore: Module<TemplateOperationState, RootState> = {
    state: new TemplateOperationState(),
    getters,
    mutations,
    actions,
    namespaced: false
};