import { CriteriaBase } from '@/shared/models';
import { EnumProfilsAsNumber } from '@/shared/enums';

/**
 * Classe de modèle pour la recherche dans le back sur un autocomplete.
 *
 */
export class UtilisateurParTypeCriteria extends CriteriaBase {

    /**
     * Input dans l'auto-complete sur lequel rechercher.
     */
    public recherche: string = '';

    /**
     * Le profil à rechercher.
     */
    public profils: EnumProfilsAsNumber[] = [EnumProfilsAsNumber.None];
}
