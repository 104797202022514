import { CriteriaBase } from '@/shared/models';

/**
 * Classe de modèle pour la recherche dans le back sur un autocomplete.
 * */
export class AutocompleteCriteria extends CriteriaBase {

    /**
     * Input dans l'auto-complete sur lequel rechercher.
     */
    public recherche: string = '';

    /**
     * Indique si tous les enregistrements sont demandés.
     */
    public isAllRequested: boolean = false;
}