import { PagingAndSorting } from './PagingAndSorting.model';

/**
 * Modèle de base pour les critérias.
 */
export class CriteriaBase {
    /**
     * La pagination.
     */
    public pagingAndSorting: PagingAndSorting;

    /*
     * Helper pour récupérer le query string.
     */
    get queryString(): string {
        return this.encode(this);
    }

    /**
     * Liste des filtres rapides (flags).
     */
    public filtresRapides: {[key: string]: boolean} = {};

    /**
     * Transforme un objet en querySting.
     */
    private encode(objet: object, nesting: string = ''): string {
        const pairs = Object.entries(objet).map(([key, val]) => {
            if (Array.isArray(val)) {
                return val.map((subVal) => [nesting + key, subVal].map(escape).join('=')).join('&');
            } else if (val === undefined || val === null) {
                return '';
            } else if (typeof val === 'object') {
                return this.encode(val, nesting + `${key}.`);
            } else {
                return [nesting + key, val].map(escape).join('=');
            }
        });
        return pairs.join('&');
    }
}
