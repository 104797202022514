// Helper pour URL.
export class UrlUtility {
    public static parseJwt = (token: string): any => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(atob(base64));
        } catch (e) {
            return null;
        }
    }

    public static addQueryParam(url: string, name: string, value: string) {
        if (url.indexOf('?') < 0) {
            url += '?';
        }

        if (url[url.length - 1] !== '?') {
            url += '&';
        }

        url += encodeURIComponent(name);
        url += '=';
        url += encodeURIComponent(value);

        return url;
    }

    public static parseFragmentsByUrl = (url: string): any => {
        const hasTagDelimeter = '#';
        const useHastag = url.includes(hasTagDelimeter);
        const delimiter = useHastag ? '#' : '?';
        const fragments = UrlUtility.parseUrlFragment(url, delimiter);
        return fragments;
    }

    public static parseUrlFragment(value: string, delimiter = '#'): any {
        let idx = value.lastIndexOf(delimiter);
        if (idx >= 0) {
            value = value.substr(idx + 1);
        }

        if (delimiter === '?') {
            // If we're doing query, then strip off hash fragment before we parse
            idx = value.indexOf('#');
            if (idx >= 0) {
                value = value.substr(0, idx);
            }
        }

        const splitter = '&';
        const params = value.split(splitter);
        const result = {} as any;
        params.forEach((item) => {
            const tmp = item.split('=');
            result[decodeURIComponent(tmp[0]) as any] = decodeURIComponent(tmp[1]);
        });

        return result;
    }

    /*
    *
    * https://base64.guru/developers/javascript/examples/unicode-strings
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/264
    * ASCII to Unicode (decode Base64 to original data)
    * @param {string} b64
    * @return {string}
    */
    public static atou = (b64: string) => {
        return decodeURIComponent(escape(atob(b64)));
    }

    /**
     * Unicode to ASCII (encode data to Base64)
     * @param {string} data
     * @return {string}
     */
    public static utoa = (data: string) => {
        return btoa(unescape(encodeURIComponent(data)));
    }

    /*
     * https://howchoo.com/g/nwywodhkndm/how-to-turn-an-object-into-query-string-parameters-in-javascript
     * https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
     * https://gist.github.com/tjmehta/9204891
     *
     *
    */
    public static convertJsonToQueryString = (params: {}): string | null => {
        const queryString = Object.entries(params).map(([key, value]) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(value as string);
        }).join('&');

        return queryString || null;
    }
}
