import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { RootState } from '@/store/state';
import ApiHelper from '@/services/ApiHelper';
import { StringHelper } from '../../../shared/helpers/StringHelper';

/**
 * Classe de gestion d'état pour les valeurs référentielles.
 */
export class ReferentielState {

    /**
     * Les valeurs référentielles.
     */
    public valeursReferentiellesDictionary: { [cle: string]: ValeurReferentielle[] };

    /**
     * Constructeur.
     */
    constructor() {
        // Initialisation du dictionnaire.
        this.valeursReferentiellesDictionary = {};
        for (const type in TypeValeurReferentielle) {
            if (!Number(type)) {
                const typeValeurCamelCase = StringHelper.toCamelCase(type);
                this.valeursReferentiellesDictionary[`${typeValeurCamelCase}/obtenirTous/`] = [];
            }
        }
    }
}

/**
 * Constantes pour les méthodes du store.
 */
export enum ReferentielStoreMethods {
    VALEURS_REFERENTIELLES_DICTIONARY = 'VALEURS_REFERENTIELLES_DICTIONARY',
    SET_VALEURS_REFERENTIELLES = 'SET_VALEURS_REFERENTIELLES',
    GET_VALEURS_REFERENTIELLES = 'GET_VALEURS_REFERENTIELLES',
    GET_VALEURS_REFERENTIELLES_URI_PATH = 'GET_VALEURS_REFERENTIELLES_URI_PATH'
}

/**
 * Retourne la clé de getter pour un type de valeur référentielle.
 * @param typeValeur Le type de valeur référentielle.
 */
export function getterKeyReferentiel(typeValeur: TypeValeurReferentielle): string {
    const typeValeurCamelCase = StringHelper.toCamelCase(typeValeur);
    return `${typeValeurCamelCase}/obtenirTous/`;
}

/**
 * Getters du store.
 */
const getters: GetterTree<ReferentielState, RootState> = {
    // Récupération des valeurs référentielles.
    [getterKeyReferentiel(TypeValeurReferentielle.TypeVoie)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeVoie)],
    [getterKeyReferentiel(TypeValeurReferentielle.Civilite)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.Civilite)],
    [getterKeyReferentiel(TypeValeurReferentielle.FormeJuridique)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.FormeJuridique)],
    [getterKeyReferentiel(TypeValeurReferentielle.StatutSimulation)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.StatutSimulation)],
    [getterKeyReferentiel(TypeValeurReferentielle.StatutDossier)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.StatutDossier)],
    [getterKeyReferentiel(TypeValeurReferentielle.Secteur)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.Secteur)],
    [getterKeyReferentiel(TypeValeurReferentielle.ClientRepresente)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.ClientRepresente)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeBeneficiaire)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeBeneficiaire)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeInstallateur)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeInstallateur)],
    [getterKeyReferentiel(TypeValeurReferentielle.RoleAccordCadre)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.RoleAccordCadre)],
    [getterKeyReferentiel(TypeValeurReferentielle.Arrete)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.Arrete)],
    [getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeBloc)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeBloc)],
    [getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeChamp)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeChamp)],
    [getterKeyReferentiel(TypeValeurReferentielle.TemplateChamp)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TemplateChamp)],
    [getterKeyReferentiel(TypeValeurReferentielle.Departement)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.Departement)],
    [getterKeyReferentiel(TypeValeurReferentielle.ObjetDemande)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.ObjetDemande)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeCee)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeCee)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeContribution)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeContribution)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeCompteSyndic)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeCompteSyndic)],
    [getterKeyReferentiel(TypeValeurReferentielle.ParcoursUtilisateur)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.ParcoursUtilisateur)],
    [getterKeyReferentiel(TypeValeurReferentielle.AttestationHonneur)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.AttestationHonneur)],
    [getterKeyReferentiel(TypeValeurReferentielle.StatutCommercial)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.StatutCommercial)],
    [getterKeyReferentiel(TypeValeurReferentielle.StatutPncee)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.StatutPncee)],
    [getterKeyReferentiel(TypeValeurReferentielle.AbandonRefus)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.AbandonRefus)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeDocument)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeDocument)],
    [getterKeyReferentiel(TypeValeurReferentielle.RetourOrganismeControle)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.RetourOrganismeControle)],
    [getterKeyReferentiel(TypeValeurReferentielle.StatutEchantillon)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.StatutEchantillon)],
    [getterKeyReferentiel(TypeValeurReferentielle.EtatTraitement)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.EtatTraitement)],
    [getterKeyReferentiel(TypeValeurReferentielle.TypeControle)]: (state) => state.valeursReferentiellesDictionary[getterKeyReferentiel(TypeValeurReferentielle.TypeControle)],
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<ReferentielState> = {
    // Modification des données d'un type de valeur référentielle.
    [ReferentielStoreMethods.SET_VALEURS_REFERENTIELLES]:
        (state: ReferentielState, payload: { cle: string, valeursReferentielles: ValeurReferentielle[] }) =>
            state.valeursReferentiellesDictionary[payload.cle] = payload.valeursReferentielles,
};

/**
 * Actions du store.
 */
const actions: ActionTree<ReferentielState, RootState> = {

    /**
     * Récupération en BDD des valeurs d'un type référentiel ou en cache si elles ont déjà été récupérées.
     */
    [ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES]: (store: ActionContext<ReferentielState, RootState>, type: TypeValeurReferentielle): Promise<ValeurReferentielle[]> => {
        return store.dispatch(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES_URI_PATH, getterKeyReferentiel(type));
    },

    /**
     * Récupération en BDD des valeurs d'un type référentiel ou en cache si elles ont déjà été récupérées pour un template d'opération et un arrêté spécifique.
     */
    [ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES_URI_PATH]: (store: ActionContext<ReferentielState, RootState>, uriPath: string): Promise<ValeurReferentielle[]> => {
        return new Promise<ValeurReferentielle[]>((resolve) => {

            // On vérifie que la valeur référentielles n'a pas déjà été chargée.
            if (store.state.valeursReferentiellesDictionary[uriPath] === undefined || store.state.valeursReferentiellesDictionary[uriPath].length === 0) {
                // Initialisation de l'entrée dans le store.
                store.commit(ReferentielStoreMethods.SET_VALEURS_REFERENTIELLES, { cle: uriPath, valeursReferentielles: [] });

                // Appel API pour récupérer la liste.
                ApiHelper.getAll<ValeurReferentielle>(uriPath).then((values: ValeurReferentielle[]) => {
                    store.commit(ReferentielStoreMethods.SET_VALEURS_REFERENTIELLES, { cle: uriPath, valeursReferentielles: values });
                    resolve(store.state.valeursReferentiellesDictionary[uriPath]);
                });

            } else {
                // Si des éléments sont déjà chargés pour ce type, la base n'est pas appelée, et le store pas impactée.
                resolve(store.state.valeursReferentiellesDictionary[uriPath]);
            }
        });
    },
};

/**
 * Export du module
 */
export const ReferentielStore: Module<ReferentielState, RootState> = {
    state: new ReferentielState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
