






import Vue from 'vue';
import NavBar from '@/components/Navbar.vue';
export default Vue.extend({
    name: `Header`,
    components: { NavBar },
});
