import { Societe } from './Societe.model';
import { Adresse } from './Adresse.model';
import { Utility } from '@/shared/helpers';
import { PrescripteurInterne } from './PrescripteurInterne.model';
import { EtatContratPartenariat } from '@/shared/enums';


export class Utilisateur {
    public static BuildUtilisateurDeTest(): Utilisateur {
        const user = new Utilisateur();
        // Identité.
        user.civiliteId = 1;
        user.firstName = 'Prenom_' + Utility.rand();
        user.lastName = 'Nom_' + Utility.rand();
        user.fonction = 'Fonction_' + Utility.rand();
        user.phoneNumber = '0555555555';
        // Votre entreprise.
        const adresse = new Adresse();
        adresse.numeroVoie = '1';
        adresse.typeVoieId = 3;
        adresse.nomVoie = 'nomVoie' + Utility.rand();
        adresse.villeId = 1;
        const societe = new Societe();
        societe.raisonSociale = 'RaisonSociale_' + Utility.rand();
        societe.capital = '150';
        societe.formeJuridiqueId = 1;
        societe.siret = '82845747300019';
        societe.lieuImmatriculation = 'LieuImmatriculation' + Utility.rand();
        societe.adresse = adresse;
        user.societes = [societe];
        // Vos identifiants de connexion
        user.email = `test_${Utility.rand()}@test.fr`;
        const pass = 'Comptecee1!';
        user.password = pass;
        return user;
    }
    public id!: number;
    public civiliteId!: number;
    public userName!: string;
    public email!: string;
    public lastName!: string;
    public firstName!: string;
    public fonction!: string;
    public phoneNumber!: string;
    public prescripteurInterneId: number = -1;
    public password!: string;
    public societes: Societe[] = new Array<Societe>();
    public typeCompteSyndicId?: number;
    public profilUtilisateur!: string;
    public isActive!: boolean;
    public EmailConfirmed!: boolean;
    public isDeleted!: boolean;
    public prenomNom: string;
    public prescripteurInterne: PrescripteurInterne = null;
    public etatContratPartenariatId: EtatContratPartenariat = null;
    public dateActivationCompte: string = '';
    public datePlanifieDesactivation: string = '';

    /**
     * Définit si un utilisateur ayant un profil autre qu'Interne a le droit d’accéder à la bonification CPE.
     * @type {boolean}
     * @memberof Utilisateur
     */
    public hasAccesBonificationCpe: boolean;

    /**
     * Définit si le seuil minimum pour une opération pour est désactivé pour un utilisateur.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public isSeuilMinimumOperationDesactive: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder a la Bonification SME.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesBonificationSme: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder à la coche PNAQ.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesCochePnaq: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Coup de pouce.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsCoupDePouce: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Non standard.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsNonStandard: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Réseau.
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsReseau: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Transport.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsTransport: boolean;

    /**
     * Définit si l'utilisateur peut créer des simulations ou des dossiers.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesCreationSimulationDossier: boolean;

    constructor() {
        if (this.societes.length === 0) {
            this.societes.push(new Societe());
        }
    }

    public addSociete(): void {
        this.societes.push(new Societe());
    }

    public removeSociete(): void {
        this.societes.pop();
    }
}
