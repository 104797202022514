import { Result } from '@/shared/models';
import { AutocompleteCriteria } from './models/AutocompleteCriteria.model';
import { ApiService } from './base/ApiService';
import { AxiosResponse } from 'axios';
import { UtilisateurParTypeCriteria } from './models/UtilisateurParTypeCriteria.model';
import { EnumProfilsAsNumber } from '@/shared/enums/Profils.enum';

/**
 * Classe d'HELPER pour les appels au back pour un utilisateur.
 * */
// tslint:disable-next-line:no-namespace
export module ApiAutocompleteHelper {

    /**
     * Promesse pour l'AutoComplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    export function getAutocompletePromise<T>(recherche: string, endpoint: string, isAllRequested?: boolean): Promise <T[]> {
        const apiService = new ApiService<T>(endpoint);
        const criteria = new AutocompleteCriteria();
        criteria.recherche = recherche;
        criteria.isAllRequested = isAllRequested;
        return new Promise<T[]>((resolve, reject) => {
            apiService.getWhere(criteria.queryString).then((reponse: AxiosResponse<Result<T[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }

    /**
     * Promesse pour l'AutoComplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    export function getAutocompletebyIdPromise<T>(id: number, endpoint: string): Promise<T> {
        const apiService = new ApiService<T>(endpoint);
        return new Promise<T>((resolve, reject) => {
            apiService.get(id).then((reponse: AxiosResponse<Result<T>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }

    /**
     * Promesse pour l'AutoComplete des utilisateurs.
     * @param nom Critère de recherche.
     */
    export function getAutocompleteByUtilisateurCriteriaPromise<T>(recherche: string, profils: EnumProfilsAsNumber[], endpoint: string): Promise<T[]> {
        const apiService = new ApiService<T>(endpoint);
        return new Promise<T[]>((resolve, reject) => {
            const criteria = new UtilisateurParTypeCriteria();
            criteria.recherche = recherche;
            criteria.profils = profils;
            apiService.getWhere(criteria.queryString).then((reponse: AxiosResponse<Result<T[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }
}

export default ApiAutocompleteHelper;