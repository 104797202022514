import { Utilisateur, Societe, Contact } from '@/models';
import { UtilisateurIdentity, UtilisateurConnexion, UtilisateurEmail } from '@/components/FormsUtilisateur/models/';
import { UtilisateurDroitsAcces } from '../models/UtilisateurDroitsAcces';
import { TypeContact } from '@/shared/enums/TypeContact.enum';

export class MapUtilisateur {
    // Export
    public static toIdentity(utilisateur: Utilisateur): UtilisateurIdentity {
        return new UtilisateurIdentity(
            utilisateur.id,
            utilisateur.civiliteId,
            utilisateur.typeCompteSyndicId,
            utilisateur.lastName,
            utilisateur.firstName,
            utilisateur.fonction,
            utilisateur.phoneNumber
        );
    }
    public static toConnexion(utilisateur: Utilisateur): UtilisateurConnexion {
        return new  UtilisateurConnexion(
            utilisateur.email,
            utilisateur.userName,
            utilisateur.password,
        );
    }
    public static toSociete(utilisateur: Utilisateur): Societe[] {
        utilisateur.societes.forEach(
            (s) => {
                s.accordCadres = [];
                s.utilisateurId = utilisateur.id;
                s.estSousTraitant = !s.contacts.find((c) => c.typeContactId === TypeContact.SousTraitant);
                s.signataire = s.signataire ?  s.signataire : new Contact(TypeContact.Signataire);
                s.interlocuteur = s.interlocuteur ? s.interlocuteur : new Contact(TypeContact.Interlocuteur);
                s.sousTraitant = s.estSousTraitant ? new Contact(TypeContact.SousTraitant) : s.sousTraitant;
            });
        return utilisateur.societes;
    }

    /**
     * Crée un {UtilisateurDroitsAcces} à partir d'un type {Utilisateur}.
     *
     * @static
     * @param {Utilisateur} utilisateur
     * @returns {UtilisateurDroitsAcces}
     * @memberof MapUtilisateur
     */
    public static toDroitsAcces(utilisateur: Utilisateur): UtilisateurDroitsAcces {
        return new UtilisateurDroitsAcces(utilisateur.id,
            utilisateur.hasAccesBonificationCpe,
            utilisateur.hasAccesBonificationSme,
            utilisateur.hasAccesCochePnaq,
            utilisateur.hasAccesOperationsCoupDePouce,
            utilisateur.hasAccesOperationsReseau,
            utilisateur.hasAccesOperationsTransport,
            utilisateur.hasAccesOperationsNonStandard,
            utilisateur.isSeuilMinimumOperationDesactive,
            utilisateur.hasAccesCreationSimulationDossier
        );
    }
    /**
     * Met à jour un {Utilisateur} à partir d'un type {UtilisateurDroitsAcces}.
     *
     * @static
     * @param {Utilisateur} utilisateur
     * @returns {UtilisateurDroitsAcces}
     * @memberof MapUtilisateur
     */
    public static fromDroitsAcces(utilisateur: Utilisateur, utilisateurDroitsAcces: UtilisateurDroitsAcces): Utilisateur {
        if (utilisateurDroitsAcces.userId === utilisateur.id) {
            utilisateur.hasAccesBonificationCpe = utilisateurDroitsAcces.hasAccesBonificationCpe;
            utilisateur.hasAccesBonificationSme = utilisateurDroitsAcces.hasAccesBonificationSme;
            utilisateur.hasAccesCochePnaq = utilisateurDroitsAcces.hasAccesCochePnaq;
            utilisateur.hasAccesOperationsCoupDePouce = utilisateurDroitsAcces.hasAccesOperationsCoupDePouce;
            utilisateur.hasAccesOperationsReseau = utilisateurDroitsAcces.hasAccesOperationsReseau;
            utilisateur.hasAccesOperationsTransport = utilisateurDroitsAcces.hasAccesOperationsTransport;
            utilisateur.hasAccesOperationsNonStandard = utilisateurDroitsAcces.hasAccesOperationsNonStandard;
            utilisateur.isSeuilMinimumOperationDesactive = utilisateurDroitsAcces.isSeuilMinimumOperationDesactive;
            utilisateur.hasAccesCreationSimulationDossier = utilisateurDroitsAcces.hasAccesCreationSimulationDossier;
        }
        return utilisateur;
    }
    public static toPrescripteurInterne(utilisateur: Utilisateur): number {
        return utilisateur.prescripteurInterneId;
    }
    public static toProfile(utilisateur: Utilisateur): string {
        return utilisateur.profilUtilisateur;
    }
    public static toEmail(utilisateur: Utilisateur): UtilisateurEmail {
        return new UtilisateurEmail(0, utilisateur.email, '');
    }

    // Import
    public static fromPartialObject(partialUtilisateur: any, utilisateur: Utilisateur ) {
        if (partialUtilisateur instanceof UtilisateurIdentity) {
            utilisateur.lastName = partialUtilisateur.lastName;
            utilisateur.firstName = partialUtilisateur.firstName;
            utilisateur.phoneNumber = partialUtilisateur.phoneNumber;
            utilisateur.fonction = partialUtilisateur.fonction;
            utilisateur.civiliteId = partialUtilisateur.civiliteId;
            utilisateur.typeCompteSyndicId = partialUtilisateur.typeCompteSyndicId;
        } else if (partialUtilisateur instanceof UtilisateurConnexion) {
            utilisateur.email = partialUtilisateur.email;
            utilisateur.password = partialUtilisateur.password;
            utilisateur.userName = partialUtilisateur.email;
      }
    }
    public static fromSociete(utilisateurSct: Societe[], utilisateur: Utilisateur) {
        utilisateur.societes = utilisateurSct;
        utilisateur.societes.forEach((s: Societe) => {
            s.contacts = [];
            if (!s.estUtilisateurSignataire) {
                s.contacts.push(s.signataire);
            }
            if (!s.estUtilisateurInterlocuteur) {
                s.contacts.push(s.interlocuteur);
            }
            if (!s.estSousTraitant) {
                s.contacts.push(s.sousTraitant);
            }
        });
    }
    public static fromPrescripteurInterne(prescripteurInterneId: number, utilisateur: Utilisateur) {
        utilisateur.prescripteurInterneId = prescripteurInterneId;
    }
    public static fromProfile(profile: string, utilisateur: Utilisateur) {
        utilisateur.profilUtilisateur = profile;
    }

}
