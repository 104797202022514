/**
 * Classe MontRetroceder.
 *
 * @export
 * @class MontRetroceder
 */
export default class MontantRetroceder {

    /**
     * Identifiant de la simulation.
     *
     * @type {number}
     * @memberof MontRetroceder
     */
    public id: number;

    /**
     * Montant à rétrocéder.
     *
     * @type {number}
     * @memberof MontRetroceder
     */
    public montant: number;

    /**
     * Nom du syndic.
     *
     * @type {string}
     * @memberof MontRetroceder
     */
    public nomSyndic: string;

    /**
     * Est Sdc.
     *
     *
     * @type {boolean}
     * @memberof MontantRetroceder
     */
    public estSdc: boolean;
    /**
     * Création d'une instance de SimulationSociete.
     * @memberof MontRetroceder
     */
    constructor();
    constructor(id: number, montant: number, nomSyndic: string, estSdc: boolean);
    constructor(id?: number, montant?: number, nomSyndic?: string, estSdc?: boolean) {
        this.id = id || 0;
        this.montant = montant || 0;
        this.nomSyndic = nomSyndic || '';
        this.estSdc = estSdc || false;
    }
}
