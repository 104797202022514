import Vue from 'vue';
import VGrid from 'vuetify/es5/components/VGrid';
import transitions from 'vuetify/es5/components/transitions';
import { Scroll } from 'vuetify/es5/directives';
import fr from 'vuetify/src/locale/fr';
import 'vuetify/dist/vuetify.min.css';

import Vuetify, {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAlert,
    VAutocomplete,
    //VAvatar,
    VBadge,
    //VBanner,
    //VBottomNavigation,
    VBottomSheet,
    //VBreadcrumbs,
    //VBreadcrumbsItem,
    //VBreadcrumbsDivider,
    VBtn,
    VBtnToggle,
    //VCalendar,
    //VCalendarDaily,
    //VCalendarWeekly,
    //VCalendarMonthly,
    VCard,
    VCardTitle,
    VCardActions,
    VCardText,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VSimpleCheckbox,
    VChip,
    VChipGroup,
    //VColorPicker,
    //VColorPickerSwatches,
    //VColorPickerCanvas,
    VContent,
    VCombobox,
    VCounter,
    VData,
    VDataIterator,
    VDataFooter,
    VDataTable,
    VEditDialog,
    //VTableOverflow,
    VDataTableHeader,
    VSimpleTable,
    VVirtualTable,
    VDatePicker,
    VDatePickerTitle,
    VDatePickerHeader,
    VDatePickerDateTable,
    VDatePickerMonthTable,
    VDatePickerYears,
    VDialog,
    VDivider,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFileInput,
    VFooter,
    VForm,
    VContainer,
    VCol,
    VRow,
    VSpacer,
    VLayout,
    //VFlex,
    VHover,
    VIcon,
    VImg,
    VInput,
    VItem,
    VItemGroup,
    VLabel,
    VListItemActionText,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemIcon,
    VListItemGroup,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VOverflowBtn,
    VOverlay,
    VPagination,
    VSheet,
    //VParallax,
    VPicker,
    VProgressCircular,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    //VRangeSlider,
    //VRating,
    //VResponsive,
    VSelect,
    //VSkeletonLoader,
    VSlider,
    VSlideGroup,
    VSlideItem,
    VSnackbar,
    //VSparkline,
    //VSpeedDial,
    //VStepper,
    //VStepperContent,
    //VStepperStep,
    //VStepperHeader,
    //VStepperItems,
    VSubheader,
    VSwitch,
    VSystemBar,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTabsSlider,
    VTextarea,
    VTextField,
    //VTimeline,
    //VTimelineItem,
    //VTimePicker,
    //VTimePickerClock,
    //VTimePickerTitle,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTooltip,
    //VTreeview,
    //VTreeviewNode,
    //VWindow,
    //VWindowItem,
    VCarouselTransition,
    VCarouselReverseTransition,
    VTabTransition,
    VTabReverseTransition,
    VMenuTransition,
    VFabTransition,
    VDialogTransition,
    VDialogBottomTransition,
    VFadeTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollXReverseTransition,
    VScrollYTransition,
    VScrollYReverseTransition,
    VSlideXTransition,
    VSlideXReverseTransition,
    VSlideYTransition,
    VSlideYReverseTransition,
    VExpandTransition,
    VExpandXTransition,
} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        // Spécial.
        VGrid,
        transitions,
        //
        VApp,
        VAppBar,
        VAppBarNavIcon,
        VAlert,
        VAutocomplete,
        //VAvatar,
        VBadge,
        //VBanner,
        //VBottomNavigation,
        VBottomSheet,
        //VBreadcrumbs,
        //VBreadcrumbsItem,
        //VBreadcrumbsDivider,
        VBtn,
        VBtnToggle,
        //VCalendar,
        //VCalendarDaily,
        //VCalendarWeekly,
        //VCalendarMonthly,
        VCard,
        VCardTitle,
        VCardActions,
        VCardText,
        VCarousel,
        VCarouselItem,
        VCheckbox,
        VSimpleCheckbox,
        VChip,
        VChipGroup,
        //VColorPicker,
        //VColorPickerSwatches,
        //VColorPickerCanvas,
        VContent,
        VCombobox,
        VCounter,
        VData,
        VDataIterator,
        VDataFooter,
        VDataTable,
        VEditDialog,
        //VTableOverflow,
        VDataTableHeader,
        VSimpleTable,
        VVirtualTable,
        VDatePicker,
        VDatePickerTitle,
        VDatePickerHeader,
        VDatePickerDateTable,
        VDatePickerMonthTable,
        VDatePickerYears,
        VDialog,
        VDivider,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        VFileInput,
        VFooter,
        VForm,
        VContainer,
        VCol,
        VRow,
        VSpacer,
        VLayout,
        //VFlex,
        VHover,
        VIcon,
        VImg,
        VInput,
        VItem,
        VItemGroup,
        VLabel,
        VListItemActionText,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,
        VList,
        VListGroup,
        VListItem,
        VListItemAction,
        VListItemAvatar,
        VListItemIcon,
        VListItemGroup,
        VMenu,
        VMessages,
        VNavigationDrawer,
        VOverflowBtn,
        VOverlay,
        VPagination,
        VSheet,
        //VParallax,
        VPicker,
        VProgressCircular,
        VProgressLinear,
        VRadioGroup,
        VRadio,
        //VRangeSlider,
        //VRating,
        //VResponsive,
        VSelect,
        //VSkeletonLoader,
        VSlider,
        VSlideGroup,
        VSlideItem,
        VSnackbar,
        //VSparkline,
        //VSpeedDial,
        //VStepper,
        //VStepperContent,
        //VStepperStep,
        //VStepperHeader,
        //VStepperItems,
        VSubheader,
        VSwitch,
        VSystemBar,
        VTabs,
        VTab,
        VTabItem,
        VTabsItems,
        VTabsSlider,
        VTextarea,
        VTextField,
        //VTimeline,
        //VTimelineItem,
        //VTimePicker,
        //VTimePickerClock,
        //VTimePickerTitle,
        VToolbar,
        VToolbarItems,
        VToolbarTitle,
        VTooltip,
        //VTreeview,
        //VTreeviewNode,
        //VWindow,
        //VWindowItem,
        VCarouselTransition,
        VCarouselReverseTransition,
        VTabTransition,
        VTabReverseTransition,
        VMenuTransition,
        VFabTransition,
        VDialogTransition,
        VDialogBottomTransition,
        VFadeTransition,
        VScaleTransition,
        VScrollXTransition,
        VScrollXReverseTransition,
        VScrollYTransition,
        VScrollYReverseTransition,
        VSlideXTransition,
        VSlideXReverseTransition,
        VSlideYTransition,
        VSlideYReverseTransition,
        VExpandTransition,
        VExpandXTransition,
    }
});

// - Ajout de la configuration-//
const vuetifyOpts = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#039ee0', // Light blue.
                secondary: '#039ee0', // Light blue.
                accent: '#008737', // Green.
            },
            dark: {
                primary: '#4169E1',
                white: '#ffffff',
                accent: '#111',
                error: '#b71c1c',
                warning: '#DF9627',
                grayf1: '#F8F8F9',
                grayDE: '#EDEDEE',
                grayCE: '#969696',
                avatar: '#FC6A70',
                green: '#69B769'
            }
        },
    },
    icons: {
        iconfont: 'md' || 'fa' as any,
    },
    directives: {
        Scroll
    },
    lang: {
        locales: { fr },
        current: 'fr'
    },
};
export default new Vuetify(vuetifyOpts);
