import AccordsCadresGeneral from '@/components/AccordsCadres/models/AccordsCadresGeneral';
import { Societe, AccordsCadresSociete, DroitsAccordsCadres } from '@/models';

export class AccordCadre extends AccordsCadresGeneral {
    /**
     * Les sociétés membres de l'accord cadre.
     *
     * @type {Societe[]}
     * @memberof AccordCadre
     */
    public membres: Societe[] = new Array<Societe>();
    /**
     * lien entre société accord cadre et le role.
     *
     * @type {AccordsCadresSociete[]}
     * @memberof AccordCadre
     */
    public accordCadreSocietes: AccordsCadresSociete[] = new Array<AccordsCadresSociete>();
    /**
     * Droits de la société courante sur les sociétés de l'accord cadre.
     *
     * @type {DroitsAccordsCadres[]}
     * @memberof AccordsCadresSociete
     */
    public droitsAccordsCadres: DroitsAccordsCadres[] = new Array<DroitsAccordsCadres>();
}
