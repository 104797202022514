// Helpers.
export class Utility {
    public static rand = () => (Date.now() + '' + Math.random()).replace('.', '');
}

/**
 * Générer un UUID.
 */
export const uuidv4 = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

/**
 * Générer un UUID.
 */
export const createUUID = (): string => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

/**
 * Permet de savoir si c'est un objet est défini ou pas.
 */
export const isNullOrUndefined = (value: any): boolean => (typeof value === 'undefined' || value === null);

/**
 * Permet de savoir si c'est une chaîne est définie ou pas et aussi vide ou pas.
 */
export const isNullOrEmpty = (s: string | number | boolean | null) => {
    return isNullOrUndefined(s) || (s == null || s === '' || s === ' ');
}
/**
 * Permet de savoir si c'est un booléen.
 */
export const isBoolean = (value: any): boolean => ('boolean' === typeof value);

/**
 * Permet de recuperer le nombre de decimal d'un nombre à virgule
 * @param value
 */
export const getPrecisionFromNumber = (value: number): number => {
    if (!isFinite(value)) return 0;
    let e = 1, p = 0;
    while (Math.round(value * e) / e !== value) { e *= 10; p++; }
    return p;
};

/**
* Format un nombre en limitant à 2 décimales.
*
* @param valeur
*/
export const formatNumber = (valeur: number): number => {
    return Number(valeur.toFixed(2));
}
