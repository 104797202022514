/**
 * Helper pour la gestion des string.
 */
export class StringHelper {
    /**
     * Enlève le trailing slash si present.
     * @param str : chaine à traiter
     */
    public static removeTrailingSlash(str: string): string {
        return stripTrailingSlash(str);
    }

    public static toCamelCase(str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
}

const stripTrailingSlash = (str: string): string => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};
