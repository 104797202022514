import { AccordCadre, Societe, AccordsCadresSociete } from '@/models';
import AccordsCadresGeneral from '@/components/AccordsCadres/models/AccordsCadresGeneral';

/**
 * Class servant à mapper les objets d'un composant parent vers un composant fils et vice versa
 *
 * @export
 * @class MapAccordCadre
 */
export class MapAccordCadre {
    /**
     * Mapper de l'objet AccordCadre (composant parent) à AccordCadreGeneral (composant fils)
     *
     * @static
     * @param {AccordCadre} accordsCadres
     * @returns {AccordsCadresGeneral}
     * @memberof MapAccordCadre
     */
    public static toGeneralAC(accordsCadres: AccordCadre): AccordsCadresGeneral {
       return new AccordsCadresGeneral(
            accordsCadres.id,
            accordsCadres.nom,
            accordsCadres.dateDebut,
            accordsCadres.dateFin,
            accordsCadres.valorisationClassique,
            accordsCadres.valorisationPrecarite,
            accordsCadres.volumeMaxClassique,
            accordsCadres.volumeMaxPrecarite,
            accordsCadres.fichierContrat,
            accordsCadres.responsableId,
        );
    }
    /**
     * Mapper de l'objet AccordCadre (composant parent) à Societes MembresAC (composant fils)
     *
     * @static
     * @param {AccordCadre} accordsCadres
     * @returns {Societe[]}
     * @memberof MapAccordCadre
     */
    public static toSociete(accordsCadres: AccordCadre): AccordsCadresSociete[] {
        return accordsCadres.accordCadreSocietes;
    }
    /**
     * Mapper de l'objet Societes (composant fils) à AccordCadre (composant parent)
     *
     * @static
     * @param {Societe[]} societes
     * @param {AccordCadre} accordsCadres
     * @memberof MapAccordCadre
     */
    public static fromSociete(societes: Societe[], accordsCadres: AccordCadre) {
        accordsCadres.membres = societes;
    }
    /**
     * Mapper de l'objet AccordCadreGeneral (composant fils) à AccordCadre (composant parent)
     *
     * @static
     * @param {AccordsCadresGeneral} accordsCadresGeneral
     * @param {AccordCadre} accordsCadres
     * @memberof MapAccordCadre
     */
    public static fromGeneralAC(accordsCadresGeneral: AccordsCadresGeneral, accordsCadres: AccordCadre) {
        accordsCadres.id = accordsCadresGeneral.id;
        accordsCadres.nom = accordsCadresGeneral.nom;
        accordsCadres.dateDebut = accordsCadresGeneral.dateDebut;
        accordsCadres.dateFin = accordsCadresGeneral.dateFin;
        accordsCadres.valorisationClassique = accordsCadresGeneral.valorisationClassique;
        accordsCadres.valorisationPrecarite = accordsCadresGeneral.valorisationPrecarite;
        accordsCadres.volumeMaxClassique = accordsCadresGeneral.volumeMaxClassique;
        accordsCadres.volumeMaxPrecarite = accordsCadresGeneral.valorisationPrecarite;
        accordsCadres.fichierContrat = accordsCadresGeneral.fichierContrat;
        accordsCadres.responsableId = accordsCadresGeneral.responsableId;
    }
}
