/**
 * Énumération de statuts des opérations.
 */
export enum StatutOperation {

    /**
     * Statut d'une operation dont tous les champs ne sont pas complétés.
     */
    Partiele = 'Partiele',

    /**
     * Statut d'une operation affichée : saisie en cours.
     */
    EnCours = 'EnCours',

    /**
     *  Statut d'une opération dont tous les champs sont complétés.
     */
    Complete = 'Complete'
}

/**
 * Dictionnaire avec les libellés des statuts d'opération.
 * */
export const libelleStatutOperation: { [statut: string]: string } = {
    [StatutOperation.Partiele.toString()]: 'Saisie des données de calcul incomplète',
    [StatutOperation.EnCours.toString()]: 'Saisie de l\'opération incomplète',
    [StatutOperation.Complete.toString()]: 'Saisie de l\'opération complète',
}