import { StatutOperation } from '../components/Wizard/Commun/models/StatutOperation';
import { StatutPnceeAsNumber } from '../components/Wizard/Commun/models/StatutPncee';
import { Document, TemplateTypeBlocReferentiel } from '.';
import { DateHelper } from '@/shared/helpers/DateHelper';

export class Operation {
    /**
     * Identifiant de l'opération.
     *
     * @type {string}
     * @memberof Operation
     */
    public id: number = 0;
    /**
     * Permet d'identifier l'opération de façon unique.
     *
     * @type {string}
     * @memberof Operation
     */
    public uuid: string;
    /**
     * Permet d'identifier l'opération de façon unique.
     *
     * @type {string}
     * @memberof Operation
     */
    public config: object;

    /// <summary>
    /// Gets or sets the operation values.
    /// </summary>
    /// <value>The operation values.</value>
    public operationValues: { [key: string]: any };
    /**
     * Le statut de l'opération
     *
     * @type {StatutOperation}
     * @memberof Operation
     */
    public statutOperationId: StatutOperation;
    /**
     * Id du statut commercial de l'opération
     *
     * @type {StatutCommercialOperation}
     * @memberof Operation
     */
    public statutCommercialId: number;
    /**
     * Le statut commercial de l'opération
     *
     * @type {string}
     * @memberof Operation
     */
    public statutCommercial: string;
    /**
     * Action à payer.
     *
     * @type {boolean}
     * @memberof Operation
     */
    public actionAPayer: boolean = false;
    /**
     * Obtient ou définit le motif AbandonRefus de l'opération.
     *
     * @type {boolean}
     * @memberof Operation
     */
    public abandonRefusId?: number = null;
    /**
     * Le numéro de l'opération, ex: [Numéro dossier] - 01...
     *
     * @type {string}
     * @memberof Operation
     */
    public numero: string;

    /**
     * Le code de l'opération.
     *
     * @type {string}
     * @memberof Operation
     */
    public code: string;
    /**
     * L'Id d l'arrêté.
     *
     * @type {string}
     * @memberof Operation
     */
    public arreteId!: number;
    /**
     * Le libellé de l'arrêté.
     *
     * @type {string}
     * @memberof Operation
     */
    public arreteLibelle!: string;
    /**
     * La nature des travaux.
     *
     * @type {string}
     * @memberof Operation
     */
    public natureDesTravaux: string;

    /**
     * Le Volume CEE Classique (KWh CUMAC).
     *
     * @type {number}
     * @memberof Operation
     */
    public volumeCeeClassique: number;

    /**
     *  Le Prix CEE Classique (MWh CUMAC).
     *
     * @type {number}
     * @memberof Operation
     */
    public prixCeeClassique: number;

    /**
     * La Valorisation CEE Classique.
     *
     * @type {number}
     * @memberof Operation
     */
    public valorisationCeeClassique: number;

    /**
     *  Le Volume CEE Précarité (KWh CUMAC).
     *
     * @type {number}
     * @memberof Operation
     */
    public volumeCeePrecarite: number;

    /**
     * Le Prix CEE Précarité (MWh CUMAC).
     *
     * @type {number}
     * @memberof Operation
     */
    public prixCeePrecarite: number;

    /**
     * La Valorisation CEE Précarité.
     *
     * @type {number}
     * @memberof Operation
     */
    public valorisationCeePrecarite: number;

    /**
     * La rémunération apporteur d'affaires (€/MWhc).
     *
     * @type {number}
     * @memberof Operation
     */
    public remunerationApporteurAffaires: number;

    /**
     * La valorisation apporteur d'affaires (€).
     *
     * @type {number}
     * @memberof Operation
     */
    public valorisationApporteurAffaires: number;

    /**
     * Détermine si l'administrateur a fixé le prix de l'opération à la main. Si c'est le cas, les prix ne seront plus modifiables par
     * un profil standard.
     */
    public estPrixFixeAdministrateur: boolean;

    /**
     * Vrai si l'opértion est soumise à contrôle.
     */
    public estSoumisAControle: boolean;

    /**
     * Le Volume CEE Classique (MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public volumeCeeClassiqueEnMWhCumac: string;
    /**
     * Le Volume CEE Précarité (MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public volumeCeePrecariteEnMWhCumac: string;
    /**
     * Unité + Volume CEE Classique (MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public volumeEtUniteCeeClassique: string;
    /**
     * Unité Volume CEE Classique(MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public uniteVolumeCeeClassique: string;
    /**
     * Unite Volume CEE Précarité (MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public uniteVolumeCeePrecarite: string;

    /**
     * Unité + Volume CEE Précarité (MWh CUMAC).
     *
     * @type {string}
     * @memberof Operation
     */
    public volumeEtUniteCeePrecarite: string;

    /**
     * Id du template Operation.
     *
     * @type {number}
     * @memberof Operation
     */
    public templateOperationId: number;
    /**
     * Id du secteur.
     *
     * @type {number}
     * @memberof Operation
     */
    public secteurId: number;

    /**
     * Date acceptation politique contrôle.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateAcceptationPolitiqueControle: string = null;

    /**
     * date début travaux.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateDebutTravaux: string = '';

    /**
     * date fin travaux.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateFinTravaux: string = '';

    /**
     * date fin travaux prévisionnelle.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateFinTravauxPrevisionnelle: string = '';

    /**
     * Date de création de l'opération.
     * 
     * @type {string}
     * @memberof Operation
     */
    public dateCreation: string = '';

    /**
     * Date de modification de l'opération.
     * 
     * @type {string}
     * @memberof Operation
     */
    public dateDerniereModification: string = '';

    /**
     * Dernier modificateur de l'opération.
     * 
     * @type {string}
     * @memberof Operation
     */
    public dernierModificateur: string = '';

    /**
     * Date de finalisation de l'opération par ENGIE.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateFinalisationBO: string = '';

    /**
     * Le statut PNCEE de l'opération
     *
     * @type {StatutOperation}
     * @memberof Operation
     */
    public statutPnceeId: number;

    /**
     * Date de dépôt de l'opération.
     * 
     * @type {string}
     * @memberof Operation
     */
    public dateDepot: string = '';

    /**
     * Nom de dépôt de l'opération.
     * 
     * @type {string}
     * @memberof Operation
     */
    public nomDepot: string = '';

    /**
     * Volume Cee Précarité prévisionnel.
     *
     * @type {number}
     * @memberof Operation
     */
    public volumeCeePrecaritePrevisionnel: number = 0;

    /**
     * Volume Cee Classique prévisionnel.
     *
     * @type {number}
     * @memberof Operation
     */
    public volumeCeeClassiquePrevisionnel: number = 0;
    /**
     * Obtient ou définit La liste des Types de document pour ce TemplateOperation.
     */
    public typeDocumentTemplateOperations: Array<Document> = [];

    public sections: Array<TemplateTypeBlocReferentiel> = [];

    /**
    * Obtient ou définit le satut echantillon.
    */
    public statutEchantillon: string = "";

    /**
    * Obtient ou définit le satut politique de contrôle.
    */
    public statutPolitiqueControle: string = "";

    /**
     * date d'achèvement des travaux maximale.
     *
     * @type {string}
     * @memberof Operation
     */
    public dateAchevementFin: string = '';

    /**
     * Id de l'état de traitement de l'opération
     *
     * @type {EtatTraitementOperation}
     * @memberof Operation
     */
    public etatTraitementId: number = 1;
    /**
     * L'état de traitement de l'opération
     *
     * @type {string}
     * @memberof Operation
     */
    public etatTraitement: string;

    public selected: boolean = false;

    /**
     * Crée une instance de l'objet @type {Operation}.
     * @memberof Operation
     */
    constructor();
    constructor(code: string, natureDesTravaux: string, sources: Operation);
    constructor(code?: string, natureDesTravaux?: string, sources?: Operation) {
        Object.assign(this, sources);
        this.code = code;
        this.natureDesTravaux = natureDesTravaux;
        this.statutOperationId = StatutOperation.Partiele;
        this.statutPnceeId = StatutPnceeAsNumber.NON_DEPOSE;
        this.dateCreation = DateHelper.todayIso();
        this.dateDerniereModification = DateHelper.todayIso();
        this.dateFinalisationBO = '';
    }
}
