/**
 * Modèle de donnée d'un Template d'opération.
 */
export class TemplateOperation {
    /**
     * Identifiant technique de l'entité.
     */
    public id!: number;
    /**
     * Code unique du template d'opération.
     */
    public code!: string;
    /**
     * Code unique du template d'opération + notion coup de pouce.
     */
    public codePourAffichage!: string;
    /**
     * Libelle du template d'opération.
     */
    public libelle!: string;
    /**
     * Identifiant du secteur de l'opération.
     */
    public secteurId!: number;
    /**
     * Identifiant du TemplateOperationStandard,où sont stockés les information de base commune à l’opération.
     */
    public templateOperationStandardId!: number;
    /**
     * Permet de savoir si le template d'opération est actif.
     */
    public active!: boolean;
    /*
     * Permet de savoir si le template d'opération est visible pour les utilisateurs externes.
     */
    public estVisibleUtilisateurExterne!: boolean;
    /**
     * Permet de savoir si le template d'opération est supprimé.
     */
    public isDeleted!: boolean;

    /**
     * Date de début d'engagement du template d'opération.
     */
    public dateEngagementDebut!: string;

    /**
     * Date de fin d'engagement du template d'opération.
     */
    public dateEngagementFin!: string;

    /**
     * Date de fin d'achèvement du template d'opération.
     */
    public dateAchevementFin!: string;

    /**
     * Denomination du template d'opération.
     */
    public denomination!: string;

    /**
     * InformationMessage du template d'opération.
     */
    public informationMessage!: string;

    /**
     * Permet de savoir si le template d'opération contient la partie D.
     */
    public containsPartieD!: boolean;

    /**
     * ConditionsDelivrances du template d'opération.
     */
    public conditionsDelivrances!: string;

    /**
     * L'identifiant de la NiveauValidation du template d'opération.
     */
    public niveauValidationId!: number;

    /**
     * L'identifiant de la version d'arrêté du template d'opération.
     */
    public arreteId!: number;
    /**
     * Le code de la version de l'Arrêté.
     */
    public arreteCode!: number;
    /**
     * Le libellé de la version de l'Arrêté.
     */
    public arreteLibelle!: number;
    /**
     * Obtient ou définit le chemin relatif du template de l'attestation sur l'honneur.
     */
    public cheminRelatifTemplateAttestationHonneur!: string;

    /**
     * Obtient ou définit la date d'entrée en application du contrôle auquel est soumis le template d'opération.
     */
    public dateEntreeApplicationControle!: string;

    /**
     * Obtient ou définit la date d'entrée en application du contrôle auquel est soumis le template d'opération hors surface.
     */
    public dateEntreeApplicationControleHorsSurface!: string;

    /**
     * Obtient ou définit la surface d'isolant à partir duquel l'opération est soumise à la politique de contrôle.
     */
    public surfaceIsolantApplicationControle!: number;

    /**
    * Obtient ou définit une valeur qui permet de savoir si le template d'opération est soumis à contrôle.
   */
    public estSoumisAControle: boolean;

    /**
     * Obtient ou définit La liste des Types de document pour ce TemplateOperation.
     */
    public typeDocumentTemplateOperations: Array<{ id: number, templateOperationId: number, typeDocumentId: number }> = [];

    /**
     * Obtient ou définit La liste des Ids Types de document sélectionnés.
     */
    public selectedTypeDocumentIds: Array<number> = [];

    /**
     * Texte popup politique de controle du template d'opération.
     */
    public textePolitiqueControle!: string;
}