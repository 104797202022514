import { TypeContact } from "@/shared/enums/TypeContact.enum";
import { Utilisateur } from "./Utilisateur.model";

export class Contact {
    public id: number = 0;
    public typeContactId: number;
    public civiliteId: number = 0;
    public nom: string;
    public prenom: string;
    public fonction: string;
    public telephone: string;
    public mail: string;
    public societeId: number;

    constructor(typeContactId?: TypeContact, utilisateur?: Utilisateur) {
         this.typeContactId = typeContactId || 0;
         this.civiliteId = utilisateur ? utilisateur.civiliteId : 0;
         this.nom = utilisateur ? utilisateur.lastName : '';
         this.prenom = utilisateur ? utilisateur.firstName : '';
         this.fonction = utilisateur ? utilisateur.fonction : '';
         this.telephone = utilisateur ? utilisateur.phoneNumber : '';
         this.mail = utilisateur ? utilisateur.email : '';
         this.societeId =  0;
    }
}
