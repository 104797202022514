import { RootState } from '@/store/state';
import { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import { SimulationDossierCriteria } from '@/components/TableauDeBord/models/SimulationDossierCriteria.model';
import { SimulationDossier, TableauDeBordUserBase, Dossier } from '@/models';
import SecureLS from "secure-ls";
import { ApiService } from '../../../services/base/ApiService';
const ls = new SecureLS({ isCompression: true });

/**
 * States Tableau de bord.
 *
 * @export
 * @class TableauDeBordState
 */
export class TableauDeBordState {

    /**
     * Critères.
     *
     * @type {SimulationDossierCriteria}
     * @memberof TableauDeBordState
     */
    public criteres: SimulationDossierCriteria = new SimulationDossierCriteria();

    /**
     * La liste des simulations à afficher en fonction des critères de recherche.
     *
     * @type {SimulationDossier[]}
     * @memberof TableauDeBordState
     */
    public simulationsDossiers: SimulationDossier[] = new Array<SimulationDossier>();

    /**
     * La liste des derniers dossiers consultés
     */
    public lastConsultedDossiers: TableauDeBordUserBase[] = new Array<TableauDeBordUserBase>();
}
/**
 * Constantes pour les méthodes du store.
 */
export enum TableauDeBordStoreMethods {
    ADD_CONSULTED_DOSSIER = 'ADD_CONSULTED_DOSSIER',
    GET_CONSULTED_DOSSIERS = 'GET_CONSULTED_DOSSIERS',
    UPDATE_CONSULTED_DOSSIERS = 'UPDATE_CONSULTED_DOSSIERS',
    RESET_ALL = 'RESET_ALL',
    ADD_LAST_FILTERS = 'ADD_LAST_FILTERS',
    GET_LAST_FILTERS = 'GET_LAST_FILTERS',
}
/**
 * Getters du store.
 */
const getters: GetterTree<TableauDeBordState, RootState> = {
    [TableauDeBordStoreMethods.GET_CONSULTED_DOSSIERS]: (state) => {
        // let localStorageConsultedDossiers = JSON.parse(localStorage.getItem('lastConsultedDossiers'));        
        let localStorageConsultedDossiers = ls.get('lastConsultedDossiers');
        if (localStorageConsultedDossiers) {
            state.lastConsultedDossiers = localStorageConsultedDossiers;
        }
        return state.lastConsultedDossiers;
    },
    [TableauDeBordStoreMethods.GET_LAST_FILTERS]: (state) => {       
        let lastFilters = localStorage.getItem('lastFilters');
        let criteria = JSON.parse(lastFilters);        
        if (criteria) {
            Object.assign(state.criteres, criteria);
        }
        return state.criteres;
    },
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<TableauDeBordState> = {
    [TableauDeBordStoreMethods.ADD_CONSULTED_DOSSIER]: (state: TableauDeBordState, lastConsultedDossier: TableauDeBordUserBase) => {
        if (lastConsultedDossier) {
            if (!state.lastConsultedDossiers.find(x => x.id === lastConsultedDossier.id)) {
                lastConsultedDossier.isRecentlyConsulted = true;
                state.lastConsultedDossiers.unshift(lastConsultedDossier);
                if (state.lastConsultedDossiers.length === 4) {
                    state.lastConsultedDossiers.pop();
                }
                ls.set("lastConsultedDossiers", state.lastConsultedDossiers);
            }
        }
    },
    [TableauDeBordStoreMethods.UPDATE_CONSULTED_DOSSIERS]: (state: TableauDeBordState, updatedDossier: Dossier) => {
        let dossierToUpdateIndex = state.lastConsultedDossiers.findIndex(x => x.id === updatedDossier.id);
        const tableauDeBordService = new ApiService<TableauDeBordUserBase>('/tableauDeBord/recupererSimulationDossierTableauDeBordById');
        tableauDeBordService.get(updatedDossier.id).then((reponse) => {
            state.lastConsultedDossiers[dossierToUpdateIndex] = reponse.data.data;
            state.lastConsultedDossiers[dossierToUpdateIndex].isRecentlyConsulted = true;
            ls.set("lastConsultedDossiers", state.lastConsultedDossiers);
        });
    },
    [TableauDeBordStoreMethods.ADD_LAST_FILTERS]: (state: TableauDeBordState, lastFilters: SimulationDossierCriteria) => {        
        state.criteres = lastFilters;
        localStorage.setItem("lastFilters", JSON.stringify(state.criteres));
    }
};

/**
 * Actions du store.
 */
const actions: ActionTree<TableauDeBordState, RootState> = {  
};

/**
 * Export du module
 */
export const TableauDeBordStore: Module<TableauDeBordState, RootState> = {
    state: new TableauDeBordState(),
    getters,
    mutations,
    actions,
    namespaced: false
};