import { Ville, TypeVoie } from '@/models';
export class Adresse {
    public id: number = 0;
    public numeroVoie: string = '';
    public typeVoie: TypeVoie;
    public typeVoieId: number = 0;
    public nomVoie: string = '';
    public complementVoie: string = '';
    public ville: Ville = new Ville();
    public villeId: number = null;
    public estSaisieLibre: boolean = false;
    public codePostalLibre: string = '';
    public villeLibre: string = '';
}
export class AdresseGouv {
    public label: string = '';
    public score: number = 0;
    public housenumber: string = '';
    public id: string = '';
    public name: string = '';
    public postcode: string = '';
    public citycode: string = '';
    public x: number = 0;
    public y: number = 0;
    public city: string = '';
    public context: string = '';
    public type: string = '';
    public importance: number = 0;
    public street: string = '';
}