import { CriteriaBaseAvecFiltresRapides } from '@/shared/models';
import { Utilisateur } from '../../../models';

/**
 * Critères de recherche avancés pour filtrer les simulations.
 * */
export class SimulationDossierCriteria extends CriteriaBaseAvecFiltresRapides {

    // Numéro de dossier.
    public numeroDossier: string;

    // Raison sociale bénéficiaire ou installateur ou apporteur d'affaires.
    public raisonSociale: string;

    // Nom du gestionnaire Engie.
    public nomGestionnaireEngie: string;

    // Nom du gestionnaire Engie.
    public gestionnaireEngie: Utilisateur;

    // Recherche par AC.
    public isDossiersAccordCadres: boolean;

    // Id du gestionnaire Engie.
    public idGestionnaire: number;

    // Identifiant du type de compte.
    public typeCompteId: number;

    // Mail de l'utilisateur.
    public mailUtilisateur: string;

    // Statuts de simulation/dossier choisis.
    public statutIds: number[];

    // Site des travaux.
    public siteTravaux: string;

    // Nom du dossier.
    public nomDossier: string;

    // Dossier ou Simulation.
    public parcoursUtilisateurId: number = 0;

    // Droit coup de pouce
    public droitCoupDePouce: boolean;

    // Accord Cadre Ids.
    public accordCadreIds: number[];

    /**
     * Id Créateur d'une simulation/dossier choisie.
     *
     * @type {Utilisateur}
     * @memberof AccordCadreCriteria
     */
    public createurId: number = 0;

    /**
     * Société membre choisie.
     *
     * @type {Societe}
     * @memberof AccordCadreCriteria
     */
    public membreId: number = 0;

    // Saisie de la recherche rapide.
    public rechercheRapide: string[];

    public sortByDateModification: boolean;

    public filtresRapides = {

        // Si la simulation est en cours.
        estEnSimulation: false,

        // Si la simulation est en attente de valorisation par Engie.
        estEnAttenteValorisation: false,

        // Si la simulation est en attente de la convention.
        estEnAttenteConvention: false,

        // Si la simulation est en création.
        estEnCreation: false,

        // Si la simulation est à recevoir.
        estARecevoir: false,

        // Si la simulation est envoyée à Engie.
        estEnvoyeEngie: false,

        // Si la simulation est en attente de traitement par Engie.
        estEnTraitementEngie: false,

        // Si la simulation est finalisée par Engie.
        estFinaliseEngie: false,

        // Si la simulation est mise en paiement par Engie.
        estMisEnPaiement: false
    }

    public get isFiltreSelectionne(): boolean {
        if (this.numeroDossier || this.nomGestionnaireEngie || this.gestionnaireEngie || this.idGestionnaire ||
            this.typeCompteId || this.mailUtilisateur || (this.statutIds && this.statutIds.length > 0) ||
            this.nomDossier || this.parcoursUtilisateurId > 0 || this.accordCadreIds || (this.rechercheRapide && this.rechercheRapide.length > 0) || this.sortByDateModification) {
            return true;
        }
        else
            return false;
    }
}