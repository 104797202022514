



















import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import LiensFlottants from '@/views/LiensFlottants.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';

@Component({
    name: `Layout`,
    components: { Header, Footer, LiensFlottants }
})
export default class extends Vue {

    // Promesse indiquant si l'utilisateur est connecté.
    @Getter(AuthStoreMethods.IS_CURRENT_USER_LOGGED_IN)
    public isUserLoggedInPromise: Promise<boolean>;

    // Indique si l'utilisateur est connecté.
    public isUserLoggedIn: boolean = false;

    public mounted() {
        // Check que l'utilisateur est connecté. Si oui on affiche le tableau de bord.
        this.isUserLoggedInPromise.then((reponse: boolean) => {
            this.isUserLoggedIn = reponse;
        });
    }
}
