/**
 * Énumération des arrêtes.
 */
export enum Arretes {

    ARR_0     =     0,
    ARR_1     =     1,
    ARR_2     =     2,
    ARR_3     =     3,
    ARR_4     =     4,
    ARR_5     =     5,
    ARR_6     =     6,
    ARR_7     =     7,
    ARR_8     =     8,
    ARR_9     =     9,
    ARR_10     =     10,
    ARR_11     =     11,
    ARR_12     =     12,
    ARR_13     =     13,
    ARR_14     =     14,
    ARR_15     =     15,
    ARR_16     =     16,
    ARR_17     =     17,
    ARR_18     =     18,
    ARR_19     =     19,
    ARR_20     =     20,
    ARR_21     =     21,
    ARR_22     =     22,
    ARR_23     =     23,
    ARR_24     =     24,
    ARR_25     =     25,
    ARR_26     =     26,
    ARR_27     =     27,
    ARR_28     =     28,
    ARR_29     =     29,
    ARR_30     =     30,
    ARR_31     =     31,
    ARR_32     =     32,
    ARR_33     =     33,
    ARR_34     =     34,
    ARR_35     =     35,
    ARR_CP     =     36,
    ARR_36     =     38,
}