import { PagingAndSorting } from './PagingAndSorting.model';

/**
 * Modèle pour la pagination et le tri utilisé par la datatable de Vuetify.
 */
export class Pagination {
    /*
     * Tri descendant.
     */
    public descending: boolean;

    /**
     * Index de la page courante.
     */
    public page: number = 1;

    /**
     * Nombre d'éléments par page.
     */
    public itemsPerPage: number = 10;

    /**
     * Nom de la colonne de tri.
     */
    public sortBy: string[] = [];

    /**
     * Sens de tri des propriétés.
     */
    public sortDesc: boolean[] = [];

    /**
     * Propriétés à grouper.
     */
    public groupBy: string[] = [];

    /**
    * Sens de tri des propriétés à grouper.
    */
    public groupDesc: boolean[] = [];

    public multiSort: boolean = false;

    public mustSort: boolean = false;

    /**
     * Nombre total d'éléments.
     */
    public totalItems: number;

    /**
     * Nombre de rangées par page.
     * https://stackoverflow.com/questions/57193927/vuetify-table-page-is-not-working-and-reset-back-to-1
     */
    public itemsPerPageOptions: number[] = ([1, 2, 3, 4, 5]).map((item) => item * this.itemsPerPage);

    /**
     * Nombre de Lignes par page.
     */
    public itemsPerPageText: string = "Lignes par page :";

    /**
     * Transforme un objet Pagination en un objet PagingAndSorting.
     */
    public toPagingAndSorting(): PagingAndSorting {
        const pagingAndSorting = new PagingAndSorting();

        pagingAndSorting.pageIndex = this.page - 1;
        pagingAndSorting.pageSize = this.itemsPerPage;
        pagingAndSorting.sortExpression = this.sortBy && this.sortBy.length > 0 ? this.sortBy[0] + (this.sortDesc[0] ? ' desc' : ' asc') : '';

        return pagingAndSorting;
    }
}
