/**
 * Classe de gestion des contrats apporteurs d'affaires.
 *
 * @export
 * @class ContratApporteursAffaires
 */
export class ContratApporteursAffaires {
    /**
     * Identifiant contrat apporteur d'affaires.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public id: number = 0;
    /**
     * Date de début contrat apporteur d'affaires.
     *
     * @type {string}
     * @memberof ContratApporteursAffaires
     */
    public dateDebut: string = '';
    /**
     * Date de fin contrat apporteur d'affaires.
     *
     * @type {string}
     * @memberof ContratApporteursAffaires
     */
    public dateFin: string = '';
    /**
     * Intitulé du contrat apporteur d'affaires.
     *
     * @type {string}
     * @memberof ContratApporteursAffaires
     */
    public nom: string = '';
    /**
     * Contrat apporteur d'affaires a un Prix fixe.
     *
     * @type {boolean}
     * @memberof ContratApporteursAffaires
     */
    public prixFixeAuContrat: boolean = true;
    /**
     * Contrat apporteur d'affaires a un flux financier?
     *
     * @type {boolean}
     * @memberof ContratApporteursAffaires
     */
    public fluxFinancier: boolean = false;
    /**
     * Volume classique du contrat apporteur d'affaires.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public volumeClassique: number = 0;
    /**
     * Volume précarité du contrat apporteur d'affaires.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public volumePrecarite: number = 0;
    /**
     * Part bénéficiaire classique du contrat apporteur d'affaires.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public partBeneficiaireClassique: number = 0;
    /**
     * Part bénéficiaire précarité du contrat apporteur d'affaires.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public partBeneficiairePrecarite: number = 0;
    /**
     * Part Utilisateur de type apporteur d'affaires du contrat.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public partApporteurAffaires: number = 0;
    /**
     * Identifiant de l'utilisateur de type apporteur d'affaires attaché au contrat.
     *
     * @type {number}
     * @memberof ContratApporteursAffaires
     */
    public utilisateurId: number = 0;

    public utilisateur: {id: number, firstName: string, lastName: string} = {id: 0, firstName: '', lastName: ''};
}

