

















import Vue from 'vue';
import Layout from '@/components/Layout.vue';
import VEmpty from '@/components/VEmpty.vue';
import CeeDrawer from '@/components/CeeDrawer.vue';
import { adminNavRoutes } from '@/router/routes';
export default Vue.extend({
    name: `CeeLayoutDefaultAdmin`,
    components: { Layout, VEmpty, CeeDrawer },
    data() {
        return {
            adminNavRoutes,
        };
    },
});
