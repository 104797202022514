/**
 * Énumération des permissions existantes.
 */
export enum Permissions {
    Admin_AccordCadre = 'Admin_AccordCadre',
    Admin_ChampsOperations = 'Admin_ChampsOperations',
    
    /** Consultation des pages d'administration. */
    Admin_Consultation = 'Admin_Consultation',
    Admin_ContratApporteurAffaire = 'Admin_ContratApporteurAffaire',
    Admin_DashBoard = 'Admin_DashBoard',
    Admin_DemandesUtilisateursOperations = 'Admin_DemandesUtilisateursOperations',
    Admin_Menu = 'Admin_Menu',
    Admin_SeuilMinimumOperations = 'Admin_SeuilMinimumOperations',
    Admin_TemplateOperations = 'Admin_TemplateOperations',
    Admin_Users = 'Admin_Users',
}
