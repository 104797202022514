/**
 * Simulation Ou Dossier.
 *
 * @export
 * @enum {number}
 */
export enum SimulationOuDossier {
    Simulation = 1,
    Dossier = 2
}
