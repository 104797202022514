export interface IAppState {
    locale: string | null;
    config: any;
    defaultMessages: any;
    redirectTo: string | null;
    cookieConsentVersion: string;
    baseUrl: string;
    baseApiUrl: string;
}

export const AppDefaultState = (): IAppState => {
    return {
        locale: null,
        config: null,
        defaultMessages: {},
        redirectTo: null,
        cookieConsentVersion: '',
        baseUrl: '',
        baseApiUrl: '',
    };
};
