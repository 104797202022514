import { Contact } from './Contact.model';
import { Adresse } from './Adresse.model';
import { AccordCadre } from './AccordCadre.model';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { Utilisateur } from '.';

export class Societe {
    public id: number;
    public raisonSociale: string;
    public capital: string;
    public formeJuridiqueId: number;
    public siret!: string;
    public lieuImmatriculation: string;
    public adresseId: number;
    public adresse: Adresse = new Adresse();
    public contacts: Contact[] = new Array<Contact>();
    public signataire: Contact = new Contact(TypeContact.Signataire);
    public interlocuteur: Contact = new Contact(TypeContact.Interlocuteur);
    public sousTraitant: Contact;
    public gerant: Contact = new Contact(TypeContact.Gerant);
    public estUtilisateurSignataire: boolean = true;
    public estUtilisateurInterlocuteur: boolean = true;
    public estSousTraitant: boolean = true;
    public accordCadres: AccordCadre[] = new Array<AccordCadre>();
    public utilisateurId: number = 0;
    public utilisateur: Utilisateur;
    public estSansSiret: boolean;
    public societeSousTraitante: Societe;

    constructor() {
        this.id = 0;
        this.raisonSociale = '';
        this.capital = '';
        this.siret = '';
        this.formeJuridiqueId = 0;
        this.lieuImmatriculation  = '';
        this.adresse = new Adresse();
        this.adresseId = null;
        this.contacts = new Array<Contact>();
        this.estUtilisateurSignataire = false;
        this.estSansSiret = false;
    }
}
