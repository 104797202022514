import { DirectiveOptions, VNode } from 'vue';
import {Vue} from 'vue-property-decorator';
import { DirectiveBinding } from 'vue/types/options';
import { GrantEditHelper, disableFormInputs, enableFormInputs, isParamsProvided } from '@/shared/helpers/GrantEditHelper';
import { SimulationOuDossier } from '../enums/SimulationOuDossier.enum';
import {isEqual} from 'lodash-es';

let resultParamsValid = false;
let workFLowValid = false;

const editGrantDirective: DirectiveOptions = {

    /**
     * Bind lors de l'application de la directive.
     *
     * @param {HTMLElement} el
     * @param {DirectiveBinding} binding
     * @param {VNode} vNode
     */
    async bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        resultParamsValid = isParamsProvided(binding);
        if (resultParamsValid) {
            GrantEditHelper.setDataProfil(vNode);
            GrantEditHelper.initialize();
        } else {
            // tslint:disable-next-line:no-console
            console.error('[Error] : one or many parameters are not provided');
            return;
        }
    },

    /**
     * Mise à jour des paramètres bindings.
     *
     * @param {HTMLElement} el
     * @param {DirectiveBinding} binding
     * @param {VNode} vNode
     * @param {VNode} vOldNode
     */
    update(el: HTMLElement, binding: DirectiveBinding, vNode: VNode, vOldNode: VNode) {
        workFLowValid = !!GrantEditHelper.getWorkflow() && GrantEditHelper.getWorkflow().length > 0;
        if ((!isEqual(binding.value, binding.oldValue) || binding.value.statut === 0) && workFLowValid) {
            if (!!(vNode.context.$props.model || {}).simulationDossierId &&
            !GrantEditHelper.hasPermissionToEdit({
                value: binding.value.estDossier ? SimulationOuDossier.Dossier : SimulationOuDossier.Simulation,
                statut: binding.value.statut,
            })) {
                disableFormInputs(el, vNode);
            } else {
                enableFormInputs(el, vNode);
            }
        }
    }
};

Vue.directive('edit-grant', editGrantDirective);
