import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { RootState } from '../../state';
import { ApiService } from '@/services/base/ApiService';
import { TypeDeWizardComposant } from '../../../components/Wizard/Composants';


/**
 * Classe de gestion de la référence sur l'ensemble des étapes.
 */
export class InformationsSimulationDossierState {

    /**
     * Simulation ou dossier.
     */
    public discriminator: string = '';

    /**
     * Numéro (identifiant) de simulation/dossier, non-modifiable par l'utilisateur.
     */
    public numeroSimulationDossier: string = '';

    /**
     * référence simulation/dossier.
     */
    public referenceSimulationDossier: string = '';

    /**
     * Libellé du statut de la simulation/du dossier.
     */
    public statutSimulationDossier: string = '';
    /**
     * Mode d'édition.
     */
    public isEditingMode: boolean = false;

    public notes: any[] = []; /*Array<{
        idCreateur: number,
        createur: string,
        date: string
        message: string,
    }> = [];*/

    /**
     * Champs non renseignes.
     */
    public champsNonRenseignes: Map<string, string[]> = new Map<string, string[]>();

    /**
     * Champs non renseignes.
     */
    public loadingEtapes: string[] = [];

}

/**
 * Constantes pour les méthodes du store.
 */
export enum InformationsSimulationDossierStoreMethods {
    DISCRIMINATOR = 'DISCRIMINATOR',
    NUMERO = 'NUMERO',
    NOTES = 'NOTES',
    REFERENCE = 'REFERENCE',
    STATUT = 'STATUT',
    CHAMPS_NON_RENSEIGNES = 'CHAMPS_NON_RENSEIGNES',
    SET_DISCRIMINATOR = 'SET_DISCRIMINATOR',
    SET_NUMERO = 'SET_NUMERO',
    SET_REFERENCE = 'SET_REFERENCE',
    SET_STATUT = 'SET_STATUT',
    SET_NOTES = 'SET_NOTES',
    RESET_CHAMPS_NON_RENSEIGNES = 'RESET_CHAMPS_NON_RENSEIGNES',
    ADD_NOTE = 'ADD_NOTE',
    ADD_CHAMPS_NON_RENSEIGNES = 'ADD_CHAMPS_NON_RENSEIGNES',
    REMOVE_CHAMPS_NON_RENSEIGNES = 'REMOVE_CHAMPS_NON_RENSEIGNES',
    LOADING_ETAPES = 'LOADING_ETAPES',
    SET_LOADING_ETAPE = 'SET_LOADING_ETAPE',
    UNSET_LOADING_ETAPE = 'UNSET_LOADING_ETAPE',
    MODIFIER_REFERENCE = 'MODIFIER_REFERENCE',
    RECUPERER_INFORMATIONS = 'RECUPERER_INFORMATIONS',
    RESET = 'RESET',
    EDITING_MODE = 'EDITING_MODE',
    TOGGLE_EDITING_MODE = 'TOGGLE_EDITING_MODE',
}

/**
 * Getters du store.
 */
const getters: GetterTree<InformationsSimulationDossierState, RootState> = {
    [InformationsSimulationDossierStoreMethods.DISCRIMINATOR]: (state) => state.discriminator,
    [InformationsSimulationDossierStoreMethods.NUMERO]: (state) => state.numeroSimulationDossier,
    [InformationsSimulationDossierStoreMethods.REFERENCE]: (state) => state.referenceSimulationDossier,
    [InformationsSimulationDossierStoreMethods.STATUT]: (state) => state.statutSimulationDossier,
    [InformationsSimulationDossierStoreMethods.EDITING_MODE]: (state) => state.isEditingMode,
    [InformationsSimulationDossierStoreMethods.NOTES]: (state) => state.notes,
    [InformationsSimulationDossierStoreMethods.CHAMPS_NON_RENSEIGNES]: (state) => state.champsNonRenseignes,
    [InformationsSimulationDossierStoreMethods.LOADING_ETAPES]: (state) => state.loadingEtapes
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<InformationsSimulationDossierState> = {
    [InformationsSimulationDossierStoreMethods.SET_DISCRIMINATOR]
        : (state: InformationsSimulationDossierState, discriminator: string) => state.discriminator = discriminator,
    [InformationsSimulationDossierStoreMethods.SET_NUMERO]
        : (state: InformationsSimulationDossierState, numeroSimulationDossier: string) => state.numeroSimulationDossier = numeroSimulationDossier,
    [InformationsSimulationDossierStoreMethods.SET_REFERENCE]
        : (state: InformationsSimulationDossierState, referenceSimulationDossier: string) => state.referenceSimulationDossier = referenceSimulationDossier,
    [InformationsSimulationDossierStoreMethods.SET_STATUT]
        : (state: InformationsSimulationDossierState, statutSimulationDossier: string) => state.statutSimulationDossier = statutSimulationDossier,
    [InformationsSimulationDossierStoreMethods.SET_NOTES]
        : (state: InformationsSimulationDossierState, notes: Array<{
            idCreateur: number,
            createur: string,
            date: string
            message: string,
        }>) => state.notes = notes,
        [InformationsSimulationDossierStoreMethods.RESET_CHAMPS_NON_RENSEIGNES]
        : (state: InformationsSimulationDossierState) => state.champsNonRenseignes = new Map<string, string[]>(),
    [InformationsSimulationDossierStoreMethods.RESET]
        : (state: InformationsSimulationDossierState) => {
            state.referenceSimulationDossier = '';
            state.isEditingMode = false;
            state.discriminator = '';
    },
    [InformationsSimulationDossierStoreMethods.TOGGLE_EDITING_MODE]
        : (state: InformationsSimulationDossierState) => state.isEditingMode = !state.isEditingMode,
    [InformationsSimulationDossierStoreMethods.ADD_NOTE]
        : (state: InformationsSimulationDossierState, note: any) => {
            const index = state.notes.findIndex((item: any) => item.idCreateur === note.idCreateur);
            if (!~index) {
                state.notes.push(note);
            } else {
                state.notes[index] = note;
            }
        },
    [InformationsSimulationDossierStoreMethods.ADD_CHAMPS_NON_RENSEIGNES]
        : (state: InformationsSimulationDossierState, champsNonRenseignes: any) =>
            state.champsNonRenseignes.set(champsNonRenseignes.etape, champsNonRenseignes.champs),
    [InformationsSimulationDossierStoreMethods.REMOVE_CHAMPS_NON_RENSEIGNES]
    : (state: InformationsSimulationDossierState, etape: TypeDeWizardComposant) =>
        state.champsNonRenseignes.set(etape, []),
    [InformationsSimulationDossierStoreMethods.SET_LOADING_ETAPE]
    : (state: InformationsSimulationDossierState, loadingEtape: string) => {
        const index = state.loadingEtapes.indexOf(loadingEtape);
        if (index === -1) {
            state.loadingEtapes.push(loadingEtape);
        }
    },
    [InformationsSimulationDossierStoreMethods.UNSET_LOADING_ETAPE]
    : (state: InformationsSimulationDossierState, loadingEtape: string) => {
        const index = state.loadingEtapes.indexOf(loadingEtape);
        if (index !== -1) {
            state.loadingEtapes.splice(index);
        }
    }
};

/**
 * Actions du store.
 */
const actions: ActionTree<InformationsSimulationDossierState, RootState> = {
    [InformationsSimulationDossierStoreMethods.MODIFIER_REFERENCE]: (store: ActionContext<InformationsSimulationDossierState, RootState>, simulationDossierId: number)
        : Promise<{id: number, intituleDossier: string}> => {
            const simulationService = new ApiService<{id: number, intituleDossier: string, notes: string}>('simulationDossier/modifierIntituleDossier');
            return new Promise<{id: number, intituleDossier: string}>((resolve, reject) => {
                return simulationService.post({ id: simulationDossierId, intituleDossier: store.state.referenceSimulationDossier,
                    notes: JSON.stringify(store.state.notes)}).then((response) => {
                    store.commit(InformationsSimulationDossierStoreMethods.SET_REFERENCE, response.data.data.intituleDossier);
                    resolve(response.data.data);
                })
                    .catch((error: { response: any; }) => {
                        reject(error.response);
                    });
            });
    },
    [InformationsSimulationDossierStoreMethods.RECUPERER_INFORMATIONS]: (store: ActionContext<InformationsSimulationDossierState, RootState>, simulationDossierId: number)
        : Promise<{id: number, intituleDossier: string}> => {
        const simulationService =
        new ApiService<{ id: number, intituleDossier: string, numeroDossier: string, statutSimulationDossier: string, notes: string, discriminator: string}>('simulationDossier/recupererInformationsDossier');
        return new Promise<{id: number, intituleDossier: string}>((resolve, reject) => {
                return simulationService.get(simulationDossierId).then((response) => {
                    store.commit(InformationsSimulationDossierStoreMethods.SET_DISCRIMINATOR, response.data.data.discriminator);
                    store.commit(InformationsSimulationDossierStoreMethods.SET_NUMERO, response.data.data.numeroDossier);
                    store.commit(InformationsSimulationDossierStoreMethods.SET_REFERENCE, response.data.data.intituleDossier);
                    store.commit(InformationsSimulationDossierStoreMethods.SET_STATUT, response.data.data.statutSimulationDossier);
                    store.commit(InformationsSimulationDossierStoreMethods.SET_NOTES, !!response.data.data.notes ? JSON.parse(response.data.data.notes) : []);
                    resolve(response.data.data);
                })
                    .catch((error: { response: any; }) => {
                        reject(error.response);
                    });
            });
    },
};

/**
 * Export du module
 */
export const informationsSimulationDossierStore: Module<InformationsSimulationDossierState, RootState> = {
    state: new InformationsSimulationDossierState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
