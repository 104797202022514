/**
 * Énumération des types de profils existants.
 */
export enum Profils {
    None = '',
    AdministrateurCEE = 'AdministrateurCEE',
    GestionnaireBO = 'GestionnaireBO',
    AdministrateurInformatique = 'AdministrateurInformatique',
    Entreprise = 'Entreprise',
    Installateur = 'Installateur',
    SyndicSDC = 'SyndicSDC',
    ApporteurAffaires = 'ApporteurAffaires',
    Finance = "Finance"
}

export enum OrigineDossier {
        ApporteurAffairesSDC = 'WEBCEE - APPORTEUR D\'AFFAIRES - BENEFICIAIRE - SDC',
        ApporteurAffairesNonSDC = 'WEBCEE - APPORTEUR D\'AFFAIRES - BENEFICIAIRE',
        ApporteurAffairesAvecClientInstallateur = 'WEBCEE - APPORTEUR D\'AFFAIRES - INSTALLATEUR',
        Entreprise = 'WEBCEE - BÉNÉFICIAIRE DES TRAVAUX',
        SyndicSDC = 'WEBCEE - BÉNÉFICIAIRE DES TRAVAUX SDC',
        Installateur = 'WEBCEE - INSTALLATEUR PARTENAIRE',
}

export enum ProfilsInternes {
    AdministrateurCEE = 'AdministrateurCEE',
    GestionnaireBO = 'GestionnaireBO',
    AdministrateurInformatique = 'AdministrateurInformatique',
}


export enum EnumProfilsAsNumber {
    None = 0,
    AdministrateurCEE = 1,
    GestionnaireBO = 2,
    AdministrateurInformatique = 3,
    Entreprise = 4,
    Installateur = 5,
    SyndicSDC = 6,
    ApporteurAffaires = 7,
    Finance = 8
}
