export default class AccordsCadresGeneral {

    /**
     * Id de l'accord-cadre
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public id: number;
    /**
     * Intitulé de l'accord-cadre
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public nom: string;
    /**
     * Date début du contrat
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public dateDebut: string;
    /**
     * Date fin du contrat
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public dateFin: string;
    /**
     * Valorisation classique
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public valorisationClassique: number;
    /**
     * Valorisation précatité
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public valorisationPrecarite: number;
    /**
     * classVolume classique maximum
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public volumeMaxClassique: number;
    /**
     * Volume précarité maximum
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public volumeMaxPrecarite: number;
    /**
     * Contrat en format PDF stocké en Base64
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public fichierContrat: string;
    /**
     * Id du responsable accord-cadre côté Engie
     *
     * @type {number}
     * @memberof AccordsCadresGeneral
     */
    public responsableId?: number;
    /**
     * Nom du responsable accord-cadre côté Engie.
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public nomResponsable: string;
    /**
     * Prénom du responsable accord-cadre côté Engie.
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public prenomResponsable: string;
    /**
     * Membre AC avec le role responsable.
     *
     * @type {string}
     * @memberof AccordsCadresGeneral
     */
    public nomCompletMembreAC: string = '';

    /**
     * Creates an instance of AccordsCadresGeneral.
     * @param {number} id
     * @param {string} nom
     * @param {string} dateDebut
     * @param {string} dateFin
     * @param {number} valorisationClassique
     * @param {number} valorisationPrecarite
     * @param {number} volumeMaxClassique
     * @param {number} volumeMaxPrecarite
     * @param {string} contrat
     * @param {number} responsableId
     * @memberof AccordsCadresGeneral
     */
    constructor();
    constructor(id: number, nom: string, dateDebut: string, dateFin: string, valorisationClassique: number,
        valorisationPrecarite: number, volumeMaxClassique: number, volumeMaxPrecarite: number, contrat: string, responsableId: number
    );
    constructor(id?: number, nom?: string, dateDebut?: string, dateFin?: string, valorisationClassique?: number,
        valorisationPrecarite?: number, volumeMaxClassique?: number, volumeMaxPrecarite?: number, contrat?: string, responsable?: number) {
        this.id = id || 0;
        this.nom = nom || '';
        this.dateDebut = dateDebut || '';
        this.dateFin = dateFin || '';
        this.valorisationClassique = valorisationClassique || 0;
        this.valorisationPrecarite = valorisationPrecarite || 0;
        this.volumeMaxClassique = volumeMaxClassique || 0;
        this.volumeMaxPrecarite = volumeMaxPrecarite || 0;
        this.fichierContrat = contrat || null;
        this.responsableId = responsable;
    }
}
