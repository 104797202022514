import axios from 'axios';
import Vue from 'vue';
import { SecurityPlugin } from '@/packages/security/securityPlugin';
import { createApp } from './App';
import '@/shared/filters';
import '@/shared/directives';

const env = process.env.NODE_ENV;
const isDev = (env === "dev" || env === "development");
const logger = console;

Vue.config.productionTip = isDev;
Vue.config.devtools = isDev;
Vue.config.performance = isDev;


axios.get('home/sensitivesettings/').then((result: any) => {
    try {
        const { data } = result;
        const { app: appSettings, ...settings } = data;
        const { baseApiUrl } = appSettings;

        if (appSettings && settings && settings.settings) {
            const rootState = {
                app: appSettings
            };
            axios.defaults.baseURL = baseApiUrl;

            Vue.use(SecurityPlugin, { ...settings });

            const { app, router } = createApp(rootState);
            router.onReady(() => app.$mount('#app'));


        } else {
            throw new Error('Erreur critique, l\'application ne peut pas démarrer, veuillez vérifier que vous êtes bien connecté à Internet.');
        }

    } catch (err) {
        logger.log('err', err);
    }

}).catch((error: any) => {
    logger.log('Error lors de la récupération des settings', error);
});

