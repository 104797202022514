import { AccordCadre, Utilisateur, Negociateur, Paiement } from '.';
import { SimulationDossier } from './SimulationDossier.model';
export class Dossier extends SimulationDossier {
    public accordCadre?: AccordCadre = null;
    public accordCadreId: number = null;
    public commentaireSuiviDossier: string = '';
    public datePriseEnChargeParEngie: string = '';
    public dateEnvoiDossierAEngie: string = '';
    public gestionFluxFinancier: boolean = true;
    public estDossierEnPrefinancement: boolean = false;
    public gestionnaire?: Utilisateur = null;
    public idGestionnaire?: number = 0;
    public isDossierPaye: boolean = false;
    public originaleAHRecue: number = 0;
    public originaleConventionRecue: number = 0;
    public messageAvertissement: string = '';
    public negociateur?: Negociateur = new Negociateur();
    public negociateurId: number = null;
    public numeroOffreService: string = '';
    public paiements: Paiement[] = new Array<Paiement>();
    public typeCeeId: number;
    // Numéro de dossier.
    public numeroDossier: string = '';
    public apporteurAffairesId: number = null;
    public isSiteTravauxChoisi: boolean = false;
    public isSocieteBeneficiaireChoisi: boolean = false;
    public isSocieteInstallateurChoisi: boolean = false;

    public montantRetroceder: number = 0;

    public nomSyndic: string = '';
    /**
     * Obtient la date de téléchargement de l'attestation sur l'honneur.
     */
    public dateTelechargementAH: string = null;
    /**
     * Obtient une valeur indiquant si cette instance possède un cadre de contribution.
     */
    public hasCadreContribution: boolean = false;

    public typeInstallateurId: number = null;

    public typeBeneficiaireId: number = null;

    public nbBailleursSociaux: number = 1;

    public estEnvoiAHConfirme: boolean = false;

    public dateEnvoiAHConfirme: string = '';

    // IsDossierBeneficiaire.
    public isDossierBeneficiaire: boolean = false;
    // IsDossierEntreprise.
    public isDossierEntreprise: boolean = false;
    // IsDossierInstallateur.
    public isDossierInstallateur: boolean = false;
    // IsDossierSyndicSDC.
    public isDossierSyndicSDC: boolean = false;

    // IsDossierApporteurAffaires.
    public isDossierApporteurAffaires: boolean = false;
    ///
    /// Obtient une valeur indiquant si on peut ajouter une opération à ce dossier.
    /// <value><c>true</c> si sur cette instance, on peut ajouter une opération à ce dossier; sinon, <c>false</c>.</value>
    public canAddOperation: boolean = true;

    /// Obtient ou définit les notes sur un dossier.
    public notes: string = '';

    public intituleDossier: string = '';

    /**
     * Sous canal.
     *
     * @type {string}
     * @memberof Dossier
     */
    public sousCanal: string = '';

    /**
     * Canal collecte.
     *
     * @type {string}
     * @memberof Dossier
     */
    public canalCollecte: string = '';

    /*
     * Avertissement de paiements.
     */
    public avertissementPaiements: [string, string] = ['', ''];
}
