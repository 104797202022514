
/**
 * Classe d'helper pour les booléens.
 * */
export class BooleanHelper {

    /**
     * Convertie un booléen en un libellé Oui ou Non
     * @param value Valeur du booléen.
     */
    public static toStringOuiNon(value: boolean): 'Oui' | 'Non' {
        if (value) {
            return 'Oui';
        } else {
            return 'Non';
        }
    }
}