import Vue from 'vue';
Vue.directive('letters-only', {
    bind(el: HTMLElement) {
        el.addEventListener('keydown', (e: KeyboardEvent) => {
            const key = Number(e.key);
            if (isNaN(key) || e.key === null || e.key === ' ') {
                return;
            }
            e.preventDefault();
        });
    }
});
Vue.directive('ban-semicolon', {
    bind(el: HTMLElement) {
        el.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key !== ';') {
                return;
            }
            e.preventDefault();
        });
    }
});