import '@/assets/scss/style.scss';

import cloneDeep from 'lodash-es/cloneDeep';
import mergeWith from 'lodash-es/mergeWith';
import moment from 'moment';
import Vue, { CreateElement, VNode } from 'vue';
import VueI18n from 'vue-i18n';

import Meta from 'vue-meta';
import { Store } from 'vuex';
import { sync } from 'vuex-router-sync';
import { VueRouter } from 'vue-router/types/router';
import AsyncComputed from 'vue-async-computed';

import store, { configureHookStore } from '@/store';
import { createRouter, configureHookRouter } from '@/router';
import { RootState } from '@/store/state';
import App from '@/views/App.vue';

// Import Plu-gins.
import axios from 'axios';
import { AxiosPlugin, i18n } from '@/plugins';
import vuetify from '@/plugins/vuetifyPlugin';
import '@/plugins/veeValidatePlugin';
import { HttpClient } from '@/shared/HttpClient';
import { GlobalConfiguration } from './shared/GlobalConfiguration';

// Set de la locale pour moment js.
moment().locale('fr');

// Enregistrement de plu-gins.
Vue.use(AxiosPlugin);
Vue.use(AsyncComputed);
Vue.use(Meta, {
    keyName: 'metaInfo', // The component option name that vue-meta looks for meta info on.
    attribute: 'data-vue-meta', // The attribute name vue-meta adds to the tags it observes
    ssrAttribute: 'data-vue-meta-server-rendered', // The attribute name that lets vue-meta know that meta info has already been server-rendered
    tagIDKeyName: 'vmid', // The property name that vue-meta uses to determine whether to overwrite or append a tag
});
i18n.locale = 'fr'; // Définir une locale par défaut(sans elle, cela ne fonctionnera pas).

Vue.directive('scroll', {
    inserted(el, binding) {
        const f = (evt: Event) => {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    },
});

export interface IApp {
    app: Vue;
    router: VueRouter;
    // ConfigureHookRouter: (router: VueRouter) => void;
    store: Store<RootState>;
    // ConfigureHookStore: () => void;
    i18n?: VueI18n;
}

// Expose a factory function that creates a fresh set of store, router,
// Application instances on each call (which is called for each SSR request)
export const createApp = (initialState: any): IApp => {
    // Create router instances.
    const router = createRouter();
    sync(store, router);

    // Replace store.
    store.replaceState(mergeWith(cloneDeep(store.state), initialState));
    delete window.__INITIAL_STATE__;

    // Configure Hooker.
    configureHookStore();

    // Hook for router.
    configureHookRouter(router);

    // Create the application instance.
    // Here we inject the router, store and SSR context to all child components,
    // Making them available everywhere as `this.$router` and `this.$store`.
    // WithRender
    const app: Vue = new Vue({
        components: {
            App,
        },
        router,
        store,
        i18n,
        vuetify,
        render: (h: CreateElement): VNode => h(App),
        created: async () => await GlobalConfiguration.setBaseUrl(axios.defaults.baseURL).then(() => HttpClient.init(Vue.prototype.$security.getUser)),
    });
    // Expose the application, the router and the store.
    // Note we are not mounting the application here, since bootstrapping will be
    // Different depending on whether we are in a browser or on the server.
    return { app, router, store };
};
