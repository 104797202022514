/**
 * Énumération des états de contrat de partenariat. Spécifique aux utilisateurs installateurs.
 */
export enum EtatContratPartenariat {
    // Aucun statut.
    None = 0,
    // Contrat valide.
    Valide = 1,
    // Contrat valide.
    Invalide = 2,
    // Contrat en attente de validation.
    EnAttenteValidation = 3
}