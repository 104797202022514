/**
 * Énumération des niveaux des messages de retour de l'API.
 */
export enum MessageLevel {
    Info,
    Success,
    Warning,
    Error,
}

export enum MessageLevelAsString {
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error',
}
