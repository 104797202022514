/**
 * Énumération des statuts commerciaux d'une opération.
 */
export enum StatutCommercialOperation {
    /**
     * En traitement par Engie.
     */
    EN_TRAITEMENT_ENGIE = 1,
    /**
     * Validé par Engie.
     */
    VALIDE_ENGIE = 3,
    /**
     * Abandonné / Refusé par Engie.
     */
    ABANDONNE_REFUSE_ENGIE = 4,
    /**
     * Envoyé à Engie.
     */
    ENVOYE_ENGIE = 5,
    /**
     * En création (par un externe).
     */
    EN_CREATION = 7,
    /**
     * À recevoir (en création par un interne).
     */
    A_RECEVOIR = 8,
}