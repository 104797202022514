import { Operation, Societe, SiteTravaux, Utilisateur, PrescripteurInterne } from '.';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';

export class SimulationDossier {

    /**
     * Identifiant de la simulation.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public id: number = 0;

    /**
     * Les opérations liées.
     *
     * @type {Operation[]}
     * @memberof SimulationDossier
     */
    public operations: Operation[] = new Array<Operation>();

    /**
     * Identifiant du type de client représenté.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public clientRepresenteId: number = null;

    /**
     * Client est-il Syndic/SDC.
     *
     * @type {boolean}
     * @memberof SimulationDossier
     */
    public estSdc: boolean = false;

    /**
     * La société bénéficiaire.
     *
     * @type {Societe}
     * @memberof SimulationDossier
     */
    public societeBeneficiaire?: Societe = null;

    /**
     * La société de l'installateur.
     *
     * @type {Societe}
     * @memberof SimulationDossier
     */
    public societeInstallateur?: Societe = null;


    /**
     * Le site des travaux.
     *
     * @type {SiteTravaux}
     * @memberof SimulationDossier
     */
    public siteTravaux?: SiteTravaux = null;

    /**
     * Utilisateur qui a créé la simulation.
     *
     * @type {Utilisateur}
     * @memberof SimulationDossier
     */
    public utilisateurCreation: Utilisateur = null;

    /**
     * Id créateur de la simulation/dossier.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public utilisateurCreationId: number = 0;

    /**
     * Dernier utilisateur à avoir modifié la simulation.
     *
     * @type {Utilisateur}
     * @memberof SimulationDossier
     */
    public utilisateurModification: Utilisateur = null;

    /**
     * Référence du dossier.
     *
     * @type {string}
     * @memberof SimulationDossier
     */
    public intituleDossier: string = '';

    /**
     * Le nom du prescripteur interne.
     *
     * @type {PrescripteurInterne}
     * @memberof SimulationDossier
     */
    public prescripteurInterne: PrescripteurInterne = { id: 0, nom: '', prenom: '', nomPrenom: '', mail: '', entiteCommerciale: '', equipe: '', actif: true };

    /**
     * Id prescripteur interne.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public prescripteurInterneId: number = null;

    /**
     * Indique si le seuil minimum d'opération est désactivé.
     *
     * @type {boolean}
     * @memberof SimulationDossier
     */
    public isSeuilMinimumOperationDesactive: boolean = false;

    /**
     * Date role actif incitatif.
     *
     * @type {string}
     * @memberof SimulationDossier
     */
    public dateRoleActifIncitatif: string = '';

    /**
     * Numéro Dossier
     *
     * @type {string}
     * @memberof SimulationDossier
     */
    public numeroDossier: string = '';

    /**
     * Simulation ou dossier.
     *
     * @type {SimulationOuDossier}
     * @memberof SimulationDossier
     */
    public simulationOuDossier: SimulationOuDossier = null;

    /**
     * Statut de la simulation.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public statutSimulationId: number = 0;

    /**
     * Statut du dossier.
     *
     * @type {number}
     * @memberof SimulationDossier
     */
    public statutDossierId: number = 0;

    /**
     * Si une opération secteur tertiaire existe.
     */
    public estOperationSecteurResidentielExiste: boolean = false;

    /**
     * Note/Commentaire de la Simulation/Dossier
     *
     * @type {string}
     * @memberof SimulationDossier
     */
    public notes: string = '';

    /**
     * Sous canal
     *
     * @type {string}
     * @memberof SimulationDossier
     */
    public sousCanal: string = '';
}
