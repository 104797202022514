// https://gist.github.com/d-levin/18a75809ddd9b33366a3d28f6439c4ef
// https://github.com/PanJiaChen/vue-element-admin/blob/master/src/directive/permission/permission.js
import Vue, { VNode, VNodeDirective } from 'vue';

export type HandleType = Vue | HTMLElement;
export interface PermissionBindings extends VNodeDirective { }
export enum ArgsType {
    some = 'some',
    every = 'every',
}

const args: { [key: string]: string } = {
    SOME: 'some',
    EVERY: 'every'
};

/**
 * Permet de savoir, si contient la permissions.
 * @param permission
 */
const hasPermissionSet = (permissionsSet: string[]) => (permission: string): boolean => {
    return permissionsSet.includes(permission);
};

/**
 * Permet de savoir, si contient au moins une permission.
 * @param permissions
 */
const hasSomePermissionsSet = (permissionsSet: string[]) => (permissions: string[] = []): boolean => {
    return permissions.some((itemSet: string) => hasPermissionSet(permissionsSet)(itemSet));
};
/**
 * Permet de savoir, si contient toutes les permissions.
 * @param permissions
 */
const hasEveryPermissionSet = (permissionsSet: string[]) => (permissions: string[] = []): boolean => {
    return permissions.every((itemSet: string) => hasPermissionSet(permissionsSet)(itemSet))
};
/**
 * Remplace l'élément dans le DOM.
 * @param vnode
 */
export const replaceElementWithComment = (vnode: VNode) => {
    // /!\ Ne jamais modifier l'élément vnode, il doit être considéré comme readonly.
    if (vnode.elm && vnode.elm.parentElement) {
        vnode.elm.parentElement.removeChild(vnode.elm);
    }
}

/**
 * Désactive tous les noeuds de type formulaire au sein de l'élément.
 * @param element Élément à désactiver.
 * @param vnode Noeud de l'élément à désactiver.
 */
export const disableElement = (element: HTMLElement, vnode: VNode) => {
    // /!\ Ne jamais modifier l'élément vnode, il doit être considéré comme readonly.
    if (vnode.elm && vnode.elm.parentElement) {
        element.classList.add(disabledFormClass);
        element.classList.remove(vCloakClass);
        element.removeAttribute(vCloakClass);
    }
}


/**
 * Permet de savoir la fonction de vérification de permission à utiliser.
 * @param arg
 */
const checkerFunction = (arg: string): ((permissionsSet?: string[]) => (permissions: string[]) => boolean) => {
    switch (arg) {
        case args.SOME:
            return hasSomePermissionsSet;
        case args.EVERY:
            return hasEveryPermissionSet;
        default:
            return (permissionsSet?: string[]) => (permissions: string[]) => hasPermissionSet(permissionsSet)(permissions[0]);
    }
}
/**
 * Valide les arguments et sa valeur.
 * @param param0
 */
function validValue({ arg, value }: { arg: ArgsType, value: any }) {
    switch (arg) {
        case ArgsType.some:
        case ArgsType.every:
            return (value && value instanceof Array && value.length > 0) && Array.isArray(value);
        case undefined:
            return typeof value === 'string';
        default:
            return false;
    }
}
/**
 * Valide l'argument passé en paramètre.
 * @param arg
 */
const validArg = (arg: string): boolean => {
    return arg === undefined || Object.keys(args).some((key: string) => args[key] === arg);
};
/**
 * Valide l'argument avec sa valeur.
 * @param arg
 * @param value
 */
export const validate = (arg: ArgsType, value: string | string[]): void => {
    if (!validArg(arg)) {
        throw new TypeError(`[ ${arg} ] is not a valid argument`);
    }

    if (!validValue({ arg, value })) {
        throw new TypeError(`Value type [ ${typeof value} ] is not supported or does not match type expected by arg [ ${arg} ]`);
    }
}
/**
 * Check si les conditions sont vérifiées ou pas, sinon vide le nœud DOM.
 * @param el
 * @param binding
 * @param vnode
 * @param oldVnode
 */
export const check = (
    arg: string,
    value: string[],
    negateResult: boolean,
    callBackSet: ((profile: { [key: string]: string | string[] }) => string[]),
    callBackSuccess: (() => void),
    callBackError: (() => void), ) => {

    try {
        // valide les arguments.
        validate((ArgsType[arg as keyof typeof ArgsType]), value);

        Vue.prototype.$security.userProfile().then((profile: { [key: string]: string | string[] }) => {
            // Construit le vérificateur.
            const verifier = checkerFunction(arg);
            // On vérifie si la condition est vérifiée.

            let result = verifier(callBackSet(profile))(value);
            // Vérifie si on inverse le résultat ou pas.
            // Ici on inverse.
            if (negateResult) {
                result = !result;
            }
            if (!result) {
                // Appelle CallBack Erreur.
                callBackError();
            } else {
                // Appelle CallBack Succès.
                callBackSuccess();
            }
            //
        }).catch(() => callBackError());
    }
    catch{
        callBackError();
    }
};
/**
 * Check si les conditions sont vérifiées ou pas, sinon vide le nœud DOM.
 * @param el
 * @param binding
 * @param vnode
 * @param oldVnode
 */
export const checkerPermission = (profile: { [key: string]: string | string[] }): string[] => {
    return profile.permission as string[];
};
/**
 * Check si les conditions sont vérifiées ou pas, sinon vide le nœud DOM.
 * @param el
 * @param binding
 * @param vnode
 * @param oldVnode
 */
export const checkerProfil = (profile: { [key: string]: string | string[] }): string[] => {
    return [profile.profilCode as string] as string[];
};
export const disabledFormClass: string = 'disabled-form';
export const vCloakClass: string = 'v-cloak';