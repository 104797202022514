/**
 * Classe conteneur des identifiants de l'accord-cadre et Société.
 *
 * @export
 * @class AccordsCadresAddRemoveSociete
 */
export default class AccordsCadresAddRemoveSociete {
    /**
     * Identifiant de l'accord-cadre.
     *
     * @type {number}
     * @memberof AccordsCadresAddRemoveSociete
     */
    public accordCadreId: number = 0;
    /**
     * Identifiant de la société.
     *
     * @type {number}
     * @memberof AccordsCadresAddRemoveSociete
     */
    public societeId: number = 0;

    /**
     * Identifiant du Profil membre accord-cadre
     *
     * @type {number}
     * @memberof AccordsCadresAddRemoveSociete
     */
    public roleAccordCadreId: number = 3;

    /**
     * Creates an instance of AccordsCadresAddRemoveSociete.
     * @memberof AccordsCadresAddRemoveSociete
     */
    constructor(idAccordCadre: number, societeId: number, roleAccordCadreId?: number) {
        this.accordCadreId = idAccordCadre;
        this.societeId = societeId;
        this.roleAccordCadreId = roleAccordCadreId || 3;
    }
}
