export enum TypeContribution {
    ACHAT_REGISTRE = 13,
    AIDE_DIRECTE = 1,
    AVANCE_PRIME_BENEFICIAIRE = 10,
    COMMISSION_APPORTEUR_AFFAIRES = 14,
    PARTICIPATION_PROGRAMME = 19,
    PRESTATION_SERVICES = 6,
    PRET_BONIFIE_BENEFICIARE = 16,
    PRET_BONIFIE_PRIME_BENEFICIAIRE = 12,
    PRIME_BENEFICIAIRE = 11,
    REMISE_OFFRE_BENEFICIAIRE = 17,
    REMUNERATION_PARTENAIRE_DIVERS = 18,
    REMUNERATION_PARTENAIRE_INSTALLATEUR = 15,
    PREFINANCEMENT_REMUNERATION_PARTENAIRE_DIVERS = 21,
    PREFINANCEMENT_REMUNERATION_PARTENAIRE_INSTALLATEUR = 22,
    AVOIR_PRIME_CEE_BENEFICIAIRE = 24,
    AVOIR_REMUNERATION_PARTENAIRE_DIVERS = 25,
    AVOIR_REMUNERATION_PARTENAIRE_INSTALLATEUR = 26,
    AVOIR_COMMISSION_APPORTEUR_AFFAIRES = 27,
    AVOIR_PREFINANCEMENT_PRIME_CEE_BENEFICIAIRE = 28,
    AVOIR_PREFINANCEMENT_REMUNERATION_PARTENAIRE_INSTALLATEUR = 29,
    FEE_TRANSACTION_REGISTRE = 30
}