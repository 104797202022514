// Https://www.mistergoodcat.com/post/vuejs-plugins-with-typescript
// Https://github.com/yugasun/vue-axios-plugin/blob/master/src/index.js
// Https://github.com/pagekit/vue-resource/tree/develop/types

import _Vue from 'vue'; // <-- notice the changed import
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Notifications } from '@/shared/Notifications';


let pluginInstalled = false;
export class AxiosPluginOptions {
}

export function AxiosPlugin(Vue: typeof _Vue, options?: any): void {
    if (pluginInstalled) { return; }
    pluginInstalled = true;

    if (!axios) { return; }

    const metas = document.querySelectorAll('meta[name][data-initial-data]') || ([] as Element[]);
    metas.forEach((meta: any) => {
        axios.defaults.headers.common[meta.name] = meta.content;
    });


    if (!!window.__INITIAL_STATE__ && !!window.__INITIAL_STATE__.app) {
        axios.defaults.baseURL = window.__INITIAL_STATE__.app.baseApiUrl;
    }
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.validateStatus = (status: number) => {
        /* `validateStatus` defines whether to resolve or reject the promise for a given
         * HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
         * or `undefined`), the promise will be resolved; otherwise, the promise will be
         */
        return (status >= 200 && status < 300); // default
    };
    axios.interceptors.response.use(
        (response: AxiosResponse<any>) => successHandler(response),
        (error: AxiosResponse<any> | AxiosError | any) => errorHandler(error),
    );

    Vue.axios = axios;
    Vue.http = axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() { return axios; },
        },
        $http: {
            get() { return axios; },
        },
    });
}

const errorHandler = (error: AxiosResponse<any> | AxiosError): Promise<any> => {
    // Notifications flottante en cas d'erreur.
    Notifications.errorHandler(error);
    return Promise.reject({ ...error });
};


const successHandler = (response: AxiosResponse<any>): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    Notifications.successHandler((response as AxiosResponse<any>));
    return response;
};
