import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import { DefaultState, RootState } from './state';
import { AccordsCadresStore } from './modules/accordscadres/AccordsCadresStore';
import { UtilisateurStore } from './modules/utilisateur/UtilisateurStore';
import { ResultatSimulationStore } from './modules/simulation/ResultatSimulationStore';
import { informationsSimulationDossierStore } from './modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { ReferentielStore } from './modules/referentiel/ReferentielStore';
import { TableauDeBordStore } from './modules/TableauDeBord/TableauDeBordStore';
import { TemplateOperationStore } from './modules/TemplateOperation/TemplateOperationStore';
import { contratApporteursAffairesStore } from './modules/contratApporteursAffaires/contratApporteursAffairesStore';
import { AuthStore, authStoreTokenInterceptorPlugin } from './modules/auth/AuthStore';
import { operationsStore } from './modules/operations/OperationsStore';

Vue.use(Vuex);

// TODO Alban : En attente de trancher la question, on désactive le mode strict tout le temps.
//const strict = process.env.NODE_ENV !== 'production';
const strict = false;

/**
 * Création des paramètres du store.
 */
const storeOptions: StoreOptions<RootState> = {
    strict,
    state: DefaultState,   
    modules: {
        UtilisateurStore,
        AuthStore,
        ResultatSimulationStore,
        AccordsCadresStore,
        ReferentielStore,
        TableauDeBordStore,
        TemplateOperationStore,
        contratApporteursAffairesStore,
        informationsSimulationDossierStore,
        operationsStore
    },
};

const store: Store<RootState> = new Vuex.Store<RootState>(storeOptions);
export default store;

// ===
// Public helpers
// ===

const initPluginsStore = (): void => {
    authStoreTokenInterceptorPlugin(store);
};

export function configureHookStore(): void {
    initPluginsStore();
}
