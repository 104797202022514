import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Traduction de ressources
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'fr',
    messages: {
        fr: {
            view: require('@/assets/i18n/view.json'),
            validation: require('@/assets/i18n/validation.json'),
        },
    },
});
