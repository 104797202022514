export interface AuthState {
    currentUser?: User | null;
    accessToken?: string | null;
    tokenType?: string | null;
}

// Profile du user.
export interface UserProfile {
    amr: string;
    auth_time: number;
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    hasAccesBonificationCpe: boolean;
    hasAccesBonificationSme: boolean;
    hasAccesCochePnaq: boolean;
    hasAccesOperationsCoupDePouce: boolean;
    hasAccesOperationsReseau: boolean;
    hasAccesOperationsTransport: boolean;
    hasAccesOperationsNonStandard: boolean;
    hasContratApporteurAffairesEnCours: boolean;
    hasContratPartenariatValide: boolean;
    hasAccesCreationSimulationDossier: boolean;
    idp: string;
    isAdmin: boolean;
    isSeuilMinimumOperationDesactive: boolean;
    isSuperAdmin: boolean;
    isInterne: boolean;
    name: string;
    permission: string[];
    preferred_username: string;
    profilCode: string;
    profilId: number;
    sub: string;
    fonction: string;
}

export class User {
    get expiresIn(): number | undefined {
        if (this.expiresAt) {
            const now = parseInt((Date.now() / 1000).toString(), 10);
            return this.expiresAt - now;
        }
        return undefined;
    }

    set expiresIn(value: number | undefined) {
        const expiresin = parseInt((value || '').toString(), 10);
        if (typeof expiresin === 'number' && expiresin > 0) {
            const now = parseInt((Date.now() / 1000).toString(), 10);
            this.expiresAt = now + expiresin;
        }
    }
    get expired(): boolean | undefined {
        const expiresin = this.expiresIn;
        if (expiresin !== undefined) {
            return expiresin <= 0;
        }
        return undefined;
    }

    get scopes(): string[] {
        return (this.scope || '').split(' ');
    }

    get toStorageString(): string {
        return JSON.stringify({
            idToken: this.idToken,
            sessionState: this.sessionState,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            tokenType: this.tokenType,
            scope: this.scope,
            profile: this.profile,
            expiresAt: this.expiresAt,
        });
    }

    public static fromStorageString(storageString: string): User {
        return new User(JSON.parse(storageString));
    }
    public expiresAt: number;
    public idToken: string;
    public sessionState: string;
    public accessToken: string;
    public refreshToken: string;
    public tokenType: string;
    public scope: string;
    public profile: string;
    public state: string;

    constructor({
        idToken,
        sessionState,
        accessToken,
        refreshToken,
        tokenType,
        scope,
        profile,
        expiresAt,
        state,
    }: { idToken: string, sessionState: string, accessToken: string, refreshToken: string, tokenType: string, scope: string, profile: string, expiresAt: number, state: string }) {
        this.idToken = idToken;
        this.sessionState = sessionState;
        this.accessToken = accessToken;

        this.refreshToken = refreshToken;
        this.tokenType = tokenType;
        this.scope = scope;
        this.profile = profile;
        this.expiresAt = expiresAt;
        this.state = state;
    }
}
