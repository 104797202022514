import { CriteriaBase } from '@/shared/models';

export class TemplateOperationCriteria extends CriteriaBase {

    /**
     * Inclut ou non les templates d'opérations inactifs.
     */
    public inclureInactif: boolean = false;

    /**
     * Inclut ou non les templates d'opérations qui ne sont visibles qu'aux exetern.
     */
    public inclureInVisibleUtilisateurExterne: boolean = false;

    /**
     * Inclut uniquement les opérations dont le code contient ce champ.
     */
    public codeOperation: string = undefined;
}