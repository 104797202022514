import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { ContratApporteursAffaires, Utilisateur } from '@/models';
import { RootState } from '@/store/state';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';
import { ApiService } from '@/services/base/ApiService';
import { EnumProfilsAsNumber } from '@/shared/enums';


/**
 * Classe de gestion d'état pour le store d'un utilisateur.
 */
export class ContratApporteursAffairesState {

    /**
     * L'utilisateur en cours.
     */
    public contratApporteursAffaires: ContratApporteursAffaires;

    /**
     * Liste des profils que peut avoir un utilisateur.
     */
    public apporteurAffairesSelectionne: { id: number, prenomNom: string };

    /**
     * Constructeur.
     */
    constructor() {
        this.contratApporteursAffaires = new ContratApporteursAffaires();
        this.apporteurAffairesSelectionne = {
            id: this.contratApporteursAffaires.utilisateur.id,
            prenomNom: (this.contratApporteursAffaires.utilisateur.firstName + ' ' + this.contratApporteursAffaires.utilisateur.lastName)
        };
    }
}

/**
 * Constantes pour les méthodes du store.
 */
export enum ContratApporteursAffairesStoreMethods {
    CONTRAT_APPORTEURS_AFFAIRES = 'CONTRAT_APPORTEURS_AFFAIRES',
    SET_CONTRAT_APPORTEURS_AFFAIRES = 'SET_CONTRAT_APPORTEURS_AFFAIRES',
    SET_CONTRAT_APPORTEURS_AFFAIRES_BY_PROPERTIES = 'SET_CONTRAT_APPORTEURS_AFFAIRES_BY_PROPERTIES',
    APPORTEUR_AFFAIRES_SELECTIONNE = 'APPORTEUR_AFFAIRES_SELECTIONNE',
    SET_APPORTEUR_AFFAIRES_SELECTIONNE = 'SET_APPORTEUR_AFFAIRES_SELECTIONNE',
    RECUPERER_APPORTEURS_AFFAIRES_AUTOCOMPLETE = 'RECUPERER_APPORTEURS_AFFAIRES_AUTOCOMPLETE',
    CREER_CONTRAT_APPORTEURS_AFFAIRES = 'SAUVEGARDER_CONTRAT_APPORTEURS_AFFAIRES',
    MODIFIER_CONTRAT_APPORTEURS_AFFAIRES = 'MODIFIER_CONTRAT_APPORTEURS_AFFAIRES',
    RECUPERER_CONTRAT_APPORTEUR_AFFAIRES = 'RECUPERER_APPORTEURS_AFFAIRES',
    RESET_ALL = 'RESET_ALL',
}

/**
 * Getters du store.
 */
const getters: GetterTree<ContratApporteursAffairesState, RootState> = {
    [ContratApporteursAffairesStoreMethods.CONTRAT_APPORTEURS_AFFAIRES]: (state) => state.contratApporteursAffaires,
    [ContratApporteursAffairesStoreMethods.APPORTEUR_AFFAIRES_SELECTIONNE]: (state) => state.apporteurAffairesSelectionne,
};
/**
 * Mutateurs du store.
 */
const mutations: MutationTree<ContratApporteursAffairesState> = {
    [ContratApporteursAffairesStoreMethods.SET_CONTRAT_APPORTEURS_AFFAIRES]: (state: ContratApporteursAffairesState, contratApporteursAffaires: ContratApporteursAffaires) =>
        state.contratApporteursAffaires = contratApporteursAffaires,
    [ContratApporteursAffairesStoreMethods.SET_APPORTEUR_AFFAIRES_SELECTIONNE]: (state: ContratApporteursAffairesState, apporteurAffaires: { id: number, prenomNom: string }) =>
        state.apporteurAffairesSelectionne = apporteurAffaires,
    [ContratApporteursAffairesStoreMethods.SET_CONTRAT_APPORTEURS_AFFAIRES_BY_PROPERTIES]: (state: ContratApporteursAffairesState, property: object) =>
        state.contratApporteursAffaires = Object.assign({}, state.contratApporteursAffaires, property),
    [ContratApporteursAffairesStoreMethods.RESET_ALL]: (state: ContratApporteursAffairesState) => {
        state.contratApporteursAffaires = new ContratApporteursAffaires();
        state.apporteurAffairesSelectionne = {
            id: state.contratApporteursAffaires.utilisateur.id,
            prenomNom: (state.contratApporteursAffaires.utilisateur.firstName + ' ' + state.contratApporteursAffaires.utilisateur.lastName)
        };
    },
};

/**
 * Actions du store.
 */
const actions: ActionTree<ContratApporteursAffairesState, RootState> = {
    [ContratApporteursAffairesStoreMethods.RECUPERER_APPORTEURS_AFFAIRES_AUTOCOMPLETE]: (store: ActionContext<ContratApporteursAffairesState, RootState>,
                                                                                         recherche: string): Promise<Utilisateur[]> => {
        return ApiAutocompleteHelper.getAutocompleteByUtilisateurCriteriaPromise<Utilisateur>(recherche, [EnumProfilsAsNumber.ApporteurAffaires], 'utilisateur/obtenirTousUtilisateursParType');
    },

    [ContratApporteursAffairesStoreMethods.CREER_CONTRAT_APPORTEURS_AFFAIRES]: (store: ActionContext<ContratApporteursAffairesState, RootState>)
        : Promise<ContratApporteursAffaires> => {
        const apporteursAffairesService = new ApiService<ContratApporteursAffaires>('contratApporteursAffaires/creer');
        return new Promise<ContratApporteursAffaires>((resolve, reject) => {
            return apporteursAffairesService.post(store.state.contratApporteursAffaires)
                .then((response) => {
                    if (response.data && !(response.data as any).isError) {
                        resolve(response.data.data);
                    }
                    resolve(null);
                })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                });
        });
    },
    [ContratApporteursAffairesStoreMethods.MODIFIER_CONTRAT_APPORTEURS_AFFAIRES]: (store: ActionContext<ContratApporteursAffairesState, RootState>)
        : Promise<ContratApporteursAffaires> => {
        const apporteursAffairesService = new ApiService<ContratApporteursAffaires>('contratApporteursAffaires/modifier');
        return new Promise<ContratApporteursAffaires>((resolve, reject) => {
            return apporteursAffairesService.put(store.state.contratApporteursAffaires).then((response) => {
                if (response.data && !(response.data as any).isError) {
                    resolve(response.data.data);
                }
                resolve(null);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                });
        });
    },
    [ContratApporteursAffairesStoreMethods.RECUPERER_CONTRAT_APPORTEUR_AFFAIRES]: (store: ActionContext<ContratApporteursAffairesState, RootState>, payload: { id: number })
        : Promise<ContratApporteursAffaires> => {
        const contratApporteursAffairesService = new ApiService<ContratApporteursAffaires>('contratApporteursAffaires/obtenir');
        return new Promise<ContratApporteursAffaires>((resolve, reject) => {
            return contratApporteursAffairesService.get(payload.id).then((response) => {
                store.commit(ContratApporteursAffairesStoreMethods.SET_CONTRAT_APPORTEURS_AFFAIRES, response.data.data);
                store.commit(ContratApporteursAffairesStoreMethods.SET_APPORTEUR_AFFAIRES_SELECTIONNE,
                    { id: response.data.data.utilisateur.id, prenomNom: response.data.data.utilisateur.firstName + ' ' + response.data.data.utilisateur.lastName });
                resolve(response.data.data);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                });
        });
    }
};

/**
 * Export du module
 */
export const contratApporteursAffairesStore: Module<ContratApporteursAffairesState, RootState> = {
    state: new ContratApporteursAffairesState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
