
































import { Component, Prop, Vue } from 'vue-property-decorator';
import CeeDrawerItem from '@/components/CeeDrawerItem.vue';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { RouteConfig } from 'vue-router';
import { UserProfile } from '@/store/modules/auth/types';

@Component({
    name: 'CeeDrawer',
    components: {
        CeeDrawerItem
    },
})
export default class CeeDrawer extends Vue {

    @Prop({ default: () => new Array<RouteConfig>() }) public readonly routes!: RouteConfig[];
    @Prop({ default: false }) public readonly isNest!: boolean;

    // Récupère l'utlisateur courant.
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    // Permet de savoir si l'utilisateur connecté est admin;
    public estAdmin = false;

    // Charge l'utilisateur courant.
    public mounted(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdmin = profile.isAdmin;
            }
        });
    }

    public readonly search: string = '';

    public genChip(item: any): string {
        if (item.new) { return 'new'; }
        if (item.updated) { return 'updated'; }
        if (item.deprecated) { return 'deprecated'; }
        return '';
    }

    get items(): RouteConfig[] {
        return this.routes.filter(i => i.meta.visible());
    }
}
