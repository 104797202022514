import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { RootState } from '../../state';
import MontRetroceder from '@/components/Wizard/Commun/models/MontantRetroceder';
import MontantRetroceder from '@/components/Wizard/Commun/models/MontantRetroceder';
import { ApiService } from '@/services/base/ApiService';


/**
 * Classe de gestion d'état pour le store du résultat de la simulation.
 */
export class ResultatSimulationState {

    /**
     * Montant à rétrocéder.
     */
    public montantRetroceder: MontRetroceder;

    /**
     * Constructeur.
     */
    constructor() {
        this.montantRetroceder = new MontRetroceder();
    }
}

/**
 * Constantes pour les méthodes du store.
 */
export enum ResultatSimulationStoreMethods {
    MONTANT_A_RETROCEDER = 'MONTANT_A_RETROCEDER',
    SET_MONTANT_A_RETROCEDER = 'SET_MONTANT_A_RETROCEDER',
    SET_MONTANT_A_RETROCEDER_BY_PRIORITIES = 'SET_MONTANT_A_RETROCEDER_BY_PRIORITIES',
    MODIFIER_DONNEES_MONTANTRETROCEDER = 'MODIFIER_DONNEES_MONTANTRETROCEDER',
}

/**
 * Getters du store.
 */
const getters: GetterTree<ResultatSimulationState, RootState> = {
    [ResultatSimulationStoreMethods.MONTANT_A_RETROCEDER]: (state) => state.montantRetroceder,
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<ResultatSimulationState> = {
    [ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER]
        : (state: ResultatSimulationState, montantARetroceder: MontRetroceder) => state.montantRetroceder = montantARetroceder,
    [ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER_BY_PRIORITIES]: (state: ResultatSimulationState, property: object) =>
    state.montantRetroceder = Object.assign({}, state.montantRetroceder, property),
};

/**
 * Actions du store.
 */
const actions: ActionTree<ResultatSimulationState, RootState> = {
    [ResultatSimulationStoreMethods.MODIFIER_DONNEES_MONTANTRETROCEDER]: (store: ActionContext<ResultatSimulationState, RootState>): Promise<MontantRetroceder> => {
        const service = new ApiService<MontantRetroceder>('simulationDossier/modifierMontantRetroceder');
        return new Promise<MontantRetroceder>((resolve, reject) => {
            return service.put(store.state.montantRetroceder)
                .then((response) => {
                    store.commit(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER, response.data.data);
                    resolve(response.data.data);
                })
                .catch(() => {
                    reject('Une erreur est suvenu lors de la modification du montant à rétrocéder.');
                });
        });
    }
};

/**
 * Export du module
 */
export const ResultatSimulationStore: Module<ResultatSimulationState, RootState> = {
    state: new ResultatSimulationState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
