import moment from 'moment';
import { UniteDeTemps } from '@/shared/enums';

/**
 * Helper pour la gestion des dates avec moment.js notamment.
 */
export class DateHelper {
    public static formatDateParDefaut: string = 'YYYY-MM-DD';
    public static formatDateFR: string = 'DD/MM/YYYY';
    public static formatDateFRWithTime: string = 'DD/MM/YYYY  HH:mm:ss';
    // A mettre à jour tous les ans via https://www.joursferies.fr/. TODO : Chercher une solution plus pérenne.
    public static joursFeries: string[] = [
        '2020-01-01', '2020-04-13', '2020-05-01', '2020-05-08', '2020-05-21', '2020-06-01', '2020-07-14', '2020-08-15', '2020-11-01', '2020-11-11', '2020-12-25',
        '2021-01-01', '2021-04-05', '2021-05-01', '2021-05-08', '2021-05-13', '2021-05-24', '2021-07-14', '2021-08-15', '2021-11-01', '2021-11-11', '2021-12-25',
        '2022-01-01', '2022-05-01', '2022-05-08', '2022-05-26', '2022-06-06', '2022-07-14', '2022-08-15', '2022-11-01', '2022-11-11', '2022-12-25'
    ];

    public static lundi: number = 1;
    public static samedi: number = 6;
    public static dimanche: number = 0;

    /**
     * Récupère la date d'aujourd'hui au format iso.
     * */
    public static todayIso(): string {
        return moment().format();
    }

    public static equalsToday(date: string): boolean {
        return date === moment().format(this.formatDateParDefaut);
    }

    /**
     * Converti une date au format ISO 8601.
     * @param date : la date à traiter
     */
    public static toIsoString(date: string | Date) {
        if (!date) {
            return undefined;
        }

        return moment(date).format();
    }

    /**
     * Converti une date fr ou de base au format ISO 8601.
     * @param date : la date à traiter
     */
    public static frOrBasetoIsoString(date: string) {

        if (!date) {
            return undefined;
        }

        let result = this.frToIso(date);

        if (result !== 'Invalid date') {
            return result;
        }

        return this.toIsoString(date);
    }

    /**
     * Convertie une date au format fr vers le format iso.
     * @param date la date à convertir.
     */
    public static frToIso(date: string): string {
        return moment(date, DateHelper.formatDateFR).format();
    }

    public static dateFrWithTime(date: string): string {
        return moment(date).format(DateHelper.formatDateFRWithTime);
    }

    /**
     * Détermine le nombre d'année sur lesquelles portent la plage de date.
     * @param dateDebut Début de la plage.
     * @param dateFin Fin de la plage.
     * @returns Le nombre d'années.
     */
    public static nombreAnneeSurPeriode(dateDebut: string, dateFin: string): number {
        return moment(dateFin).get(UniteDeTemps.year) - moment(dateDebut).get(UniteDeTemps.year) + 1;
    }

    /**
     * Détermine le nombre de mois sur lesquelles portent la plage de date.
     * @param dateDebut Début de la plage.
     * @param dateFin Fin de la plage.
     * @returns Le nombre de mois.
     */
    public static nombreMoisSurPeriode(dateDebut: string, dateFin: string): number {
        return (this.nombreAnneeSurPeriode(dateDebut, dateFin) - 1) * 12
            + moment(dateFin).get(UniteDeTemps.month) - moment(dateDebut).get(UniteDeTemps.month) + 1;
    }

    /**
     * Get the value of an element of the date.
     * @param date The date.
     * @param unitOfTime The unit of time.
     */
    public static get(date: string, unitOfTime: UniteDeTemps): number {
        return moment(date).get(unitOfTime);
    }

    /**
     * Converti une date au format DD/MM/YYYY.
     * @param date : la date à traiter
     */
    public static format(date: string | Date): string {
        return moment(date).format(DateHelper.formatDateFR);
    }

    /**
     * Converti une date au format paramétré.
     * @param date : la date à traiter
     */
    public static formatParam(date: string, format: string) {
        if (!date) {
            return undefined;
        }

        let momentDate = moment(date);
        if (!momentDate.isValid()) {
            momentDate = moment(date, 'DD-MM-YYYY');
        }

        return momentDate.format(format);
    }

    /**
     * Renvoie la date du premier du mois correspondant à une date.
     * @param date La date de référence.
     */
    public static estPremierDuMois(date: Date): boolean {
        return moment(date).date() === 1;
    }
    /**
     * Renvoie la date du dernier du mois correspondant à une date.
     * @param date La date de référence.
     */
    public static estDernierDuMois(date: Date): boolean {
        const datePlusUnJour = moment(date).add(1, UniteDeTemps.days);
        return datePlusUnJour.date() === 1;
    }

    /**
     * Renvoie le premier jour du mois.
     * @param date : La date de référence
     */
    public static premierJourMois(date: string) {
        return moment(date).startOf('month').format(DateHelper.formatDateParDefaut);
    }

    /**
     * Récupère le nombre de jours d'un mois donné.
     * @param annee L'année.
     * @param mois Le mois.
     */
    public static getJoursDansLeMois(annee: number, mois: number) {
        return moment(this.creerDate(annee, mois, 1)).daysInMonth();
    }

    /**
     * Ajout sur date.
     * @param date Date à modifier au format ISO.
     * @param valeur Valeur à ajouter.
     * @param unit Unité à modifier.
     */
    public static add(date: string, valeur: number, unit: any) {
        const dateEntree = moment(date);
        if (dateEntree.isValid()) {
            return dateEntree.add(valeur, unit).format(DateHelper.formatDateFR);
        } else {
            return '';
        }
    }

    /**
     * Créer une date à partir de l'année, du mois et du jour.
     * @param annee L'année.
     * @param mois Le mois.
     * @param jour Le jour.
     */
    public static creerDate(annee: number, mois: number, jour: number): string {
        return moment(`${annee}-${mois}-${jour}`, DateHelper.formatDateParDefaut).format(DateHelper.formatDateParDefaut);
    }

    /**
     * Vérifie qu'on peut faire une date valide à partir de l'année, du mois et du jour.
     * @param annee L'année.
     * @param mois Le mois.
     * @param jour Le jour.
     */
    public static dateValide(annee: number, mois: number, jour: number): boolean {
        const date = moment(`${annee}-${mois}-${jour}`, DateHelper.formatDateParDefaut);
        return date.isValid();
    }

    /**
     * Compare deux dates entre elles.
     * @param premiereDateIso Première date au format ISO.
     * @param secondeDateIso Seconde date au format ISO.
     * @param accepterEgaliteDate Retourner vrai si les 2 dates sont identiques. Par défaut à faux.
     */
    public static premiereSuperieurASeconde(premiereDateIso: string, secondeDateIso: string, accepterEgaliteDate: boolean = false) {
        const premiereDate = moment(premiereDateIso);
        const secondeDate = moment(secondeDateIso);

        if (accepterEgaliteDate) {
            return premiereDate >= secondeDate;
        } else {
            return premiereDate > secondeDate;
        }
    }

    /**
     * Compare deux dates entre elles.
     * @param premiereDateIso Première date au format ISO.
     * @param secondeDateIso Seconde date au format ISO.
     * @param accepterEgaliteDate Retourner vrai si les 2 dates sont identiques. Par défaut à faux.
     */
    public static getClosest(premiereDateIso: string, secondeDateIso: string): string{
        const premiereDate = moment(premiereDateIso);
        const secondeDate = moment(secondeDateIso);
        if (premiereDateIso == null || premiereDateIso === "" || premiereDate > secondeDate) {
            return secondeDate.toISOString();
        } else {
            return premiereDate.toISOString();
        }
    }

    /**
     * Compare deux dates entre elles, sans prendre en compte le Time.
     * @param premiereDateIso Première date au format ISO.
     * @param secondeDateIso Seconde date au format ISO.
     * @param accepterEgaliteDate Retourner vrai si les 2 dates sont identiques. Par défaut à faux.
     */
    public static premiereSuperieurASecondeWithoutTime(premiereDateIso: string, secondeDateIso: string, accepterEgaliteDate: boolean = false) {
        let premiereDate = moment(moment(premiereDateIso).format(DateHelper.formatDateParDefaut));
        let secondeDate = moment(moment(secondeDateIso).format(DateHelper.formatDateParDefaut));

        // Si première date invalide on essaye d'uitliser le format FR pour récuperer la date.
        if (!premiereDate.isValid()) {
            premiereDate = moment(moment(premiereDateIso, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut));
        }
        if (!secondeDate.isValid()) {
            secondeDate = moment(moment(secondeDateIso, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut));
        }

        if (accepterEgaliteDate) {
            return premiereDate >= secondeDate;
        } else {
            return premiereDate > secondeDate;
        }
    }

    /**
     * Test l'égalité entre deux dates.
     * @param premiereDate
     * @param secondeDate
     */
    public static sontEgales(premiereDate: string, secondeDate: string): boolean {
        const date1 = moment(premiereDate).format(DateHelper.formatDateParDefaut);
        const date2 = moment(secondeDate).format(DateHelper.formatDateParDefaut);
        return date1 === date2;
    }

    /**
     * Récupère la liste des mois de l'année.
     */
    public static listeMois(): string[] {
        moment.locale('fr');
        return moment.months('MMMM');
    }

    /**
     * Convertit une chaine de caractère au format date
     * @param date
     */
    public static stringToDate(date: string): Date | undefined {
        let newDate = moment(date);
        if (newDate.isValid()) {
            return newDate.toDate();
        } else {
            return undefined;
        }
    }

    /**
     * Ajout d'un nombre donné de jours ouvrés à la date donnée.
     * @param date Date à modifier.
     * @param numDaysToAdd Nombre de jours ouvrés à ajouter.
     */
    public static addDaysAndSkeepWeekend(date: string, numDaysToAdd: number) {
        
        // let daysRemaining = numDaysToAdd;
        const newDate = moment(date).add(numDaysToAdd, 'days');

        while (newDate.day() === this.samedi || newDate.day() === this.dimanche || this.joursFeries.some((item) => newDate.isSame(item))) {
            newDate.add(1, 'days');
        }
        
        return newDate;
    }
}
