import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../../state';
import { Operation } from '@/models';
import { ApiService } from '@/services/base/ApiService';
/**
 * Classe de gestion de la référence sur l'ensemble des étapes.
 */
export class OperationsState {

    /**
     * Numéro (identifiant) de simulation/dossier, non-modifiable par l'utilisateur.
     */
    public currentOperation: Operation = null;

}

/**
 * Constantes pour les méthodes du store.
 */
export enum OperationsStoreMethods {
    OPERATION_EN_COURS = 'OPERATION_EN_COURS',
    SET_OPERATION_EN_COURS = 'SET_OPERATION_EN_COURS',
    GET_VALIDATION_SAISIES = 'GET_VALIDATION_SAISIES'
}

/**
 * Getters du store.
 */
const getters: GetterTree<OperationsState, RootState> = {
    [OperationsStoreMethods.OPERATION_EN_COURS]: (state) => state.currentOperation,
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<OperationsState> = {
    [OperationsStoreMethods.SET_OPERATION_EN_COURS]
        : (state: OperationsState, operation: Operation) => state.currentOperation = operation,
};

const actions: ActionTree<OperationsState, RootState> = {
    [OperationsStoreMethods.GET_VALIDATION_SAISIES]: (store: ActionContext<OperationsState, RootState>, formDataOperations: any[]): Promise<string[]> => {
        return new Promise<string[]>((resolve) => {
            const apiService = new ApiService<any>(`/operation/validationOperations`);
            apiService.post(formDataOperations).then((result) => {
                resolve(result.data.data);
            });
        });
    },
};

/**
 * Export du module
 */
export const operationsStore: Module<OperationsState, RootState> = {
    state: new OperationsState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
