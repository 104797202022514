import Vue from 'vue';
import { DateHelper } from '../helpers';

Vue.filter('dateFormat', (value: string) => {
    validerArguments(value);
    return DateHelper.format(value.substr(0, 10));
});

Vue.filter('dateFormatWithTime', (value: string) => {
    validerArguments(value);
    return DateHelper.dateFrWithTime(value);
});

Vue.filter('dateFormat&Add2Months', (value: string) => {
    validerArguments(value);
    return DateHelper.add(value, 2, 'M');
});


const validerArguments = (value: string) => {
    if (value === null || typeof value === 'undefined' || !value ) {
        throw new Error('La date défini comme paramètre n\'est pas valide.');
    }
};
