import { Component, Vue } from 'vue-property-decorator';
import template from './Home.Template.vue';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';

@Component({
    metaInfo: {
        title: 'Prime d\'économies d\'énergie',
    },
    ...template,
    name: 'Home',
})
export default class Home extends Vue {

    // Affichage la vidéo youtube dans une iframe.
    public dialog: boolean = false;

    // Promesse indiquant si l'utilisateur est connecté.
    @Getter(AuthStoreMethods.IS_CURRENT_USER_LOGGED_IN)
    public isUserLoggedInPromise: Promise<boolean>;

    // Indique si l'utilisateur est connecté.
    public isUserLoggedIn: boolean = false;

    public mounted() {
        // Check que l'utilisateur est connecté. Si oui on affiche le tableau de bord.
        this.isUserLoggedInPromise.then((reponse: boolean) => {
            this.isUserLoggedIn = reponse;
        });
    }

}

