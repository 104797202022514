export class UtilisateurConnexion {
  public email: string;
  public password: string;
  public username: string;

 constructor();
 constructor(email: string, password: string, username: string);
 constructor(email?: string, password?: string, username?: string) {
      this.email = email || '';
      this.password = password || '';
      this.username = username || '';
 }

}
