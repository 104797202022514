import { MessageLevel } from '@/shared/enums/MessageLevel.enum';


const levels = new Map<number, string>([
    [MessageLevel.Info, 'infos'],
    [MessageLevel.Success, 'success'],
    [MessageLevel.Warning, 'warning'],
    [MessageLevel.Error, 'error']
]);

/**
 * Modèle de message de l'API.
 */
export class Message {
    public messageLevel: MessageLevel = MessageLevel.Error;
    /**
     * Retourne le message level sous forme de string.
     */
    public get messageLevelString(): string {
        if (this.messageLevel) {
            return levels.get(this.messageLevel);
        }
        return 'error';
    }

    public text!: string;
}
