import { Societe, RoleAccordCadre } from '@/models';

export class AccordsCadresSociete {

    /**
     * Id accord cadre société.
     * @type {number}
     * @memberof AccordsCadresSociete
     */
    public id: number = 0;
    /**
     * Id de l'accord-cadre lié à AccordCadreSociete
     *
     * @type {number}
     * @memberof AccordsCadresSociete
     */
    public accordCadreId: number = 0;
    /**
     * Id de la société liée à AccordCadreSociete
     *
     * @type {number}
     * @memberof AccordsCadresSociete
     */
    public societeId: number = 0;
    /**
     * Objet Societe liée à l'accord-cadre
     *
     * @type {Societe}
     * @memberof AccordsCadresSociete
     */
    public societe: Societe;
    /**
     * Id du Profil accord-cadre lié à AccordCadreSociete
     *
     * @type {number}
     * @memberof AccordsCadresSociete
     */
    public roleAccordCadreId: number;
    /**
     * Objet Profil accord-cadre
     *
     * @type {RoleAccordCadre}
     * @memberof AccordsCadresSociete
     */
    public roleAccordCadre: RoleAccordCadre;

    /**
     * Creates an instance of AccordsCadresSociete.
     * @param {number} accordCadre
     * @param {number} societe
     * @param {number} ProfilId
     * @memberof AccordsCadresSociete
     */
    constructor(accordCadre: number, societeId: number, societe: Societe, roleId: number) {
        this.accordCadreId = accordCadre;
        this.societeId = societeId;
        this.societe = societe;
        this.roleAccordCadreId = roleId;
        this.roleAccordCadre = { id: 3, code: 'STAND', libelle: 'Standard'};
    }
}
