/**
 * Modèle des valeurs référentielles.
 */
export class ValeurReferentielle {
    /**
     * Identifiant.
     */
    public id: number = 0;

    /**
     * Code unique.
     */
    public code: string = '';

    /*
     * Libellé affiché.
     */
    public libelle: string = '';

    constructor(id: number, code: string, libelle: string) {
        this.id = id;
        this.code = code;
        this.libelle = libelle;
    }
}
