















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Error',
    components: {
        Error403: require('@/components/Error/_403.vue').default,
        Error404: require('@/components/Error/_404.vue').default,
    },
})
export default class Error extends Vue {
    // Le statut du message d'erreur.
    @Prop({ default: 404 }) public readonly status!: number;

    /**
     *  Retourne le composant d'erreur.
     */
    public get errorComponent() {
        return () => import(`@/components/Error/_${this.status}.vue`);
    }
}
