/**
 * Énumération des statuts du dossier.
 */
export enum StatutDossier {
        NONE = 0,
        EN_CREATION = 1,
        A_RECEVOIR = 2,
        ENVOYE_ENGIE = 3,
        EN_TRAITEMENT_ENGIE = 4,
        FINALISE_ENGIE = 5,
        NON_DEPOSE_PNCEE = 6,
        DEPOSE_PNCEE = 7,
        TRAITE_PNCEE = 8,
        PAYE_ENGIE = 9,
        SUPPRIMEE = 10
}

export enum StatutDossierAsString {
        EN_CREATION = 'En création',
        A_RECEVOIR = 'À recevoir',
        ENVOYE_ENGIE = 'Envoyé à ENGIE',
        EN_TRAITEMENT_ENGIE = 'En traitement par ENGIE',
        FINALISE_ENGIE = 'Finalisé par ENGIE',
        NON_DEPOSE_PNCEE = 'Finalisé par ENGIE',
        DEPOSE_PNCEE = 'Déposé au PNCEE',
        TRAITE_PNCEE = 'Traité par PNCEE',
        PAYE_ENGIE = 'Payé par ENGIE',
        SUPPRIMEE = 'Dossier Supprimée',
}
