import Vue from 'vue';

/**
 * Méthode permettant de formatter un nombre.
 */
Vue.filter('numberFormat', (value: number, style: string = 'decimal', maximumFractionDigits: number = 3): string => {
    if (value === undefined || value === null || typeof value === (void 0) || isNaN(value)) {
        return '-';
    }

    // Formate le nombre si possible.
    return new Intl.NumberFormat('fr-FR', { style: style || 'decimal', currency: 'EUR', maximumFractionDigits: maximumFractionDigits }).format(value);

});

/**
 * Méthode permettant de formatter un nombre.
 */
Vue.filter('volumeFormat', (value: number, style: string = 'decimal'): string => {
    if (value === null || typeof value === (void 0)) {
        return '';
    }
    return new Intl.NumberFormat('fr-FR', { style: style || 'decimal', currency: 'EUR'}).format(value);
});
