import { ActionTree, GetterTree, Module, MutationTree, ActionContext } from 'vuex';
import { Profil, AccordCadre, Societe, DroitsAccordsCadres } from '@/models';
import { RootState } from '../../state';
import { ApiService } from '@/services/base/ApiService';
import AccordsCadresAddRemoveSociete from '@/components/AccordsCadres/models/AccordsCadresAddRemoveSociete';
import AccordsCadresGeneral from '@/components/AccordsCadres/models/AccordsCadresGeneral';
import { MapAccordCadre } from '@/components/AccordsCadres/helpers/MapAccordCadre';
import {RoleAccordCadre} from '@/shared/enums';

/**
 * Classe de gestion d'état pour le store d'un utilisateur.
 */
export class AccordsCadresState {

    /**
     * L'accord-cadre persisté.
     */
    public accordsCadres: AccordCadre;

    public estInfosGeneralModifiees: boolean;

    public estFichierContratChoisi: boolean;
    /**
     * Sociétés membres.
     *
     * @type {Societe[]}
     * @memberof AccordsCadresState
     */
    public membres: Societe[];

    /**
     * Sociétés membres séléctionnées.
     *
     * @type {Societe[]}
     * @memberof AccordsCadresState
     */
    public membresSelectiones: Societe[];

    /**
     * Identifiant du profil séléctionné.
     *
     * @type {number}
     * @memberof AccordsCadresState
     */
    public profilAccordCadre: Profil;

    /**
     * Constructeur.
     */
    constructor() {
        this.accordsCadres = new AccordCadre();
        this.membres = new Array<Societe>();
        this.membresSelectiones = new Array<Societe>();
        this.profilAccordCadre = {id: 0, name: 'N/D', description: 'N/D', isIntern: false, isAdministrateur: false};
        this.estInfosGeneralModifiees = false;
        this.estFichierContratChoisi = false;
    }
}

/**
 * Constantes pour les méthodes du store.
 */
export enum AccordsCadresStoreMethods {
    ACCORD_CADRE = 'ACCORD_CADRE',
    INFOS_GENERAL_MODIFIEES = 'INFOS_GENERAL_MODIFIEES',
    EST_FICHIER_CONTRAT_CHOISI = 'EST_FICHIER_CONTRAT_CHOISI',
    SET_INFOS_GENERAL_MODIFIEES_ACTION = 'SET_INFOS_GENERAL_MODIFIEES_ACTION',
    SET_EST_FICHIER_CONTRAT_CHOISI = 'SET_EST_FICHIER_CONTRAT_CHOISI',
    SET_INFOS_GENERAL_MODIFIEES = 'SET_INFOS_GENERAL_MODIFIEES',
    SET_ACCORD_CADRE = 'SET_ACCORD_CADRE',
    SET_ACCORD_CADRE_BY_PROPERTIES = 'SET_ACCORD_CADRE_BY_PROPERTIES',
    MEMBRES = 'MEMBRES',
    SET_MEMBRES = 'SET_MEMBRES',
    PROFIL_ACCORD_CADRE = 'PROFIL_ACCORD_CADRE',
    SET_PROFIL_ACCORD_CADRE = 'SET_PROFIL_ACCORD_CADRE',
    MEMBRES_SELECTIONNEES = 'MEMBRES_SELECTIONNEES',
    AJOUTER_MEMBRES_SELECTIONNEES = 'AJOUTER_MEMBRES_SELECTIONNEES',
    RETIRER_MEMBRES_SELECTIONNEES = 'RETIRER_MEMBRES_SELECTIONNEES',
    RECUPERER_MEMBRES_ENFCT_PROFIL = 'RECUPERER_MEMBRES_ENFCT_PROFIL',
    PERSISTER_ACCORD_CADRE = 'PERSISTER_ACCORD_CADRE',
    RECUPERER_ACCORD_CADRE = 'RECUPERER_ACCORD_CADRE',
    RECUPERER_PROFIL_ACCORD_CADRE = 'RECUPERER_PROFIL_ACCORD_CADRE',
    MODIFIER_ROLE_MEMBRE = 'MODIFIER_ROLE_MEMBRE',
    PERSISTER_AJOUT_MEMBRES_SELECTIONNEES = 'PERSISTER_AJOUT_MEMBRES_SELECTIONNEES',
    SUPPRIMER_MEMBRE = 'SUPPRIMER_MEMBRE',
    MODIFIER_INFOSGENERAL_ACCORD_CADRE = 'MODIFIER_INFOSGENERAL_ACCORD_CADRE',
    RESET_ALL= 'RESET_ALL',
    RESET_MEMBRES_SELECTIONNES = 'RESET_MEMBRES_SELECTIONNES',
    SET_DROITS = 'SET_DROITS',
    SET_SOCIETE_AC = 'SET_SOCIETE_AC',
    SET_DROITS_ACTION = 'SET_DROITS_ACTION',
}

/**
 * Getters du store.
 */
const getters: GetterTree<AccordsCadresState, RootState> = {
    [AccordsCadresStoreMethods.ACCORD_CADRE]: (state) => state.accordsCadres,
    [AccordsCadresStoreMethods.MEMBRES]: (state) => state.membres,
    [AccordsCadresStoreMethods.PROFIL_ACCORD_CADRE]: (state) => state.profilAccordCadre,
    [AccordsCadresStoreMethods.MEMBRES_SELECTIONNEES]: (state) => state.membresSelectiones,
    [AccordsCadresStoreMethods.INFOS_GENERAL_MODIFIEES]: (state) => state.estInfosGeneralModifiees,
    [AccordsCadresStoreMethods.EST_FICHIER_CONTRAT_CHOISI]: (state) => state.estFichierContratChoisi,
};

/**
 * Mutateurs du store.
 */
const mutations: MutationTree<AccordsCadresState> = {
    [AccordsCadresStoreMethods.SET_ACCORD_CADRE]: (state: AccordsCadresState, accordsCadres: AccordCadre) => state.accordsCadres = accordsCadres,
    [AccordsCadresStoreMethods.SET_INFOS_GENERAL_MODIFIEES]: (state: AccordsCadresState, estInfosGeneralModifiees: boolean) =>
        state.estInfosGeneralModifiees = estInfosGeneralModifiees,
    [AccordsCadresStoreMethods.SET_EST_FICHIER_CONTRAT_CHOISI]: (state: AccordsCadresState, estFichierChoisi: boolean) =>
        state.estFichierContratChoisi = estFichierChoisi,
    [AccordsCadresStoreMethods.SET_ACCORD_CADRE_BY_PROPERTIES]: (state: AccordsCadresState, accordsCadresProperty: object) =>
        state.accordsCadres = Object.assign({}, state.accordsCadres, accordsCadresProperty),
    [AccordsCadresStoreMethods.SET_MEMBRES]: (state: AccordsCadresState, membres: Societe[]) => state.membres = membres,
    [AccordsCadresStoreMethods.SET_PROFIL_ACCORD_CADRE]: (state: AccordsCadresState, profilAccordCadre: Profil) => state.profilAccordCadre = profilAccordCadre,
    [AccordsCadresStoreMethods.AJOUTER_MEMBRES_SELECTIONNEES]: (state: AccordsCadresState, membreSelectionne: Societe[]) => state.membresSelectiones = membreSelectionne,
    [AccordsCadresStoreMethods.RETIRER_MEMBRES_SELECTIONNEES]: (state: AccordsCadresState, membreSelectionne: Societe) => {
        const index = state.membresSelectiones.indexOf(membreSelectionne);
        if (index >= 0) {
            state.membresSelectiones.splice(index, 1);
        }
    },
    [AccordsCadresStoreMethods.RESET_ALL]: (state: AccordsCadresState) => {
        state.accordsCadres = new AccordCadre();
        state.membres = new Array<Societe>();
        state.membresSelectiones = new Array<Societe>();
        state.profilAccordCadre = {id: 0, name: 'N/D', description: 'N/D', isIntern: false, isAdministrateur: false};
    },
    [AccordsCadresStoreMethods.RESET_MEMBRES_SELECTIONNES]: (state: AccordsCadresState) => state.membresSelectiones = [],
    [AccordsCadresStoreMethods.SET_DROITS]: (state: AccordsCadresState) => {
        state.accordsCadres.droitsAccordsCadres = state.membresSelectiones.map((membre) => {
            return {
                accordCadreId: state.accordsCadres.id,
                societeAffilieeId: membre.id,
                utilisateurId: membre.utilisateur.id,
            };
        });
    },
    [AccordsCadresStoreMethods.SET_SOCIETE_AC]: (state: AccordsCadresState) => {
        state.accordsCadres.accordCadreSocietes = state.membresSelectiones.map((membre) => {
            return {
                id: 0,
                accordCadreId: 0,
                societe: null,
                societeId: membre.id,
                roleAccordCadreId: RoleAccordCadre.STANDARD,
                roleAccordCadre: null,
            };
        });
    }
};

/**
 * Actions du store.
 */
const actions: ActionTree<AccordsCadresState, RootState> = {
    [AccordsCadresStoreMethods.SET_DROITS_ACTION]: (store: ActionContext<AccordsCadresState, RootState>): void => {
        store.commit(AccordsCadresStoreMethods.SET_DROITS);
        const accordCadreService = new ApiService<DroitsAccordsCadres[]>('/accordCadre/modifierDroits');
        accordCadreService.post(store.state.accordsCadres.droitsAccordsCadres).catch(() => {throw new Error('Erreur lors de définition des droits AC'); });
    },
    [AccordsCadresStoreMethods.RECUPERER_MEMBRES_ENFCT_PROFIL]: (store: ActionContext<AccordsCadresState, RootState>, payload: { profil: Profil}): Promise<Societe[]> => {
        const societeService = new ApiService<Societe>('societe/obtenir');
        return new Promise<Societe[]>((resolve, reject) => {
            if (payload && payload.profil) {
                return societeService.getWhere(`ProfilId=${payload.profil.id}&accordCadreId=${store.state.accordsCadres.id}`).then((response) => {
                    store.commit(AccordsCadresStoreMethods.SET_PROFIL_ACCORD_CADRE, payload.profil);
                    store.commit(AccordsCadresStoreMethods.SET_MEMBRES, response.data.data);
                    store.commit(AccordsCadresStoreMethods.RESET_MEMBRES_SELECTIONNES);
                    resolve(response.data.data);
                })
                    .catch((error: { response: any; }) => {
                        reject(error.response);
                    });
            }
            else {
                return Promise.resolve([]);
            }
        });
    },
    [AccordsCadresStoreMethods.PERSISTER_ACCORD_CADRE]: (store: ActionContext<AccordsCadresState, RootState>): Promise<void | AccordCadre> => {
        store.commit(AccordsCadresStoreMethods.SET_SOCIETE_AC);
        const accordCadreService = new ApiService<AccordCadre>('accordCadre/creer');
        return new Promise<AccordCadre>((resolve, reject) => {
            return accordCadreService.put(store.state.accordsCadres)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        }).then((response) => {
            if (!!response) {
                store.commit(AccordsCadresStoreMethods.SET_ACCORD_CADRE_BY_PROPERTIES, {id: response.id});
                store.commit(AccordsCadresStoreMethods.SET_EST_FICHIER_CONTRAT_CHOISI, false);
                if (store.state.membresSelectiones.length > 0) {
                    store.dispatch(AccordsCadresStoreMethods.SET_DROITS_ACTION);
                }
            }
            return response;
        });
    },
    [AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE]: (store: ActionContext<AccordsCadresState, RootState>, payload: { id: number}): Promise<AccordCadre> => {
        const accordCadreService = new ApiService<AccordCadre>('accordCadre/obtenirFull');
        return new Promise<AccordCadre>((resolve, reject) => {
           return accordCadreService.get(payload.id)
           .then((response) => {
                store.commit(AccordsCadresStoreMethods.SET_ACCORD_CADRE, response.data.data);
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    },
    [AccordsCadresStoreMethods.RECUPERER_PROFIL_ACCORD_CADRE]: (store: ActionContext<AccordsCadresState, RootState>): Promise<Profil> => {
        const accordCadreService = new ApiService<Profil>('accordCadre/recupererProfilParSocieteMembre');
        return new Promise<Profil>((resolve, reject) => {
            return accordCadreService.get(store.state.accordsCadres.id)
            .then((response) => {
                store.dispatch(AccordsCadresStoreMethods.RECUPERER_MEMBRES_ENFCT_PROFIL, {profil: response.data.data});
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    },
    [AccordsCadresStoreMethods.PERSISTER_AJOUT_MEMBRES_SELECTIONNEES]: (store: ActionContext<AccordsCadresState, RootState>): Promise<void> => {
        const accordCadreService = new ApiService<{
            accordCadreId: number,
            societeId: number,
            utilisateurId: number,
        }>('accordCadre/ajouterSociete');
        return new Promise<void>((resolve, reject) => {
            for (const membre of store.state.membresSelectiones) {
                accordCadreService.post({accordCadreId : store.state.accordsCadres.id, societeId: membre.id, utilisateurId: membre.utilisateur.id})
                .then(() => {
                    resolve();
                })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                });
            }
        }).finally(() => {
            store.dispatch(AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE, {id: store.state.accordsCadres.id});
            store.commit(AccordsCadresStoreMethods.RESET_MEMBRES_SELECTIONNES);
            store.dispatch(AccordsCadresStoreMethods.RECUPERER_MEMBRES_ENFCT_PROFIL, {profil: store.state.profilAccordCadre});
        });
    },
    [AccordsCadresStoreMethods.SUPPRIMER_MEMBRE]: (store: ActionContext<AccordsCadresState, RootState>, payload: {societeId: number}): Promise<AccordCadre> => {
        const accordCadreService = new ApiService<AccordCadre>('accordCadre/retirerSociete');
        return new Promise<AccordCadre>((resolve, reject) => {
            return accordCadreService.deleteWhere(`idAccordCadre=${store.state.accordsCadres.id}&idSociete=${payload.societeId}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        }).finally(() => {
            store.dispatch(AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE, {id: store.state.accordsCadres.id});
            store.dispatch(AccordsCadresStoreMethods.RECUPERER_MEMBRES_ENFCT_PROFIL, {profil: store.state.profilAccordCadre});
        });
    },
    [AccordsCadresStoreMethods.MODIFIER_ROLE_MEMBRE]: (store: ActionContext<AccordsCadresState, RootState>, payload: {roleId: number, societeId: number}): void => {
        const accordCadreEdit = new AccordsCadresAddRemoveSociete(store.state.accordsCadres.id, payload.societeId, payload.roleId);
        const accordCadreService = new ApiService<AccordsCadresAddRemoveSociete>('accordCadre/modifierRoleAccordCadre');
        accordCadreService.post(accordCadreEdit).then(() => {
            store.dispatch(AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE, {id: store.state.accordsCadres.id});
        });
    },
    [AccordsCadresStoreMethods.MODIFIER_INFOSGENERAL_ACCORD_CADRE]: (store: ActionContext<AccordsCadresState, RootState>): Promise<AccordsCadresGeneral> => {
        const accordCadreGeneral: AccordsCadresGeneral = MapAccordCadre.toGeneralAC(store.state.accordsCadres);
        const accordCadreService = new ApiService<AccordsCadresGeneral>('accordCadre/modifierAccordCadreGeneral');
        return new Promise<AccordCadre>((resolve, reject) => {
            return accordCadreService.put(accordCadreGeneral).then(() => {
                store.commit(AccordsCadresStoreMethods.SET_INFOS_GENERAL_MODIFIEES, true);
                store.commit(AccordsCadresStoreMethods.SET_EST_FICHIER_CONTRAT_CHOISI, false);
                store.dispatch(AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE, {id: store.state.accordsCadres.id});
                resolve();
            });
        });
    },
    [AccordsCadresStoreMethods.SET_INFOS_GENERAL_MODIFIEES_ACTION]: (store: ActionContext<AccordsCadresState, RootState>, payload: {estInfosGeneralModifiees: boolean}): void => {
        store.commit(AccordsCadresStoreMethods.SET_INFOS_GENERAL_MODIFIEES, payload.estInfosGeneralModifiees);
    }
};

/**
 * Export du module
 */
export const AccordsCadresStore: Module<AccordsCadresState, RootState> = {
    state: new AccordsCadresState(),
    getters,
    mutations,
    actions,
    namespaced: false
};
