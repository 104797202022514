import { Departement } from './Departement.model';
import { ObjetDemande } from './ObjetDemande.model';

/**
 * Définit l'ensemble des droits d'accès pour un utilisateur en dehors de son type de profil.
 *
 * @export
 * @class UtilisateurDroitsAcces
 */
export class DemandeUtilisateur {
  /**
   * Obtient ou définit si la personne à l'origine de la demande a déjà un compte Web CEE ou pas.
   *
   * @memberof DemandeUtilisateur
   */
  public compteWebCeeExistent: boolean;

  /**
   * Obtient ou définit l’émail de la personne à l'origine de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public email!: string;

  /**
   * Obtient ou définit le message de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public message: string;

  /**
   * Obtient ou définit le nom de la personne à l'origine de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public nom: string;

  /**
   * Obtient ou définit le numéro de dossier pour lequel la demande est faite.
   *
   * @memberof DemandeUtilisateur
   */
  public numeroDossier: string;

  /**
   * Obtient ou définit l'identifiant de l'objet de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public objetDemandeId: number;

  /**
   * Obtient ou définit le prénom de la personne à l'origine de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public prenom: string;

  /**
   * Obtient ou définit le SIRET de la demande (uniquement en cas de demande installateur).
   *
   * @memberof DemandeUtilisateur
   */
  public siret: string;

  /**
   * Obtient ou définit la société de la personne à l'origine de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public societe: string;

  /**
   * Obtient ou définit le numéro de téléphone de la personne à l'origine de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public telephone: string;

  /**
   * Obtient ou définit l'identifiant du département de la demande.
   *
   * @type {Departement}
   * @memberof DemandeUtilisateur
   */
  public departementId: number;

  /**
   * Obtient ou définit l'identifiant de la demande utilisateur.
   *
   * @type {Departement}
   * @memberof DemandeUtilisateur
   */
  public id: number;

  /**
   * Obtient ou définit le département de la demande.
   *
   * @type {Departement}
   * @memberof DemandeUtilisateur
   */
  public departement: Departement;

  /**
   * Obtient ou définit l'objet de la demande.
   *
   * @memberof DemandeUtilisateur
   */
  public objetDemande: ObjetDemande;
}
