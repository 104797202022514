import Vue from 'vue';
// import Router from 'vue-router';
import VueRouter, { Route } from 'vue-router';
import * as NProgress from 'nprogress';
import { routes } from './routes';

// Voir explications, avec les liens ci-dessous
/**
 * Preventing "NavigationDuplicated" errors in console in Vue-router >= 3.1.0
 * https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 * https://github.com/vuejs/vue-router/issues/2881
 * 
 */
const routerMethods = ['push', 'replace'];

routerMethods.forEach((method: string) => {
    const originalCall = (VueRouter.prototype as any)[method];
    (VueRouter.prototype as any)[method] = function (location: any, onResolve: any, onReject: any): Promise<any> {
        if (onResolve || onReject) {
            return originalCall.call(this, location, onResolve, onReject);
        }
        return (originalCall.call(this, location) as any).catch((err: any) => err);
    };
});


Vue.use(VueRouter);

export function createRouter(): VueRouter {
    const router = new VueRouter({
        mode: 'history',
        fallback: false,
        scrollBehavior: (to: Route, from: Route, savedPosition: any): any => (!!to.hash) ? { selector: to.hash } : savedPosition || { x: 0, y: 0 },
        routes,
    });
    return router;
}

export function configureHookRouter(router: VueRouter): void {
    // Before each route evaluates...
    router.beforeEach((routeTo, routeFrom, next) => [() => !!routeFrom && routeFrom.name && NProgress.start(), () => next()].forEach((item) => item()));
    router.beforeEach(Vue.prototype.$security.authRedirectGuard());

    // When each route is finished evaluating...
    router.afterEach((routeTo, routeFrom) => NProgress.done());
}
