import { AppDefaultState, IAppState } from './app/state';

export interface RootState {
    [key: string]: any;

    app: IAppState;
}

export const DefaultState: RootState = {
    app: {
        ...AppDefaultState(),
    },
};
