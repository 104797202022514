// https://gist.github.com/d-levin/18a75809ddd9b33366a3d28f6439c4ef
// https://github.com/PanJiaChen/vue-element-admin/blob/master/src/directive/permission/permission.js
import Vue, { VNode } from 'vue';
import {
    PermissionBindings,
    checkerPermission,
    checkerProfil,
    replaceElementWithComment,
    disableElement,
    check as checkCondition,
    vCloakClass,
} from '@/shared/helpers/PermissionsHelper';

// Liste des arguments.
const args: { [key: string]: string } = {
    SOME: 'some',
    EVERY: 'every'
};
/**
 * Check si les conditions sont vérifiées ou pas, sinon vide le nœud DOM.
 * @param el
 * @param binding
 * @param vnode
 * @param oldVnode
 */
const check = (el: HTMLElement,
    binding: PermissionBindings,
    vnode: VNode,
    callBackSet: ((profile: { [key: string]: string | string[] }) => string[]),
    hideElement: boolean) => {
    //
    const { arg, value, modifiers } = binding as {
        arg: string,
        value: string[],
        modifiers?: { [key: string]: boolean }
    };
    const isBoolean = (valToTest: any) => Boolean(valToTest) === valToTest;
    const negateResult = (modifiers && "not" in modifiers && isBoolean(modifiers.not) && modifiers.not) ?
                         true : false;

    checkCondition(
        arg,
        value,
        negateResult,
        callBackSet,
        () => {
            if (!!el && el.classList.contains(vCloakClass)) {
                el.classList.remove(vCloakClass);
                el.removeAttribute(vCloakClass);
            }
        },
        () => {
            if (!!el) {
                if (hideElement) {
                    replaceElementWithComment(vnode);
                }
                else {
                    disableElement(el, vnode);
                }
            }
        });
};
/**
 * Enregistrer une directive globale appelée `v-permission`
 * Méthode permettant de gérer les permissions.
 */
Vue.directive('permission', {
    bind: function (el: HTMLElement) {
        el.classList.add(vCloakClass);
        el.setAttribute(vCloakClass, '');
    },
    // Quand l'élément lié est inséré dans le DOM...
    inserted: function (el: HTMLElement, binding: PermissionBindings, vnode: VNode) {
        check(el, binding, vnode, checkerPermission, true);
    },
});

/**
 * Enregistrer une directive globale appelée `v-profil`.
 */
Vue.directive('profil', {
    bind: function (el: HTMLElement) {
        el.classList.add(vCloakClass);
        el.setAttribute(vCloakClass, '');
    },
    // Quand l'élément lié est inséré dans le DOM...
    inserted: function (el: HTMLElement, binding: PermissionBindings, vnode: VNode) {
       check(el, binding, vnode, checkerProfil, true);
    },
});

/**
 * Enregistrer une directive globale appelée `v-profil-modification`, fonctionnant comme la 'v-profil' mais mettant simplement les éléments non-autorisés en read-only.
 */
Vue.directive('profil-modification', {
    bind: function (el: HTMLElement) {
        el.classList.add(vCloakClass);
        el.setAttribute(vCloakClass, '');
    },
    // Quand l'élément lié est inséré dans le DOM...
    inserted: function (el: HTMLElement, binding: PermissionBindings, vnode: VNode) {
        check(el, binding, vnode, checkerProfil, false);
    },
});