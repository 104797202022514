import { BonusSecteur } from './BonusSecteur.model';
import { Tarif } from './Tarif.model';

/**
 * Modèle de données pour la liste des informations des bonus par secteur.
 */
export class TarifsEtBonus {

    public tarifsProfils: Tarif[];
    public bonusSecteurs: BonusSecteur[];

    constructor(tarifsProfil: Tarif[], bonusSecteurs: BonusSecteur[]) {
        this.tarifsProfils = tarifsProfil;
        this.bonusSecteurs = bonusSecteurs;
    }
}
