import { CreateElement } from 'vue';
import { VNodeData, VNodeChildren } from 'vue/types/vnode';
import { RouteConfig } from 'vue-router';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import { Permissions } from '@/shared/enums/Permissions.enum';

export const loggedInNavRoutes: RouteConfig[] = [
    {
        path: '/modification-profil',
        name: 'modification-profil',
        component: () => lazyLoadView(import('@/views/Compte/Profil/ModificationProfil')),
        meta: {
            icon: 'account_box',
            title: 'Mon profil',
            authRequired: true,
            authPermissions: [],
        },
        props: (route) => ({ userId: route.params.userId, code: route.params.code }),
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => lazyLoadView(import('@/views/Logout/Logout')),
        meta: {
            icon: 'logout',
            title: 'Se déconnecter',
            authRequired: false,
        },
    },
];

export const loggedOutNavRoutes: RouteConfig[] = [
    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@/views/Login/Login')),
        meta: {
            authRequired: false,
            icon: 'keyboard_tab',
            title: 'Se connecter',
        },
    },
];

export const persistentNavRoutes: RouteConfig[] = [
    {
        path: '/mes-dossiers',
        name: 'mes-dossiers',
        component: () => import('@/components/TableauDeBord/TableauDeBord.vue'),
        meta: {
            title: 'Tableau de bord',
            authRequired: true,
            authPermissions: [],
            visible: (profile: { isAdmin: boolean }) => profile.isAdmin !== undefined,
        },
    },
    {
        path: '/simulateur',
        name: 'simulateur',
        component: () => import('@/views/SimulateurOuDossier/SimulateurOuDossier'),
        props: () => ({ simulationOuDossier: SimulationOuDossier.Simulation }),
        meta: {
            title: 'Simulateur',
            authRequired: true,
            authPermissions: [],
            visible: () => true,
        },
    },
    {
        path: '/simulateur/:id',
        name: 'editer-simulation',
        component: () => import('@/views/SimulateurOuDossier/SimulateurOuDossier'),
        meta: {
            title: 'Éditer une simulation',
            authRequired: true,
            authPermissions: [],
            visible: () => false,
        },
        props: (route) => ({ simulationDossierId: +route.params.id || null, simulationOuDossier: SimulationOuDossier.Simulation }),
    },
    {
        path: '/prime-eco',
        name: 'prime-eco',
        component: () => lazyLoadView(import('@/views/PrimeEco/PrimeEco')),
        meta: {
            authRequired: false,
            authPermissions: [],
            title: 'La prime d\'économies d\'énergie',
            visible: () => true,
        },
    },
    {
        path: '/nos-offres',
        name: 'nos-offres',
        component: () => lazyLoadView(import('@/views/NosOffres/NosOffres')),
        meta: {
            authRequired: false,
            authPermissions: [],
            title: 'Nos offres',
            visible: () => true,
        },
    },
    {
        path: '/liens-utiles',
        name: 'liens-utiles',
        component: () => lazyLoadView(import('@/views/LiensUtiles/LiensUtiles')),
        meta: {
            authRequired: false,
            authPermissions: [],
            title: 'Liens utiles',
            visible: () => true,
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => lazyLoadView(import('@/views/Faq/Faq')),
        meta: {
            authRequired: false,
            authPermissions: [],
            title: 'Faq',
            visible: () => true,
        },
    },
    {
        path: '/admin',
        name: 'admin',
        redirect: { name: 'administration' },
        component: () => lazyLoadView(import('@/views/Administration/AdminHome/AdminHome')),
        meta: {
            authRequired: true,
            authPermissions: [Permissions.Admin_Consultation],
            title: 'Administration',
            visible: (profile: { isAdmin: boolean, isInterne: boolean }) => (profile !== null && !!profile.isInterne),
        },
    },
];

export const adminNavRoutes: RouteConfig[] = [
    {
        path: '/admin',
        component: () => import('@/views/Administration/Container.vue'),
        meta: {
            visible: () => true,
            title: 'Administration',
        },
        children: [
            {
                path: '',
                component: () => lazyLoadView(import('@/views/Administration/AdminHome/AdminHome')),
                name: 'administration',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    title: 'Dashboard',
                    icon: 'dashboard',
                },
            },
            {
                path: 'template-operations',
                component: () => import('@/views/Administration/Container.vue'),
                meta: {
                    to: 'gestion-template-operations',
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des templates d\'opérations',
                    icon: 'dashboard',
                },
                children: [
                    {
                        path: '',
                        component: () => lazyLoadView(import('@/views/Administration/TemplateOperations/List/TemplateOperationsList')),
                        name: 'gestion-template-operations',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            visible: () => false,
                        },
                    },
                    {
                        path: 'liste-champs',
                        component: () => lazyLoadView(import('@/views/Administration/TemplateOperations/Champs/Champs')),
                        name: 'gestion-champs',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_ChampsOperations],
                            visible: (profile: { isAdmin: boolean }) => (profile !== null && !!profile.isAdmin),
                            title: 'Gestion des champs',
                            icon: '',
                        }
                    },
                ]
            },
            {
                path: 'depots',
                component: () => import('@/views/Administration/Container.vue'),
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des dépôts et contrôles',
                    icon: 'dashboard',
                },
                children: [
                    {
                        path: 'gestion-fichiers-pncee',
                        component: () => lazyLoadView(import('@/views/Administration/GestionDepots/FichiersPncee/FichiersPncee')),
                        name: 'gestion-fichiers-pncee',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Génération des fichiers PNCEE',
                            visible: () => true,
                        },
                    },
                    {
                        path: 'creation-depot-pncee',
                        component: () => lazyLoadView(import('@/views/Administration/GestionDepots/CreationDepot/CreationDepot')),
                        name: 'creation-depot-pncee',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Création de dépôt PNCEE',
                            visible: () => true,
                        },
                    },
                    {
                        path: 'modification-statuts-operations',
                        component: () => lazyLoadView(import('@/views/Administration/GestionDepots/StatutsOperations/StatutsOperations')),
                        name: 'modification-statuts-operations',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Modification des statuts d\'opérations',
                            visible: () => true,
                        },
                    },
                ]
            },
            {
                path: 'echantillons',
                component: () => import('@/views/Administration/Container.vue'),
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des échantillons',
                    icon: 'dashboard',
                },
                children: [
                    {
                        path: 'generation-echantillons',
                        component: () => lazyLoadView(import('@/views/Administration/GestionEchantillons/GenerationEchantillons/GenerationEchantillons')),
                        name: 'generation-echantillons',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            visible: () => true,
                            title: 'Génération des échantillons',
                            icon: '',
                        }
                    },
                    {
                        path: 'recapitulatif-echantillons',
                        component: () => lazyLoadView(import('@/views/Administration/GestionEchantillons/RecapitulatifEchantillons/RecapitulatifEchantillons')),
                        name: 'recapitulatif-echantillons',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Récapitulatif de l\'échantillon',
                            visible: () => true,
                        },
                    },
                    {
                        path: 'modification-echantillons',
                        component: () => lazyLoadView(import('@/views/Administration/GestionEchantillons/ModificationEchantillons/ModificationEchantillons')),
                        name: 'modification-echantillons',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Modification des échantillons',
                            visible: () => true,
                        },
                    },
                    {
                        path: 'import-fichier-retour',
                        component: () => lazyLoadView(import('@/views/Administration/GestionEchantillons/ImportFichierRetour/ImportFichierRetour')),
                        name: 'import-fichier-retour',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            title: 'Import du fichier retour',
                            visible: () => true,
                        },
                    },
                ]
            },
            {
                path: 'template-operations/creer',
                component: () => lazyLoadView(import('@/views/Administration/TemplateOperations/Create/TemplateOperationsCreate')),
                name: 'creation-template-operations',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_TemplateOperations],
                    visible: () => false,
                    title: 'Création des templates d\'opérations',
                    icon: 'folder',
                },
            },
            {
                path: 'template-operations/:id/editer',
                component: () => lazyLoadView(import('@/views/Administration/TemplateOperations/Edit/TemplateOperationsEdit')),
                name: 'edition-template-operations',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    title: 'Edition des templates d\'opérations',
                    icon: 'folder',
                },
                props: (route) => ({ templateOperationId: route.params.id }),
            },
            {
                path: 'template-operations/:id/editer-template',
                component: () => lazyLoadView(import('@/views/Administration/TemplateOperations/EditTemplate/EditTemplate')),
                name: 'editiontemplate-template-operations',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    title: 'Edition des templates des templates d\'opérations',
                    icon: 'folder',
                },
                props: (route) => ({ idTemplateOperation: route.params.id }),
            },
            {
                path: 'gestion-dossiers',
                component: () => lazyLoadView(import('@/views/Administration/GestionDossier/GestionDossier')),
                name: 'gestion-dossiers',
                meta: {
                    visible: () => false,
                    title: 'Gestion des dossiers',
                    icon: 'folder',
                },
            },
            {
                path: 'gestion-utilisateurs',
                component: () => lazyLoadView(import('@/views/Administration/GestionUtilisateur/GestionUtilisateur')),
                name: 'gestion-utilisateurs',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des utilisateurs',
                    icon: 'people',
                },
            },
            {
                path: 'gestion-negociateurs',
                component: () => lazyLoadView(import('@/views/Administration/GestionNegociateurs/GestionNegociateurs')),
                name: 'gestion-negociateurs',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des negociateurs',
                    icon: 'fas fa-handshake',
                },
            },
            {
                path: 'gestion-negociateurs/creer',
                component: () => lazyLoadView(import('@/views/Administration/GestionNegociateurs/CreerNegociateur/CreerNegociateur')),
                name: 'creer-negociateur',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Users],
                    visible: () => false,
                    title: 'Créer un négociateur',
                    icon: 'people',
                },
            },
            {
                path: 'gestion-tarifs',
                component: () => lazyLoadView(import('@/views/Administration/GestionTarifs/GestionTarifs')),
                name: 'gestion-tarifs',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des tarifs',
                    icon: 'euro_symbol',
                },
            },
            {
                path: 'gestion-seuil-minimum-operation',
                component: () => lazyLoadView(import('@/views/Administration/GestionSeuilMinimumOperation/GestionSeuilMinimumOperation')),
                name: 'gestion-seuil-minimum-operation',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Seuil minimum des opérations',
                    icon: 'lock',
                },
            },
            {
                path: 'ajouter-seuil-minimum-operation',
                component: () => lazyLoadView(import('@/views/Administration/GestionSeuilMinimumOperation/CreerSeuil/CreerSeuilMinimumOperation')),
                name: 'ajouter-seuil-minimum-operation',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_SeuilMinimumOperations],
                    visible: () => false,
                    title: 'Ajouter seuil minimum des opérations',
                    icon: 'lock',
                    authProfils: ['admin'],
                },
            },
            {
                path: 'accords-cadres',
                component: () => lazyLoadView(import('@/views/Administration/AccordsCadres/AccordsCadres')),
                name: 'accords-cadres',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des accords-cadres',
                    icon: 'folder',
                },
            },
            {
                path: 'accords-cadres/creer',
                component: () => lazyLoadView(import('@/views/Administration/AccordsCadres/AjoutAccordCadre')),
                name: 'ajout-accord-cadre',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_AccordCadre],
                    visible: () => false,
                    title: 'Création d\'un accord-cadre',
                },
            },
            {
                path: 'accords-cadres/modifier/:id',
                component: () => lazyLoadView(import('@/views/Administration/AccordsCadres/EditAccordCadre')),
                name: 'edit-accord-cadre',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    title: 'Modifier d\'un accord-cadre',
                },
                props: (to) => ({ tab: to.meta.props.tab }),
                beforeEnter: (to, from, next) => {
                    to.meta.props = {
                        tab: from.name === 'ajout-accord-cadre' ? 1 : 0,
                    };
                    next();
                }
            },
            {
                path: 'contrats-apporteurs-affaires',
                component: () => lazyLoadView(import('@/views/Administration/GestionApporteursAffaires/GestionApporteursAffaires')),
                name: 'contrats-apporteurs-affaires',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    icon: 'fa fa-file-signature',
                    title: 'Gestion des contrats d\'apporteurs d\'affaires',
                },
            },
            {
                path: 'contrats-apporteurs-affaires/creer',
                component: () => lazyLoadView(import('@/views/Administration/GestionApporteursAffaires/CreerModifierContratApporteursAffaires')),
                name: 'creer-contrats-apporteurs-affaires',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_ContratApporteurAffaire],
                    visible: () => false,
                    icon: 'folder',
                    title: 'Creer un contrat d\'apporteur d\'affaires',
                },
            },
            {
                path: 'contrats-apporteurs-affaires/modifier/:id',
                component: () => lazyLoadView(import('@/views/Administration/GestionApporteursAffaires/CreerModifierContratApporteursAffaires')),
                name: 'edit-contrats-apporteurs-affaires',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    icon: 'folder',
                    title: 'Gestion des contrats d\'apporteurs d\'affaires',
                },
            },
            {
                path: 'gestion-utilisateurs/creer/:profil',
                component: () => lazyLoadView(import('@/views/Administration/GestionUtilisateur/CreerUtilisateur/CreerUtilisateur')),
                name: 'creer-utilisateur-avec-profil',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Users],
                    visible: () => false,
                    title: 'Creer un utilisateurs',
                    icon: 'people',
                },
            },
            {
                path: 'gestion-utilisateurs/creer',
                component: () => lazyLoadView(import('@/views/Administration/GestionUtilisateur/CreerUtilisateur/CreerUtilisateur')),
                name: 'creer-utilisateur',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Users],
                    visible: () => false,
                    title: 'Créer un utilisateur',
                    icon: 'people',
                },
            },
            {
                path: 'gestion-utilisateurs/modifier/:id',
                component: () => lazyLoadView(import('@/views/Administration/GestionUtilisateur/ModifierUtilisateur/ModifierUtilisateur')),
                name: 'modifier-utilisateur',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => false,
                    title: 'Créer un utilisateur',
                    icon: 'people',
                },
                props: (route) => ({ userId: route.params.id }),
            },
            {
                path: 'gestion-numeroFournisseursVesta',
                component: () => lazyLoadView(import('@/views/Administration/GestionNumeroFournisseursVesta/GestionNumeroFournisseursVesta')),
                name: 'gestion-numeroFournisseursVesta',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des numéros fournisseurs Vesta',
                    icon: 'edit',
                },
            },
            {
                path: 'gestion-prescripteurs',
                component: () => lazyLoadView(import('@/views/Administration/GestionPrescripteurs/GestionPrescripteurs')),
                name: 'gestion-prescripteurs',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des prescripteurs',
                    icon: 'edit',
                },
            },
            {
                path: 'gestion-demandes-utilisateurs',
                component: () => lazyLoadView(import('@/views/Administration/GestionDemandesUtilisateurs/GestionDemandesUtilisateurs')),
                name: 'gestion-demandes-utilisateurs',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion des prises de contact utilisateur',
                    icon: 'contact_mail',
                },
            },
            {
                path: 'reporting',
                component: () => import('@/views/Administration/Container.vue'),
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Reporting',
                    icon: 'dashboard',
                },
                children: [
                    {
                        path: 'reporting',
                        component: () => lazyLoadView(import('@/views/Administration/Reporting/Reporting')),
                        name: 'reporting',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            visible: () => true,
                            title: 'Export de base',
                            icon: '',
                        },
                    },
                    {
                        path: 'stats-cp',
                        component: () => lazyLoadView(import('@/views/Administration/Reporting/StatsCP/StatsCP')),
                        name: 'stats-cp',
                        meta: {
                            authRequired: true,
                            authPermissions: [Permissions.Admin_Consultation],
                            visible: () => true,
                            title: 'Statistiques coup de pouce',
                            icon: '',
                        }
                    },
                ]
            },
            {
                path: 'gestion-du-cache',
                component: () => lazyLoadView(import('@/views/Administration/Cache/Cache')),
                name: 'gestion-du-cache',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_DashBoard, Permissions.Admin_Menu, Permissions.Admin_Consultation],
                    visible: () => true,
                    title: 'Gestion du cache',
                    icon: 'dashboard',
                },
            },
            {
                path: 'valide-contrat-partenariat/:id',
                component: () => lazyLoadView(import('@/views/Administration/ContratPartenariat/ValidationContratPartenariat')),
                name: 'valide-contrat-partenariat',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Users],
                    visible: () => false,
                    title: 'Valider le contrat de partenariat',
                    icon: 'verified_user',
                },
                props: (route) => ({ userId: route.params.id, validationContrat: true }),
            },
            {
                path: 'rejete-contrat-partenariat/:id',
                component: () => lazyLoadView(import('@/views/Administration/ContratPartenariat/ValidationContratPartenariat')),
                name: 'rejete-contrat-partenariat',
                meta: {
                    authRequired: true,
                    authPermissions: [Permissions.Admin_Users],
                    visible: () => false,
                    title: 'Rejeter le contrat de partenariat',
                    icon: 'error',
                },
                props: (route) => ({ userId: route.params.id, validationContrat: false }),
            },
        ],
    },
];

export const routes: RouteConfig[] = [
    ...persistentNavRoutes,
    ...loggedInNavRoutes,
    ...loggedOutNavRoutes,
    ...adminNavRoutes,

    {
        path: '/',
        name: 'home',
        component: require('@/views/Home/Home').default,
        meta: {
            authRequired: false,
            authPermissions: [],
            title: ' ',
        },
    },
    {
        path: '/dossier',
        name: 'dossier',
        component: () => import('@/views/SimulateurOuDossier/SimulateurOuDossier'),
        props: () => ({ simulationOuDossier: SimulationOuDossier.Dossier }),
        meta: {
            title: 'Dossier',
            authRequired: true,
            authPermissions: [],
            visible: () => false,
        },
    },
    {
        path: '/dossier/:id',
        name: 'editer-dossier',
        component: () => import('@/views/SimulateurOuDossier/SimulateurOuDossier'),
        meta: {
            title: 'Éditer un dossier',
            authRequired: true,
            authPermissions: [],
            visible: () => false,
        },
        props: (route) => ({ simulationDossierId: route.params.id || null, simulationOuDossier: SimulationOuDossier.Dossier }),
    },
    {
        path: '/dispositif-cee',
        name: 'dispositif-cee',
        component: () => lazyLoadView(import('@/views/DispositifCEE/DispositifCEE')),
        meta: {
            authRequired: false,
            authPermissions: [],
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => lazyLoadView(import('@/views/Contact/Contact')),
        props: (route) => ({ estInstallateur: route.params.estInstallateur }),
        meta: {
            authRequired: true,
            authPermissions: [Permissions.Admin_Users],
            visible: () => false,
        },
    },
    {
        path: '/choix-profil',
        name: 'choix-profil',
        component: () => lazyLoadView(import('@/views/Compte/ChoixProfil/ChoixProfil')),
        meta: {
            authRequired: true,
            authPermissions: [Permissions.Admin_Users],
            visible: () => false,
        },
    },
    {
        path: '/inscription/:codeProfil',
        name: 'inscription',
        component: () => lazyLoadView(import('@/views/Compte/Inscription/Inscription')),
        props: (route) => ({ codeProfil: route.params.codeProfil }),
        meta: {
            authRequired: true,
            authPermissions: [Permissions.Admin_Users],
            visible: () => false,
        },
    },
    {
        path: '/auth/confirm-email',
        name: 'confirm-email',
        component: () => lazyLoadView(import('@/views/Compte/ConfirmEmail/ConfirmEmail')),
        meta: {
            authRequired: false,
            authPermissions: [],
            icon: 'account_box',
            title: 'Activation de compte',
        },
        props: (route) => ({ userId: route.query.userId, code: route.query.code }),
    },
    {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        component: () => lazyLoadView(import('@/views/Compte/ForgotPassword/ForgotPassword')),
        meta: {
            authRequired: false,
            authPermissions: [],
            icon: 'account_box',
            title: 'Réinitialisation du mot de passe',
        },
    },
    {
        path: '/auth/reset-password',
        name: 'reset-password',
        component: () => lazyLoadView(import('@/views/Compte/ResetPassword/ResetPassword')),
        meta: {
            authRequired: false,
            authPermissions: [],
            icon: 'account_box',
            title: 'Réinitialisation du mot de passe',
        },
        props: (route) => ({ userId: route.query.userId, code: route.query.code }),
    },
    {
        path: '/auth/signin-callback',
        component: () => lazyLoadView(import('@/views/Callback/SigninCallback')),
        meta: {
            authRequired: false,
            authPermissions: [],
        },
    },
    {
        path: '/auth/silent-renew',
        component: () => lazyLoadView(import('@/views/Callback/SilentRenewCallback')),
        meta: {
            authRequired: false,
            authPermissions: [],
        },
    },
    {
        path: '/error',
        alias: '*',
        component: require('@/views/Error.vue').default,
        props: true,
        meta: {
            authRequired: false,
            authPermissions: [],
        },
    },
    {
        path: '*',
        name: 'error',
        component: require('@/views/Error.vue').default,
        meta: {
            authRequired: false,
            authPermissions: [],
        },
        props: (route) => ({ status: route.params.status, routeToFullPath: route.params.routeToFullPath }),
    },
];

function lazyLoadView(AsyncView: any /*view: string, */, ext?: string) {
    const AsyncHandler = () => ({
        component: AsyncView,
        loading: require('@/views/_loading.vue').default,
        delay: 400,
        error: require('@/views/_timeout.vue').default,
        timeout: 10000,
    });

    return Promise.resolve({
        functional: true,
        render: (h: CreateElement, props: any): any => {
            const { data, children } = props;
            // Transparently pass any props or children to the view component.
            return h(AsyncHandler, data as VNodeData, children as VNodeChildren);
        },
    });
}
