
/**
 * Définit l'ensemble des droits d'accès pour un utilisateur en dehors de son type de profil. 
 *
 * @export
 * @class UtilisateurDroitsAcces
 */
export class UtilisateurDroitsAcces {

    /**
     * L'identifiant de l'utilisateur.
     *
     * @type {number}
     * @memberof UtilisateurDroitsAcces
     */
    public userId: number;

    /**
     * Définit si un utilisateur a le droit d'accéder a la Bonification CPE.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesBonificationCpe: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder a la Bonification SME.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesBonificationSme: boolean;
    /**
     * Définit si un utilisateur a le droit d'accéder à la coche PNAQ.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesCochePnaq: boolean;
    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Coup de pouce.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsCoupDePouce: boolean;
    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Réseau.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsReseau: boolean;
    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Transport.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsTransport: boolean;

    /**
     * Définit si un utilisateur a le droit d'accéder aux opérations Non standard.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesOperationsNonStandard: boolean;

    /**
     * Définit si le seuil minimum pour une opération pour est désactivé pour un utilisateur.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public isSeuilMinimumOperationDesactive: boolean;

    /**
     * Définit si l'utilisateur peut créer des simulations ou des dossiers.
     *
     * @type {boolean}
     * @memberof UtilisateurDroitsAcces
     */
    public hasAccesCreationSimulationDossier: boolean;

    /**
     * Crée une instance de UtilisateurDroitsAcces.
     * @memberof UtilisateurDroitsAcces
     */
    constructor();
    constructor(
        userId: number,
        hasAccesBonificationCpe: boolean,
        hasAccesBonificationSme: boolean,
        hasAccesCochePnaq: boolean,
        hasAccesOperationsCoupDePouce: boolean,
        hasAccesOperationsReseau: boolean,
        hasAccesOperationsTransport: boolean,
        hasAccesOperationsNonStandard: boolean,
        isSeuilMinimumOperationDesactive: boolean,
        hasAccesCreationSimulationDossier: boolean);
    constructor(
        userId?: number,
        hasAccesBonificationCpe?: boolean,
        hasAccesBonificationSme?: boolean,
        hasAccesCochePnaq?: boolean,
        hasAccesOperationsCoupDePouce?: boolean,
        hasAccesOperationsReseau?: boolean,
        hasAccesOperationsTransport?: boolean,
        hasAccesOperationsNonStandard?: boolean,
        isSeuilMinimumOperationDesactive?: boolean,
        hasAccesCreationSimulationDossier?: boolean) {
        this.userId = userId || -1;
        this.hasAccesBonificationCpe = hasAccesBonificationCpe;
        this.hasAccesBonificationSme = hasAccesBonificationSme;
        this.hasAccesCochePnaq = hasAccesCochePnaq;
        this.hasAccesOperationsCoupDePouce = hasAccesOperationsCoupDePouce;
        this.hasAccesOperationsTransport = hasAccesOperationsTransport;
        this.hasAccesOperationsReseau = hasAccesOperationsReseau;
        this.hasAccesOperationsNonStandard = hasAccesOperationsNonStandard;
        this.isSeuilMinimumOperationDesactive = isSeuilMinimumOperationDesactive;
        this.hasAccesCreationSimulationDossier = hasAccesCreationSimulationDossier;
    }
}
