import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { HttpClient } from '@/shared/HttpClient';
import { Result } from '@/shared/models';

/**
 * Service générique exposant les méthodes de bases d'appel à l'API.
 */
export class ApiService<T>  {
    // Endpoint d'API sur lequel requêter.
    private endpoint: string;

    /**
     * Constructeur.
     * @param endpoint nom du endpoint de l'API.
     */
    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    /**
     * Récupère toutes les entités de type T.
     * @returns Réponse axios ActionResult contenant toutes les entités de type T.
     */
    public getAll(): AxiosPromise<Result<T[]>> {
        return HttpClient.axios.get<Result<T[]>>(this.endpoint);
    }

    /**
     * Récupère l'entité de type T d'identifiant "id".
     * @param id Identifiant de l'entité.
     * @returns Réponse axios ActionResult contenant l'entité T d'identifiant "id".
     */
    public get(id: number): AxiosPromise<Result<T>> {
        return HttpClient.axios.get<Result<T>>(`${this.endpoint}/${id}`);
    }

    /**
     * Récupère une liste d'entité de type T selon des critères de recherche.
     * @param queryString Les critères de recherche sous forme de query string.
     * @returns Réponse axios ActionResult contenant la liste d'entité de type T.
     */
    public getWhere(queryString: string): AxiosPromise<Result<T[]>> {
        return HttpClient.axios.get<Result<T[]>>(`${this.endpoint}?${queryString}`);
    }


    /**
     * Récupère une liste d'entité de type T selon des critères de recherche.
     * @param queryString Les critères de recherche sous forme de query string.
     * @returns Réponse axios ActionResult contenant la liste d'entité de type T.
     */
    public getWhereWithConfig(queryString: string, config: AxiosRequestConfig): AxiosPromise<Result<T[]>> {
        return HttpClient.axios.get<Result<T[]>>(`${this.endpoint}?${queryString}`, config);
    }

    /**
     * Récupère une entité selon les critères de recherche.
     * @param queryString Les critères de recherche sous forme de query string.
     * @returns Réponse axios contenant l'entité.
     */
    public getWhereSingle(queryString: string): AxiosPromise<T> {
        return HttpClient.axios.get<T>(`${this.endpoint}?${queryString}`);
    }

    /**
     * Créée un entité en base.
     * @param data L'entité.
     * @returns Réponse axios contenant l'entité T
     */
    public post(data: T): AxiosPromise<Result<T>> {
        return HttpClient.axios.post<Result<T>>(this.endpoint, data);
    }

    public postWhere(queryString: string): AxiosPromise<Result<T>> {
        return HttpClient.axios.post<Result<T>>(`${this.endpoint}?${queryString}`);
    }
    /**
     * Modifie une entité en base.
     * @param data L'entité.
     * @returns Réponse axios contenant l'entité T
     */
    public put(data: T): AxiosPromise<Result<T>> {
        return HttpClient.axios.put<Result<T>>(this.endpoint, data);
    }

    /**
     * Supprime l'entité de type T d'identifiant "id".
     * @param id Identifiant de l'entité.
     */
    public delete(id: number): AxiosPromise {
        return HttpClient.axios.delete(`${this.endpoint}/${id}`);
    }
    public deleteWhere(queryString: string): AxiosPromise {
        return HttpClient.axios.delete(`${this.endpoint}?${queryString}`);
    }

}
