













































































































import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import VueAsyncComputed from 'vue-async-computed';
import { createDecorator, VueDecorator } from 'vue-class-component';
import { persistentNavRoutes, loggedInNavRoutes, loggedOutNavRoutes, adminNavRoutes } from '@/router/routes';
import NavBarRoutes from './NavbarRoutes.vue';
import Confirm from '@/components/Confirm.vue';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { IAsyncComputedProperty } from '@/plugins/AsyncComputed';
Vue.use(VueAsyncComputed);


type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export function AsyncComputed<T>(computedOptions?: Omit<IAsyncComputedProperty<T>, 'get'>): VueDecorator {
    return createDecorator((options, key) => {
        options.asyncComputed = options.asyncComputed || {};
        const method = options.methods![key];
        options.asyncComputed[key] = {
            get: method,
            ...computedOptions,
        } as IAsyncComputedProperty<T>;
        delete options.methods![key];
    });
}


@Component({
    name: 'Navbar',
    components: {
        NavBarRoutes,
        Confirm
    },
})
export default class Navbar extends Vue {
    public readonly persistentNavRoutes: RouteConfig[] = persistentNavRoutes;
    public readonly loggedOutNavRoutes: RouteConfig[] = loggedOutNavRoutes;
    public readonly loggedInNavRoutes: RouteConfig[] = loggedInNavRoutes;
    public readonly adminNavRoutes: RouteConfig[] = adminNavRoutes;

    /**
     * Détermine si l'utilisateur a fait défiler l'écran ou non.
     */
    public isScrolled = false;

    /**
     * Détermine si l'utilisateur a atteint le bas de l'écran.
     */
    public isFullyScrolled = false;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string; width?: number; zIndex?: number }) => Promise<boolean>);
        };
    };

    @AsyncComputed()
    public async filterPersistentNavRoutes(): Promise<RouteConfig[]> {
        const profile: { isAdmin: boolean } = (await this.userProfile) as unknown as ({ isAdmin: boolean }) || { isAdmin: undefined };
        return this.persistentNavRoutes.filter((itemRoute: RouteConfig) => {
            const meta = itemRoute.meta || {};
            const visible = meta.visible || ((itemProfile: { isAdmin: boolean }) => !!itemProfile && false);
            return (!!itemRoute && visible(profile));
        });
    }

    // Promesse pour savoir si le user est loggué.
    @Getter(AuthStoreMethods.IS_CURRENT_USER_LOGGED_IN)
    public isCurrentUserLoggedIn: Promise<boolean>;

    // Promesse pour récupérer le profil du user.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    // Promesse pour savoir si le user est interne.
    @Getter(AuthStoreMethods.IS_CURRENT_USER_INTERNE)
    public getIsInterne: boolean;

    @AsyncComputed()
    public async loggedIn(): Promise<any> {
        return this.isCurrentUserLoggedIn;
    }

    @AsyncComputed()
    public async userProfile(): Promise<any> {
        return this.getUserProfile;
    }

    @AsyncComputed()
    public async isInterne(): Promise<boolean> {
        return this.getIsInterne;
    }

    /**
     * Ajoute le listeneur sur le défilement.
     */
    public created() {
        this.onScrolled();
        window.addEventListener('scroll', this.onScrolled);
    }

    /**
     * Nettoie le listeneur sur le défilement.
     */
    public destroyed() {
        window.removeEventListener('scroll', this.onScrolled);
    }

    /**
     * Détermine si l'utilisateur a fait défiler l'écran ou non.
     */
    public onScrolled(): void {
        this.isScrolled = window.scrollY > 24;

        // Détermine si l'utilisateur est arrivé en bas de l'écran.
        // On met une petite zone de tolérance pour éviter un effet de clignottement.
        const threshold = this.isFullyScrolled ? 48 : 0;
        this.isFullyScrolled = document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 120 + threshold;
    }

    public getPrenomNom(userProfile: any): string {
        // Dans la mesure du possible, on va chercher le prénom et le nom qui sont censé être dans les claims.
        if (!userProfile.given_name || !userProfile.family_name) {
            // Si on trouve pas on affiche le login.
            return userProfile.name;
        } else {
            return `${userProfile.given_name} ${userProfile.family_name}`;
        }
    }

    /**
     * Effectue la redirection vers la nouvelle url, en forçant le rafraîchissement si nécessaire.
     * @param route Route à atteindre.
     */
    private redirection(route: RouteConfig): void {

        // Si on était déjà sur la page actuelle, on force le rafraichissement pour que le routeur voit le changement d'état.
        if (route.name === this.$router.currentRoute.name) {

            // Petit hack : force le rafraichissement en passant par une route intermédiaire.
            // Une meilleure solution serait de passer par un guard au niveau du composant et de réinitialiser son état, mais c'est plus compliqué/trop long à mettre en place.
            this.$router.push('/');
        }

        // Dans les autres cas, la redirection est basique.
        // https://github.com/vuejs/vue-router/issues/2881
        this.$router.push(route).catch(() => { /*Laisse vide express */ });
    }
}
