

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'CeeDrawerItem',
    inheritAttrs: false,
})
export default class CeeDrawerItem extends Vue {

    @Prop({ default: false, required: false }) public visible!: boolean;
    @Prop({ default: (void 0) }) public readonly avatar!: string;
    @Prop({ default: (void 0) }) public readonly avatarColor!: string;
    @Prop({ default: (void 0) }) public readonly text!: string;

    @Prop({ default: (void 0) }) public readonly href!: string;

    @Prop({ default: '' }) public readonly name!: string;
    @Prop({ default: false }) public readonly icon!: [boolean, string];

    @Prop({ default: '' }) public readonly chip!: string;
    @Prop({ default: false }) public readonly noMarkdown!: string;
    @Prop({ default: (void 0) }) public readonly subtext!: string;
    @Prop({ default: (void 0) }) public readonly to!: string;

    @Prop({ default: () => ({ text: '', group: '', meta: {}, children: new Array<object>() }) }) public readonly item!: object;
    @Prop({ default: false }) public readonly subGroup!: boolean;

    public genChip(item: any): string {
        if (item.new) { return 'new'; }
        if (item.updated) { return 'updated'; }
        if (item.deprecated) { return 'deprecated'; }
        return '';
    }

    public get chipColor() {
        if (this.chip === 'new') {
            return 'primary';
        }
        if (this.chip === 'updated') {
            return 'warning';
        }
        if (this.chip === 'deprecated') {
            return 'black';
        }
        return '';
    }
    public get customAttrs() {
        const attrs = {
            ...this.$attrs,
        };

        if (this.href) {
            attrs.target = '_blank';
            attrs.rel = 'noopener';
            attrs.href = this.href;
        }
        return attrs;
    }
    public get path() {
        const meta = ((this.item as any) || {}).meta;
        if (!this.to) {
            return this.to || meta.to;
        }
        return { name: `${this.to}` };
    }
    public get showInUi(): boolean {
        if (this.visible === null || this.visible === (void 0)) {
            return false;
        }
        return this.visible;
    }
}
