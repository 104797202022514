

import Vue, { CreateElement, VNode } from 'vue';
import Bus from '@/components/Bus';

export default Vue.extend({
    components: {},
    props: {
        capture: {
            default: false,
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            alerts: null,
            notificationsFloatingsEvents: null,
            notificationsFixedEvents: null,
        };
    },
    created() {
        Bus.$on('notifications-alerts-events', (alerts: any) => {
            this.$emit('alerts', alerts);
        });
        Bus.$on('notifications-floatings-events', (notifications: any) => {
            this.$emit('notificationsFloatingsEvents', notifications);
        });
        Bus.$on('notifications-fixed-events', (notifications: any) => {
            this.$emit('notificationsFixedEvents', notifications);
        });
        Bus.$on('httpErrorComponent', (params: any) => {
            this.$emit('httpErrorComponent', params);
        });
    },
    methods: {
        resetSnackbar(): void {
            this.$emit('resetSnackbar');
        },
    },
    errorCaptured() {
        this.$emit('showErrorFloatingBar', null);
        // Optionally capture errors.
        if (this.capture) {
            return false;
        } else {
            return true;
        }
    },
    render(h: CreateElement): VNode {
        if (this.$scopedSlots && this.$scopedSlots.default) {
            return h('div',
                this.$scopedSlots.default({
                    alerts: this.alerts,
                    notificationsFloatingsEvents: this.notificationsFloatingsEvents,
                    notificationsFixedEvents: this.notificationsFixedEvents,
                }),
            );
        } else {
            return h('div', {}, 'this default value is returned if slot is not defined');
        }
    },
});
