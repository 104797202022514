/**
 * Énumération des secteurs d'opérations.
 */
export enum Secteurs {
    Transport = 'Transport',
    Reseaux = 'Reseaux',
    Residentiel = 'Residentiel',
    Industrie = 'Industrie',
    Agriculture = 'Agriculture',
    Tertiaire = 'Tertiaire',
    NonStandard = 'NonStandard'
}

/**
 * Énumération des secteurs d'opérations avec leur Id comme valeur.
 */
export enum SecteurAsNumber {
    Transport = 1,
    Reseaux = 2,
    Residentiel = 3,
    Industrie = 4,
    Agriculture = 5,
    Tertiaire = 6,
    NonStandard = 7
}
