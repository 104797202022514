/**
 * Enumération de tous les types de valeur référentielle.
 * */
export enum TypeValeurReferentielle {
    Arrete = 'Arrete',
    AttestationHonneur = 'AttestationHonneur',
    ClientRepresente = 'ClientRepresente',
    Civilite = 'Civilite',
    Departement = 'Departement',
    FormeJuridique = 'FormeJuridique',
    ObjetDemande = 'ObjetDemande',
    ParcoursUtilisateur = 'ParcoursUtilisateur',
    RoleAccordCadre = 'RoleAccordCadre',
    Secteur = 'Secteur',
    StatutDossier = 'StatutDossier',
    StatutSimulation = 'StatutSimulation',
    TemplateChamp = 'TemplateChamp',
    TemplateTypeBloc = 'TemplateTypeBloc',
    TemplateTypeChamp = 'TemplateTypeChamp',
    TypeBeneficiaire = 'TypeBeneficiaire',
    TypeCee = 'TypeCee',
    TypeCompteSyndic = 'TypeCompteSyndic',
    TypeContribution = 'TypeContribution',
    TypeInstallateur = 'TypeInstallateur',
    TypeVoie = 'TypeVoie',
    StatutCommercial = 'StatutCommercial',
    StatutPncee = 'StatutPncee',
    AbandonRefus = 'AbandonRefus',
    TypeDocument = 'TypeDocument',
    RetourOrganismeControle = "RetourOrganismeControle",
    StatutEchantillon = "StatutEchantillon",
    EtatTraitement = "EtatTraitement",
    TypeControle = "TypeControle",
}
