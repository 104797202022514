/**
 * Énumération des statuts de simulation.
 */
export enum StatutSimulation {
    // Aucun statut.
    None = 0,
    // Statut principal d'une simulation avant d'engendrer un dossier (hors apporteurs d'affaires) ou d'être supprimée.
    EN_SIMULATION = 1,
    // Statut spécifique aux simulations réalisées par les apporteurs d'affaires.
    EN_ATTENTE_VALORISATION_ENGIE = 2,
    // Statut spécifique aux simulations réalisées par les apporteurs d'affaires.
    EN_ATTENTE_CONVENTION = 3,
    // Le simulation ne sont pas supprimable définitivement pas les utilisateurs (quelque soit leur rôle).
    SUPPRIMEE = 4
}
