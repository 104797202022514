import { Adresse } from './Adresse.model';

export class SiteTravaux {
    public id: number = 0;
    public libelle: string = '';
    public adresseId: number = 0;
    public adresse: Adresse = new Adresse();

    constructor();
    constructor(id: number, libelle: string, adresseId: number, adresse: Adresse);
    constructor(id?: number, libelle?: string, adresseId?: number, adresse?: Adresse) {
         this.id = id || 0;
         this.libelle =  libelle || '';
         this.adresseId = adresseId || 0;
         this.adresse = adresse || new Adresse();
    }
}
