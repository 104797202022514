var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{'header-container': true, scrolled: _vm.isScrolled, 'fully-scrolled': _vm.isFullyScrolled},attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name:'home' }}},[_c('v-img',{attrs:{"src":require('@/assets/img/home-page/logo-engie-blue.png')}})],1),_c('v-spacer'),_c('v-toolbar-items',[_c('NavBarRoutes',{attrs:{"routes":_vm.filterPersistentNavRoutes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
return _c('v-btn',{class:{'admin-button': (route.name === 'admin' && _vm.$router.currentRoute.name !== 'administration')},attrs:{"d-flex":"","text":"","to":{ name: route.name },"id":route.name}},[_vm._v(" "+_vm._s(route.title)+" "),_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.redirection(route)}}})])}}])}),_c('v-spacer'),(_vm.loggedIn)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","max-height":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"bouton-compte","aria-label":('Vuetify.AppToolbar.ecosystem'),"text":"","tag":"a"}},menu),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("account_circle")]),_c('span',{staticClass:"hidden-md-and-down",attrs:{"id":"bouton-compte-content"},domProps:{"textContent":_vm._s((_vm.loggedIn && _vm.userProfile) ? _vm.getPrenomNom(_vm.userProfile) : '    ' )}}),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}],null,false,3860149424)},[(_vm.loggedIn)?_c('v-list',{attrs:{"light":"","dense":""}},_vm._l((_vm.loggedInNavRoutes),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":!item.path ? { name: item.name } : null,"href":item.path,"ripple":"ripple","disabled":item.disabled,"target":item.target,"rel":"noopener","id":item.name}},[(item.meta.icon)?_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.meta.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.meta.title)+" ")])],1)],1)}),1):_vm._e()],1):_c('v-btn',{staticClass:"filled-button",attrs:{"text":""}},[_c('router-link',{attrs:{"to":{ name: 'login' },"id":"login"}},[_vm._v(" Se connecter "),_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","max-height":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({staticClass:"sandwich-icon",attrs:{"tag":"a","text":""}},menu),[_c('v-icon',[_vm._v("menu")])],1)]}}])},[_c('NavBarRoutes',{attrs:{"routes":_vm.filterPersistentNavRoutes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
return _c('v-list',{attrs:{"light":"","dense":""}},[_c('v-list-item',{key:route.title,attrs:{"ripple":"ripple","rel":"noopener"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.redirection(route)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(route.title)+" ")])],1)],1)],1)}}])})],1)],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }