/**
 * Énumération des valeurs possible pour les statuts de paiement.
 */
export enum StatutPaiement {
    NonConfirmeTrustpair = 1,
    EnCoursTrustpair = 2,
    DefavorableTrustpair = 3,
    FavorableTrustpair = 4,
    Rejete = 5,
    EnCoursValidationVesta = 6,
    ErreurPaiement = 7,    
    Valide = 8,
}

export enum StatutPaiementExterne {
    NonConfirmeTrustpair = "Vérification du RIB en cours",
    EnCoursTrustpair = "Vérification du RIB en cours",
    DefavorableTrustpair = "RIB non confirmé, paiement bloqué",
    FavorableTrustpair = "RIB confirmé, en attente de paiement",
    Rejete = "Paiement rejeté",
    EnCoursValidationVesta = "Paiement en cours de traitement",
    ErreurPaiement = "Paiement bloqué",
    Valide = "Paiement validé",
}