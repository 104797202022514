



















import Vue from 'vue';
import Notifications from 'vue-notification';
import CeeLayoutDefault from '@/views/Layouts/CeeLayoutDefault.vue';
import CeeLayoutDefaultAdmin from '@/views/Layouts/CeeLayoutDefaultAdmin.vue';
import FrameError from '@/components/FrameError.vue';
import VueI18n from 'vue-i18n';
import { merge, cloneDeep } from 'lodash-es';

Vue.use(Notifications);
Vue.component('CeeLayout', () => import('@/views/Layouts/CeeLayout'));

const groupBy = (items: any, key: any) => items.reduce(
    (result: any, item: any) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);
// tslint:disable-next-line:no-console
const logger = console;
export default Vue.extend({
    name: `App`,
    components: {
        CeeLayoutDefault,
        CeeLayoutDefaultAdmin,
        FrameError,
    },
    metaInfo: {
        title: 'Prime d\'économies d\'énergie',
        titleTemplate: '%s - ENGIE Entreprises et Collectivités',
    },
    data() {
        return {
            layout: `CeeLayoutDefault`,
        };
    },
    computed: {
        getLayout: {
            get(): string {
                return this.layout;
            },
            set(newValue: string): void {
                this.layout = newValue;
            },
        },
    },
    mounted(): void {
        Vue.config.errorHandler = (err, vm, info) => {
            logger.log('trace start');
            logger.log('Exception: ', err);
            logger.log('vm', vm);
            logger.log('info', info);
            logger.log('trace end');
        };
        window.onerror = (message, source, lineno, colno, error) => {
            logger.log('trace start');
            logger.log('message: ', message);
            logger.log('source', source);
            logger.log('lineno', lineno);
            logger.log('colno', colno);
            logger.log('Exception: ', error);
            logger.log('trace end');
        };
    },
    methods: {
        showAlerts(alerts: any): void { this.doNotificationsAlerts(alerts); },
        showNotificationsFloatingsEvents(error: any): void {
            console.error("Méthode non-implémentée");
        },
        showNotificationsFixedEvents(error: any): void {
            console.error("Méthode non-implémentée");
        },
        doNotificationsAlerts(messages: any): void {
            const groups = groupBy(messages, 'messageLevel');
            if (groups) {
                for (const [key, value] of Object.entries(groups)) {
                    const text = (value as []).map((val: any) => {
                        return this.obtenirTraduction(val.messageString);
                    }).join('<br />');
                    if (text) {
                        this.$notify({
                            group: 'notifications-alerts',
                            title: ``,
                            text,
                            type: key.toLowerCase(),
                            duration: 10000
                        });
                    }
                }
            }
        },
        doHttpErrorComponent(params: any): void {

            const routeParams = cloneDeep(params.routeParams);
            routeParams.params = merge(routeParams.params, { 0: this.$router.currentRoute.fullPath });

            this.$router.replace({
                name: 'error',
                params: {
                    // '0': this.$router.currentRoute.fullPath,
                    'status': `${routeParams.params.status || 404}`,
                },
                replace: true,
            }).catch(() => { /*Laisse vide express */ });
        },
        /**
         * Obtient la traduction à partir d'un clef.
         * @param key La chaîne à traduire
         */
        obtenirTraduction(key: string): string | VueI18n.TranslateResult {
            if (this.$te(`view.${key}`)) {
                return this.$t(`view.${key}`);
            } else if (this.$te(`validation.${key}`)) {
                return this.$t(`validation.${key}`);
            } else if (this.$te(key)) {
                return this.$t(key);
            } else {
                return key;
            }
        },
    },
});

