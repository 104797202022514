import axios, { AxiosInstance } from 'axios';
import Oidc from 'oidc-client';
import { Notifications } from './Notifications';
import { GlobalConfiguration } from './GlobalConfiguration';
import Vue from 'vue';

export class HttpClient {
    /**
     * Configure le client axios de l'instance de HttpClient.
     * @returns true si la configuration a fonctionné, false sinon.
     */
    public static async init(getCurrentUser: () => Promise<Oidc.User | null>): Promise<boolean> {
        try {
            // Configuration de l'URL d'API.
            HttpClient.instance.axiosInstance = axios.create({ baseURL: GlobalConfiguration.apiUrl });

            // Configuration du header autorisation.
            // Intercepteurs à compléter au besoin.
            let token: string | null = null;
            const tokenType: string | null = 'Bearer';

            HttpClient.instance.axiosInstance.interceptors.request.use(
                async (config) => {
                    if (!!token && !!tokenType && !config.headers.Authorization) {
                        config.headers.Authorization = `${tokenType} ${token}`;
                    } else if (!config.headers.Authorization) {
                        const user: Oidc.User | null = await getCurrentUser();
                        if (!!user) {
                            token = user.access_token;
                            config.headers.Authorization = `Bearer ${user.access_token}`;
                        }
                    }
                    return config;
                },
                (error) => {
                    // Do something with request error
                    return Promise.reject(error);
                },
            );

            // Add a response interceptor
            HttpClient.instance.axiosInstance.interceptors.response.use(
                (response) => {
                    // Do something with response data
                    // Notifications flottante en cas d'erreur
                    Notifications.successHandler(response);
                    return response;
                },
                (error) => {
                    const cookieStorage = Vue.prototype.$security.getCookieStorage();

                    // Si l'authentification du Back office a expiré et qu'on était toujours connecté dans le front, on refresh
                    if (error.response && error.response.status === 401 && cookieStorage.getItem('user')) {
                        cookieStorage.removeItem('user');
                        HttpClient.setAuthorizationHeaders('');

                        location.reload(true);
                    }
                    // Notifications flottante en cas d'erreur
                    Notifications.errorHandler(error);
                    return Promise.reject(error);
                },
            );

            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * Configure le client axios pour envoyer le token utilisateur dans les headers de requêtes vers l'API.
     * @param accessCredentials Le tuple login/mdp en base 64.
     */
    public static async setAuthorizationHeaders(accessCredentials: string) {
        HttpClient.axios.defaults.headers.common.Authorization = `Basic ${accessCredentials}`;
    }

    /**
     * Instance de la classe HttpClient.
     */
    private static instance: HttpClient = new HttpClient();

    /**
     * Client axios.
     */
    public axiosInstance!: AxiosInstance;

    /**
     * Getter de l'instance HttpClient
     */
    public static get Instance(): HttpClient {
        return this.instance || (this.instance = new this());
    }

    /**
     * Getter du client axios de l'instance HttpClient
     */
    public static get axios() {
        return this.Instance.axiosInstance;
    }
}
