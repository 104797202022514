export class Resultats {
    public static readonly ALL =  new Resultats(1, 'Tous');
    public static readonly ACTIF =  new Resultats(2, 'Actif');
    public static readonly INACTIF =  new Resultats(3, 'Inactif');

    private constructor(private readonly key: number, public readonly value: string) {}

    public toKey() {
        return this.key;
    }
}
