import { StatutDossier } from '@/shared/enums/StatutDossier.enum';
import { StatutSimulation, EnumProfilsAsNumber} from '@/shared/enums';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const inputTagName = 'input';
const formTagName = 'form';
const disableFormClassName = 'disabled-edit-form';

const profilsAdministrateurs: EnumProfilsAsNumber[] = [EnumProfilsAsNumber.AdministrateurCEE, EnumProfilsAsNumber.AdministrateurInformatique, EnumProfilsAsNumber.Finance];

export class GrantEditHelper {


    private static workflow: Array<{
        statut: StatutDossier,
        iscurrentUserInterne: boolean,
        hasPermission: boolean,
    }> = [];

    private static currentUserProfil: {
        estInterne: boolean,
        profil: EnumProfilsAsNumber,
    } = {
        estInterne: false,
        profil: EnumProfilsAsNumber.None,
    };

    public static getWorkflow() {
        return GrantEditHelper.workflow;
    }

    private static permissionDefault(statutDossier: StatutDossier): Array<{statut: StatutDossier, iscurrentUserInterne: boolean, hasPermission: boolean}> {

        return [Object.assign({statut: statutDossier}, {iscurrentUserInterne: true, hasPermission: true}),
                 Object.assign({statut: statutDossier}, {iscurrentUserInterne: false, hasPermission: false})
        ];
    }

    private static permissionOnlyAdmin(statutDossier: StatutDossier) {
        return [Object.assign({statut: statutDossier}, {iscurrentUserInterne: true, hasPermission: profilsAdministrateurs.includes(this.currentUserProfil.profil)}),
            Object.assign({statut: statutDossier}, {iscurrentUserInterne: false, hasPermission: false})
        ];
    }

    public static  setDataProfil(vNode: VNode) {
        (vNode.context as Vue & {getUserProfile: any}).getUserProfile.then((data: any) => {
            this.currentUserProfil = {
                estInterne: data.isInterne,
                profil: data.profilId,
            };
        });
    }

    public static initialize(): void {
        this.workflow = [];
        this.workflow.push(...[
            {statut: StatutDossier.EN_CREATION, iscurrentUserInterne: true, hasPermission: true},
            {statut: StatutDossier.EN_CREATION, iscurrentUserInterne: false, hasPermission: true},
            ...this.permissionDefault(StatutDossier.NONE),
            ...this.permissionDefault(StatutDossier.ENVOYE_ENGIE),
            ...this.permissionDefault(StatutDossier.A_RECEVOIR),
            ...this.permissionDefault(StatutDossier.EN_TRAITEMENT_ENGIE),
            ...this.permissionDefault(StatutDossier.FINALISE_ENGIE),
            ...this.permissionDefault(StatutDossier.NON_DEPOSE_PNCEE),
            ...this.permissionOnlyAdmin(StatutDossier.DEPOSE_PNCEE),
            ...this.permissionOnlyAdmin(StatutDossier.TRAITE_PNCEE),
        ]);
    }

    public static hasPermissionToEdit(data: {
        value: SimulationOuDossier,
        statut: StatutDossier | StatutSimulation,
    }): boolean {
        if (data.value === SimulationOuDossier.Dossier) {
            return !!(this.workflow.find((item) => item.iscurrentUserInterne === this.currentUserProfil.estInterne && item.statut === data.statut) || {} as {
                statut: StatutDossier,
                iscurrentUserInterne: boolean,
                hasPermission: boolean,
            }).hasPermission;
        } else {
            return true;
        }
    }
}

export const isFormValid = (el: HTMLElement, vNode: VNode): boolean => {
    const elements = ((vNode.elm as Node & {elements:  HTMLFormControlsCollection}).elements || {} as HTMLFormControlsCollection);
    return el.tagName === formTagName.toLocaleUpperCase() &&
        !!elements &&
        elements.length > 0 &&
        [...elements].some((element: Element) => (element.tagName || {}) === inputTagName.toLocaleUpperCase());
};

export const isParamsProvided = (params: DirectiveBinding): boolean => {
    return !!params.value &&
            typeof(params.value.estDossier) !== 'undefined' &&
            typeof(params.value.statut) !== 'undefined';
};

export const setLoadingBefore = (el: HTMLElement): void => {
    console.error("Méthode non-implémentée");
};

export const disableFormInputs = (element: HTMLElement, vnode: VNode) => {
    if (vnode.elm && vnode.elm.parentElement) {
        // Const elements = ((vnode.elm as Node & {elements:  HTMLFormControlsCollection}).elements || {} as HTMLFormControlsCollection);
        element.classList.add(disableFormClassName);
    }
};

export const enableFormInputs = (element: HTMLElement, vnode: VNode) => {
    if (vnode.elm && vnode.elm.parentElement) {
        element.classList.remove(disableFormClassName);
    }
};
