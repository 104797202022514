
export class Document {

    /**
     * Liste des codes des documents facultatifs
     * @type {string[]}
     */
    public static codeDocumentsFacultatifs: string[] = ["AUTRE_DOCUMENT"];

    /**
     * Identifiant du document.
     *
     * @type {string}
     * @memberof Document
     */
    public id: number;
    /**
     * Identifiant de l'opération.
     *
     * @type {string}
     * @memberof Document
     */
    public operationId: number | null = null;
    /**
     * Identifiant de SimulationDossierId.
     *
     * @type {string}
     * @memberof Document
     */
    public simulationDossierId: number | null = null;
    /**
     * Identifiant de PaiementId.
     *
     * @type {string}
     * @memberof Document
     */
    public paiementId: number | null = null;
    /**
     * Titre du document.
     *
     * @type {string}
     * @memberof Document
     */
    public titre: string | null = null;
    /**
     * Code  du type de document.
     *
     * @type {string}
     * @memberof Document
     */
    public typeDocumentCode: string | null = null;
    /**
     * Identifiant  du type de document.
     *
     * @type {number}
     * @memberof Document
     */
    public typeDocumentId: number | null = null;
    /**
     * Identifiant  du template d'opération.
     *
     * @type {number}
     * @memberof Document
     */
    public templateOperationId: number | null = null;
    /**
     * Libelle du type de document.
     *
     * @type {string}
     * @memberof Document
     */
    public typeDocumentLibelle: string | null = null;
    /**
     * Identifiant  de l'utilisateur.
     *
     * @type {number}
     * @memberof Document
     */
    public utilisateurId: number | null = null;
    /**
     * Contenu du document en Base64.
     *
     * @type {string}
     * @memberof Document
     */
    public contenu: string | ArrayBuffer | null = null;

    /**
     * Permet à l'utilisateur de saisir un nom personnalisé pour le type document.
     */
    public nomTypeDocumentUtilisateur: string = null;

    /**
     * Stock la valeur au moment du focus de l'input.
     * Permet de détecter un éventuel changement.
     */
    public nomTypeDocumentUtilisateurSelected: string = null;

    /**
     * Permet d'identifier le document de façon unique.
     *
     * @type {string}
     * @memberof Document
     */
    public uuid: string;

    /**
     * Permet d'indiquer si le document a été modifié.
     *
     * @type {bool}
     * @memberof Document
     */
    public estModifie: boolean = false;
    /**
     * Permet d'indiquer si le document est valise.
     */
    public estValide(): boolean {
        return this.contenu
            && this.titre
            && (this.utilisateurId > 0)
            && (this.typeDocumentId >0);
    }
}
