import { AxiosResponse, AxiosError } from 'axios';
import Bus from '@/components/Bus';
import { MessageLevel } from './enums';

const FORBIDDEN = 403;

export class Notifications {

    /**
     * Envoie des messages de notifications de succès en se basant sur la config défini.
     */
    public static successHandler(response: AxiosResponse<any>): void {
        if (Notifications.isHandlerEnabled(response.config)) {
            // Handle responses
            Notifications.sendMessages(response);
        }
    }

    /**
     * Envoie des messages de notifications d'erreur en se basant sur la config définie.
     */
    public static errorHandler(error: AxiosResponse<any> | AxiosError): void {

        // Gestion cas particulier : UnhandledRejection.
        // Si le serveur est down ('Network Error'), le statut n'est pas défini et on ne peut rien faire de l'erreur.
        if (!(error as any).status) {
            return;
        }
        
        // Récupérer la réponse.
        const response = (error as AxiosError).response;
        if (Notifications.isHandlerEnabled(error.config)) {
            // Handle responses.
            Notifications.sendMessages((error as AxiosResponse<any>));
            const { status } = response;
            if (status === FORBIDDEN) {
                Bus.$emit('httpErrorComponent', {
                    routeParams: {
                        name: 'error',
                        params: {
                            'status': '403',
                        },
                        replace: true
                    }
                });
            }
        }
    }

    // Prend en charge les notifications, et retourne un booléen pour indiquer s'il a pu gérer ou pas.
    public static handledNotifications(errorResponse: AxiosResponse<any>): boolean {
        if ((errorResponse as any).response) {
            // Handle responses
            Notifications.sendMessages((errorResponse as any).response as AxiosResponse<any>);
        }
        else if (errorResponse
            && errorResponse.config
            && Notifications.isHandlerEnabled(errorResponse.config)) {
            // Handle responses
            Notifications.sendMessages(errorResponse);
        }
        return false;
    }
    /**
     * Émettre événements des messages de notifications d'erreur en se basant sur la config défini.
     */
    public static sendMessages(response: AxiosResponse<any>): void {
        const messages = Notifications.getMessages(response);
        if (!!messages) {
            if (messages.alerts && messages.alerts.length > 0) {
                Bus.$emit('notifications-alerts-events', messages.alerts);
            }
            if (messages.notificationsFlottantes && messages.notificationsFlottantes.length > 0) {
                Bus.$emit('notifications-floatings-events', messages.notificationsFlottantes);
            }
        }
    }

    /**
     * Émettre événements des messages de notifications d'erreur en se basant sur la config défini.
     */
    public static sendSimpleMessages(message: any): void {
        if (!!message) {        
            Bus.$emit('notifications-alerts-events', [message]);
        }
    }

    /**
     * Récupérer les messages depuis la réponse.
     */
    public static getMessages(response: AxiosResponse<any>): any {     
        if (response && response.data && (response.data.messages || response.data.Messages)) {
            const messages = response.data.messages ? response.data.messages : response.data.Messages;   
            const alerts = messages.filter((msg: any) => (msg.messageType === 'Alert' || msg.messageType === 0)).map((val: any) => {
                if (typeof val.messageLevel === 'number') {
                    val.messageLevel = MessageLevel[val.messageLevel];
                }
                return val;
            });
            const notificationsFlottantes = messages.filter((message: any) => (message.messageType ? message.messageType : message.MessageType) === 'Toast').map((val: any) => {
                return val;
            });

            return { alerts, notificationsFlottantes };
        }
        return [];
    }

    public static isHandlerEnabled(config: any): boolean {
        return !!config && !!({ ...{ errorHandle: true }, ...config }).errorHandle;
    }
}

